import * as React from 'react';
import { 
    Button,
    Grid,
    Typography,
    ToggleButton,
    ToggleButtonGroup, 
    Box,
    Switch,
    FormControlLabel
} from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import noImage from '../../assets/images/no-image-127.png';
import AttendanceIcon from '../../assets/icons/mi-asistencia.svg';
import LibraryIcon from '../../assets/icons/servicios-biblioteca.svg';
import MoreServicesIcon from '../../assets/icons/mas-servicios2.svg';
import LogOutBlueIcon from '../../assets/icons/cerrar-sesion.svg';
import QrIcon from '../../assets/icons/qr.svg'
import BarCodeIcon from '../../assets/icons/codigo-barras.svg';
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import Barcode from 'react-barcode';
import QRCode from 'qrcode';
import reactDom from 'react-dom';
import { getTIInfo } from '../../services/user/userServices';
import { useSelector } from 'react-redux';
import ProfileDefaultImage from '../../assets/images/profile-default.png'
import { encryptedUserInfo } from '../../services/security/securityServices';

const Profile  = () => {

    var navigate = useNavigate();
    const [view, setView] = React.useState('Barras');
    const [imageUrl, setImageUrl] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const [person, setPerson] = React.useState({});
    const [labCoreAdmin, setLabCoreAdmin] = React.useState(false);

    const userInf = useSelector(state => state.userReducer.user);
    
    const handleChange = async (event, nextView) => {
        if (nextView !== null) {
            if(nextView === true) {
                setView('QR');
            }
            else {
                setView('Barras');
            }
        }
        const response = await QRCode.toDataURL(person.documentNumber);
        setImageUrl(response);
    };

    const goToCoreFacilities = () => {
        encryptedUserInfo({user: userInf.email})
            .then((response) => {
                const encUserInfo = response.data.encString;
                const a = document.createElement('a');
                a.href = 'https://labcore.pucp.in?encUserInfo=' + encodeURIComponent(encUserInfo);
                // a.href = 'http://localhost:3001?encUserInfo=' + encodeURIComponent(encUserInfo);
                a.click();
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const goToCoreFacilitiesAdmin = () => {
        encryptedUserInfo({user: userInf.email})
            .then((response) => {
                const encUserInfo = response.data.encString;
                const a = document.createElement('a');
                a.href = 'https://labcore.pucp.in?encUserInfo=' + encodeURIComponent(encUserInfo) + '&admin=true';
                // a.href = 'http://localhost:3001?encUserInfo=' + encodeURIComponent(encUserInfo) + '&admin=true';
                a.click();
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    React.useEffect(() => {
        if(userInf.role && userInf.role.roles && userInf.role.roles.find((role) => role.roleName === "labcoreadmin")) {
            setLabCoreAdmin(true);
        }
    }, [userInf]);

    React.useEffect(() => {
        setIsLoading(true);
        getTIInfo({email: userInf.email, personId: userInf.role.personId})
            .then((response) => {
                setPerson(response.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    return(
        <Grid container justifyContent='center' xs={12}>
            <Grid justifyContent='center' container xs={12} sm={6} lg={5} className='profile-container'>
                {isLoading === false && (
                    <>
                        <div className='TI-div'>
                        <Grid item xs={3} className='TI-image-data'>
                            <img src={userInf.imageUrl ? userInf.imageUrl : ProfileDefaultImage} referrerpolicy="no-referrer" className='TI-image'/>
                            {/*<img src={ProfileDefaultImage} referrerpolicy="no-referrer" className='TI-image'></img>*/}
                            {person.documentNumber !== null && (
                                <Typography 
                                    component="h6"                     
                                    variant="h6"
                                    color="primary"
                                    align='center'
                                >
                                    DNI: {person.documentNumber}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={9} className='TI-user-data'>
                            <Typography 
                                component="h4"                     
                                variant="h4"
                                color="primary"
                            >
                                Código: {person.pucpCode}
                            </Typography>
                            <hr color='#042354'/>
                            <Typography 
                                component="h3"                     
                                variant="h3"
                                color="primary"
                            >
                                {person.paternalSurname !== null ? 
                                <>
                                    {person.paternalSurname}<br/>{person.maternalSurname}<br/>{person.firstName}
                                </>
                                :
                                <>
                                    {person.fullName.replace(',', '')}
                                </>
                                }
                            </Typography>
                            <hr color='#042354'/>
                            <Typography 
                                component="h6"                     
                                variant="h6"
                                color="#FAD634"
                            >
                                {person.unitName}
                            </Typography>
                        </Grid>
                        </div>
        
                    <Grid item className='barcode-div'>
                        {view === "Barras" ? (
                            <Grid item xs={8} className='barcode-barqr'>
                                <Barcode 
                                    value={person.documentNumber}
                                    displayValue="false"
                                    lineColor="#042354"
                                    align="center"
                                    height="60px"
                                />
                            </Grid> 
                        ) : (
                            <Grid item xs={8} className='barcode-barqr'>
                                {imageUrl ? (<img src={imageUrl} width="85px" align="center"></img>) : null}
                            </Grid> 
                        )}
                        <Grid item className='barcode-switch-container'>
                            <Switch color='primary' className='barcode-switch' onChange={handleChange}/>
                            <h5 className={"barcode-label " + (view==="QR" ? 'barcode-label-right' : 'barcode-label-left')}>{view}</h5>             
                        </Grid>
                    </Grid>
        
                    <div className='options-container'>
                        <Typography 
                                component="h5"                     
                                variant="h5"
                                color="WHITE"
                        >
                            Servicios
                        </Typography>
                        <Button style={{ backgroundColor: 'white' }} className='profile-option' fullWidth onClick={() => navigate('/attendance')}>
                            <Typography 
                                    component="h5"                     
                                    variant="h5"
                                    color="MARINE_BLUE"
                                    style={{marginLeft:"10px"}}
                            >
                                Mi asistencia
                            </Typography>
                            <img className='option-icon' src={AttendanceIcon}/>
                        </Button>
                        {labCoreAdmin && (
                            <Button style={{ backgroundColor: 'white' }} disabled={!labCoreAdmin} className='profile-option' fullWidth
                                onClick={goToCoreFacilitiesAdmin}
                            >
                                <Typography 
                                    component="h5"                     
                                    variant="h5"
                                    color="MARINE_BLUE"
                                    style={{marginLeft:"10px", textAlign:"left"}}
                                >
                                    Laboratorios Core Facilities (Admin)
                                </Typography>
                                <img className={'option-icon ' + (!labCoreAdmin && 'disabled-icon')} src={LibraryIcon}/>
                            </Button>

                        )}
                        <Button
                            style={{ backgroundColor: 'white' }}
                            className='profile-option'
                            fullWidth
                            onClick={goToCoreFacilities}
                        >
                            <Typography 
                                    component="h5"                     
                                    variant="h5"
                                    color="MARINE_BLUE"
                                    style={{marginLeft:"10px"}}
                            >
                                Laboratorios Core Facilities
                            </Typography>
                            <img className='option-icon' src={MoreServicesIcon}/>
                        </Button>
                        <Button style={{ backgroundColor: 'white' }} className='profile-option' fullWidth
                        onClick={() => {
                            localStorage.clear();
                            localStorage.setItem("session", JSON.stringify(true));
                            navigate("/");}}
                        >
                            <Typography 
                                component="h5"                     
                                variant="h5"
                                color="MARINE_BLUE"
                                style={{marginLeft:"10px"}}
                            >
                            Salir
                            </Typography>
                            <img className='option-icon' src={LogOutBlueIcon}/>
                        </Button>
                    </div>
                    </>
                )}
            </Grid>
        </Grid>
    );
}

export default Profile;
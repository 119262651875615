import React from 'react'
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import * as ROUTES from '../../routes/routes.js';

import { useLocation } from "react-router-dom";

import "./Navbar.css";
import { Margin } from '@mui/icons-material';
import { Grid } from '@mui/material';
const Navbar = (props) => {

  let location = useLocation();

  const {
    value,
    setValue,
    changeMenuOption,
    firstIcon,
    secondIcon,
    thirdIcon,
    fourthIcon,
    fifthIcon,
  } = props;

  const [leftPosition, setLeftPosition] = React.useState(0)

  React.useEffect(() => {
    // console.log(location.pathname)
    let buttonName = 'activity-button'

    let val = 0;

    switch (location.pathname) {
      case '/capacity':
        buttonName = 'capacity-button'
        val = 1;
        break;
      case '/perfil':
      case '/attendance':
      case '/attendance-results':
        buttonName = 'profile-button'
        val = 4;
        break;
      case '/incidents':
        buttonName = 'incidence-button'
        val = 3;
        break;
      case '/qrScan':
      case '/qrInstructions':
      case ROUTES.ATTENDANCE_REPORT:
      case ROUTES.ATTENDANCE_STUDENT_SCAN:
      case ROUTES.ATTENDANCE_STUDENT_INFO:
        buttonName = 'qr-button'
        val = 2;
        break;
      default:
        break;
    }

    if(location.pathname.search('/location') !== -1) {
      buttonName = 'qr-button';
      val = 2;
    }
    
    getPosition(buttonName);
    setValue(val)
  }, [location, changeMenuOption, setValue])

  function getPosition(id){
    var el = document.getElementById(id).getElementsByTagName("IMG")[0]
    var viewportOffset = el.getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    // var top = viewportOffset.top;
    var left = viewportOffset.left;
    setLeftPosition(left)
  }

  return (
    <Paper className="bottomNavigationPaper" elevation={3}>
      <BottomNavigation
        showLabels
        className="bottomNavigationCustom"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          changeMenuOption(newValue);
        }}
      >
        <BottomNavigationAction
          id="activity-button"
          label="Inicio"
          icon={
            <img
              src={firstIcon}
              className={
                value === 0 ? "navigationIcon" : "disabledNavigationIcon"
              }
              style={{
                transition: value === 0 ? "0s" : "0.5s"
              }}
              alt="Ícono de actividades"
            />
          }
          onClick={() => {getPosition('activity-button')}}
        />
        <BottomNavigationAction
          id="capacity-button"
          label="Aforo"
          icon={
            <img
              src={secondIcon}
              className={
                value === 1 ? "navigationIcon" : "disabledNavigationIcon"
              }
              style={{
                transition: value === 1 ? "0s" : "0.5s"
              }}
              alt="Ícono de aforo"
            />
          }
          onClick={() => {getPosition('capacity-button')}}
        />
        <BottomNavigationAction
          id="qr-button"
          className="gridMuddleButton"
          icon={
            <Grid container >
              <div className="middleButton">
              </div>
              <img
                src={thirdIcon}
                style={{
                  className: "navigationIconQR",
                  transition: value === 2 ? "0s" : "0.5s",
                }}
                alt="Ícono de QR"
              />
            </Grid>
          }
          onClick={() => {getPosition('qr-button')}}
        />
        <BottomNavigationAction
          id="incidence-button"
          label="Incidencia"
          icon={
            <img
              src={fourthIcon}
              className={
                value === 3 ? "navigationIcon" : "disabledNavigationIcon"
              }
              style={{
                transition: value === 3 ? "0s" : "0.5s"
              }}
              alt="Ícono de incidencia"
            />
          }
          onClick={() => {getPosition('incidence-button')}}
        />
        <BottomNavigationAction
          id="profile-button"
          label="Perfil"
          icon={
            <img
              src={fifthIcon}
              className={
                value === 4 ? "navigationIcon" : "disabledNavigationIcon"
              }
              style={{
                transition: value === 4 ? "0s" : "0.5s"
              }}
              alt="Ícono de asistencia"
            />
          }
          onClick={() => {getPosition('profile-button')}}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default Navbar;
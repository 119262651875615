import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const registerUser = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      email: variables.email,
      fullName : variables.fullName
  }
  return axios.post(
    API_URL.url + "user/createFromGoogleAuth",
    obj,
  );
};

export const getTIInfo = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      username: variables.email,
      personId: variables.personId
  }
  return axios.post(
    API_URL.url + "user/userTI",
    obj,
  );
};
import * as React from 'react';
import { 
    Container,
    Typography
} from '@mui/material';
import CapacityCard from '../../components/Card/Capacity/CapacityCard';
import { useLocation } from "react-router-dom";
import labImage from '../../assets/images/laboratorio.png';
import learnImage from '../../assets/images/formacion.png';
import servicesImage from '../../assets/images/servicios.png';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon.js';
import BasicAlerts from '../../components/Alert/Alert';
import { useSelector } from 'react-redux';
import { getCapacity } from '../../services/capacity/CapacityServices';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './CapacityResults.css';

const CapacityResults  = () => {
    const [totalResults, setTotalResults] = React.useState(3);
    const [results, setResults] = React.useState([]);    
    const [firstResult, setFirstResult] = React.useState(1);
    const [lastResult, setLastResult] = React.useState(3);
    const [moreInf, setMoreInf] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);

    const { state } = useLocation();
    const userInf = useSelector(state => state.userReducer.user);

    React.useEffect(() => {
        if (state.capacities) {
            setResults(state.capacities.list);
            setTotalResults(state.capacities.total);            
            const activeButton = state.capacities.total > lastResult;
            setMoreInf(activeButton);
        }
    }, [state]);

    const   getCapacityResults = (newLastResult) => {
        const variables = {
            firstResult: firstResult,
            maxResults: newLastResult,
            user: userInf.email,
            personId: userInf.role.personId,
            startDate: state.startDate,  
            endDate: state.endDate,
        };
        
        getCapacity(variables)
            .then(function (response) {
                if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                    setResults(response.data.list);                               
                    const activeButton = response.data.total>newLastResult;
                    setMoreInf(activeButton);
                }
            })
            .catch(function (error) {
                console.log(error);
                setAlertType("error");
                setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                setActiveMessage(true);
            });
    }

    const selectImage = (activityType) => {
        let imageCard;
        switch(activityType){
            case 'Investigación':
                imageCard = labImage;
                break;
            case 'Servicio':
                imageCard = learnImage;
                break; 
            case 'Formación':
                imageCard = servicesImage;
                break;
            default:
                imageCard = labImage;
                break;                           
        }
        return imageCard;
    }

    const moreResults = () =>{
        const newLastResult=lastResult+3;
        setLastResult(newLastResult);
        getCapacityResults(newLastResult);
    }


    return(
        <>   
            <Container 
                className="containerCardsView"
            >     
            <div align='center'>
                <Typography 
                    className="titlesViews"
                    variant="subtitle1" 
                    component="div" 
                    gutterBottom 
                >
                    Total de resultados: {totalResults}
                </Typography> 
                {results.map((result, index)=>{
                    let imageCard = selectImage(result.tipoActividad);
                    return(
                        <CapacityCard 
                            key={index}
                            image={imageCard}
                            place={result.siPucpLabel === null ? result.site : result.siPucpLabel}
                            amount={result.capacity} 
                            maxAmount={result.maximum}
                            time={result.hourDate}
                            labelAmount={'Actual'}
                        />
                    )
                })}
                {results && results.length===0 &&
                    <Typography 
                        className="titlesViews" 
                        variant="subtitle1" 
                        component="div" 
                        gutterBottom 
                        align='center'
                    >
                        No se cuenta con información para esta consulta
                    </Typography> 
                }
                {results && results.length>0 && moreInf && 
                <Box className="containerButtonIcon">
                    <ButtonWithIcon
                        buttonText="Ver más" 
                        iconButton={<AddCircleIcon/>} 
                        onClick={()=>moreResults()}
                    />
                </Box>}
                {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />}
            </div>          
            </Container>  
        </>
    )

};

export default CapacityResults;
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './CapacityCard.css';
import noImage from '../../../assets/images/no-image-127.png';
import { formatNameCapitals } from '../../../constants/commonFunctions';
import { PRIMARY, RED } from '../../../constants/colors';

const CapacityCard=(props)=> {
    const {image, place, amount, maxAmount, time, labelAmount} = props;
    const siteNameRef = React.useRef('');
    const [hoverSiteNameRef, setHoverSiteNameRef] = React.useState(false);


    const compareSizeSiteName = () => {
        const compareSiteName =
        siteNameRef.current.clientHeight >
        13;
        setHoverSiteNameRef(compareSiteName);
    };  
    // compare once and add resize listener on "componentDidMount"
    React.useEffect(() => {
        compareSizeSiteName();
        window.addEventListener('resizeSiteNameCap', compareSizeSiteName);
    }, [,props]);

    // remove resize listener again on "componentWillUnmount"
    React.useEffect(
        () => () => {
        window.removeEventListener('resizeSiteNameCap', compareSizeSiteName);
    }, []);

    return (
        <Card className="capacityCardPI">
            <CardMedia
                className="capacityMediaPI"
                component="img"
                image={noImage}
                alt="activity"
            />
            <Box className="capacityBoxContentPI">
                <CardContent className="capacityContentPI">
                    <div className="text-container-cap">
                        <Typography 
                            ref={siteNameRef}
                            component="h6"                     
                            variant="h6"
                            color="primary"
                            className='text-overflow-cap'
                        >
                            {formatNameCapitals(place)}
                        </Typography>
                        {hoverSiteNameRef ? 
                        (
                            <span className="tooltiptext-cap">{formatNameCapitals(place)}</span>
                        )
                        :
                        (
                            <></>
                        )}
                    </div>
                    <Typography 
                        className="capacityBigTitlePI"
                        variant="h2" 
                        component="h2"
                    >                        
                        <span sx={{color: PRIMARY}}>{amount} /</span> 
                        <span style={{color: RED}}> {maxAmount}</span>
                    </Typography>
                    <Typography 
                        className="capacityLabelTitlePi"
                        variant="h6" 
                        component="h6"
                    >
                        <span style={{color: PRIMARY, padding: "6px"}}>{labelAmount}</span> 
                        <span style={{color: RED, padding: "4px"}}>  Máximo</span>
                    </Typography>
                </CardContent>                
            </Box>      
        </Card>
    );
}
export default CapacityCard;
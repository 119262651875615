import * as React from 'react';
import { 
    Autocomplete,
    Grid, 
    TextField,
    Typography
} from '@mui/material';
import './Combobox.css';
import { useNavigate } from 'react-router-dom';
import { QR_PRE } from '../../routes/routes';

const ComboboxStyled  = (props) => {
    const {label, comboboxOptions, value, setValue, setSearch, active, icon, onClickAct} = props;
    const [firstSelected, setFirstSelected] = React.useState(false);
    const navigate = useNavigate();

    return(
        <Grid container item direction="row"> 
            {label && 
            <Grid item xs={4} md={6} className="comboboxPI">
                <Typography 
                    className="labelComboboxPI"
                    variant="subtitle1" 
                    component="div" 
                    gutterBottom 
                >
                    {label}
                </Typography> 
            </Grid> }
            <Grid container direction="row">
                <Grid item xs={label ? 8 : (icon ? 10 : 12)}  md={6}>
                    <Autocomplete
                        noOptionsText={"Sin resultados"}
                        disablePortal
                        id="combo-box-demo"
                        options={comboboxOptions}
                        value={value !== null ? value.label : ''}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        disabled={!active}
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params}
                                onChange={(event) => {
                                    setSearch(event.target.value);
                                }}
                                onClick={() => {
                                    if(value && value.id !== -1) {
                                        return;
                                    }

                                    if(active) {
                                        if(firstSelected === false) {
                                            if(value && value.label) {
                                                value.label = "";
                                            }
                                            setFirstSelected(true);
                                        }
                                    }
                                }}
                                variant="outlined"
                            />
                        )}
                        freeSolo
                    />
                </Grid>
                {/*<img src={icon} ></img>*/}
                {icon ?
                (
                    <Grid item container xs={2} md={6} justifyContent="flex-end">
                        <img 
                            src={icon}
                            width={"28px"}
                            style={{marginRight: "8px"}}
                            onClick={onClickAct}
                        />
                    </Grid>
                ) : 
                (
                    <></>
                )}
            </Grid>  
        </Grid>
    )

};

export default ComboboxStyled;

export function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    let stringDate = `${day<10?'0'+day:day}/${month<10?'0'+month:month}/${year}`;
    return stringDate;
}

export function formatDateHyphen(date) {

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let stringDate = `${year}-${month < 10 ? '0'+month : month}-${day < 10 ? '0'+day : day}`;

    return stringDate;
}

export function formatDateTime(date) {
    let day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
    let month = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = date.getHours() === 12 ? '12' :  date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12 ) : date.getHours() % 12; 
    let minutes = date.getMinutes() < 10 ? '0'+date.getMinutes()  : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${day}/${month}/${year} ${hour}:${minutes} ${timeHour}`;

    return stringDate;
}

export function formatDateTimeSecondsBackEnd(date) {
    let day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
    let month = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = date.getHours() === 0 || date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12 ) : date.getHours() % 12; 
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes()  : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${day}/${month}/${year} ${hour}:${minutes}:${seconds} ${timeHour}`;

    return stringDate;
}

export function formatDateTimeSeconds(date) {
    let day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
    let month = (date.getMonth() + 1) < 10 ? '0'+(date.getMonth() + 1) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12 ) : date.getHours() % 12; 
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes()  : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${day}/${month}/${year} ${hour}:${minutes}:${seconds} ${timeHour}`;

    return stringDate;
}

export function formatJustTime(date) {
    let hour = date.getHours() % 12 < 10 ? '0'+ (date.getHours() % 12 ) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0'+ date.getMinutes()  : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? '0'+ date.getSeconds()  : date.getSeconds();

    let stringDate = `${hour} : ${minutes} : ${seconds}`;

    return stringDate;
}

export function formatJustTimeNoSeconds(date) {
    let hour = date.getHours() === 12 ? '12' :  date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12 ) : date.getHours() % 12; 
    let minutes = date.getMinutes() < 10 ? '0'+date.getMinutes()  : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${hour} : ${minutes} ${timeHour}`;

    return stringDate;
}

export function formatJustTimeNoSecondsLowercase(date) {
    let hour = date.getHours() === 12 ? '12' :  date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12 ) : date.getHours() % 12; 
    let minutes = date.getMinutes() < 10 ? '0'+date.getMinutes()  : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'p.m.' : 'a.m.';

    let stringDate = `${hour}:${minutes} ${timeHour}`;

    return stringDate;
}

export function formatTime(date) {
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() ; 
    let minutes = date.getMinutes() < 10 ? '0'+date.getMinutes()  : date.getMinutes();

    let stringDate = `${hour}:${minutes}`;

    return stringDate;
}

export function formatSeconds(secondsInput) {
    secondsInput =  Math.floor(secondsInput);

    let hour = Math.floor(secondsInput / 3600);
    secondsInput = secondsInput - 3600 * hour;
    let minute = Math.floor(secondsInput / 60);
    secondsInput = secondsInput - 60 * minute;

    let hourString = hour < 10 ? '0' + hour : hour
    let minutesString =  minute < 10 ? '0' + minute : minute
    let secondsString = secondsInput < 10 ? '0' + secondsInput : secondsInput

    let stringDate = `${hourString}:${minutesString}:${secondsString}`

    return stringDate
}

export function secondsOfTime(secondsInput) {
    secondsInput =  Math.floor(secondsInput);

    let hour = Math.floor(secondsInput / 3600);
    secondsInput = secondsInput - 3600 * hour;
    let minute = Math.floor(secondsInput / 60);
    secondsInput = secondsInput - 60 * minute;

    return secondsInput
}

export function formatNameCapitals(names) {
    if(!names) return null;

    names = names.toLowerCase();
    var newNames = '';
    var last = ' ';

    for (var i = 0; i < names.length; i++) {
        if(last === ' ' || last === '(' || last === ')' || last === ',' || last === '.' || last === ';' || last === '/')
            newNames = newNames + names.charAt(i).toUpperCase();
        else newNames = newNames + names.charAt(i);

        last = names.charAt(i);
    }

    return newNames;

    // Another way with little glitches
    //const arr = names.split(" ");

    //for (var i = 0; i < arr.length; i++) {
    //  arr[i] = arr[i].toLowerCase();
    //if(arr[i].length > 2)
       //  arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    //}

    //return arr.join(" ");
}

export function formatDateSpanish(date) {
    
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours() === 12 ? '12' :  date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12 ) : date.getHours() % 12; 
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes()  : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];

    return `${hour}:${minutes} ${timeHour} del ${day} de ${months[month-1]} del ${year}`;
}

export function formatJustDateSpanish(date) {
    
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    
    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];

    return `${day} de ${months[month-1]} del ${year}`;
}
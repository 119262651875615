import * as React from 'react';
import { 
    Grid, 
    Typography,
    TextField
} from '@mui/material';
import DesktopDateTimePicker  from '@mui/lab/DesktopDateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import './DateField.css';

const DateField  = (props) => {
    const {label, dateSelected, setDateSelected, withTime, labelIn, xsProp} = props;
    return(
        <Grid container item direction="row" > 
            {label && <Grid item xs={3} md={6}>
                 <Typography 
                    variant="h3" 
                    component="h3" 
                    color="primary"
                    style={{marginTop: "15px"}}
                    gutterBottom 
                >
                    {label}
                </Typography> 
            </Grid> }
            <Grid item xs={xsProp} md={6}>
                {withTime?
                <DesktopDateTimePicker                            
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    disableFuture
                    ampmInClock
                    ampm
                    value={dateSelected}
                    label={labelIn ? labelIn : null }
                    onChange={(newValue) => {
                        setDateSelected(newValue);
                    }}
                    toolbarTitle="Fecha y Hora"
                    inputFormat="dd/MM/yyyy - hh:mm aa"
                    cancelText="Cancelar"
                />: 
                <DesktopDatePicker
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    disableFuture                    
                    onChange={(value) => setDateSelected(value)}
                    value={dateSelected}
                    inputFormat="dd/MM/yyyy"
                    cancelText="Cancelar"
                />     
                }
            </Grid>  
        </Grid>
    )

};

export default DateField;
import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './Alert.css';

export default function BasicAlerts(props) {
    const {message, type} = props;

    return (
        <Stack className='alertPI' spacing={2}>
            <Alert severity={type}>{message}</Alert>
        </Stack>
    );
}
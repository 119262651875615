import * as React from 'react';
import { 
    Grid, Link
} from '@mui/material';
import './LinkWithIcon.css';

const LinkWithIcon  = (props) => {
    const {linkText, icon, callFunction} = props;
    return(   
        <Grid item container justifyContent="flex-end">  
            <Link 
                variant="subtitle1"
                component="button"
                onClick={ () => callFunction() }
                className="linkWithIconPI"
                color="primary"
            >
                {linkText} {icon}
            </Link>
        </Grid>   
    )

};

export default LinkWithIcon;
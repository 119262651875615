import React, { useEffect } from 'react';
import Auxiliar from '../../hoc/Auxiliar/Auxiliar';
import { Box, CssBaseline, Grid, Typography } from '@mui/material';
import pucpLogo from "../../assets/icons/Logo_PUCP.svg";
import pucpInCaps from "../../assets/icons/PUCP-IN.svg";
import pucpPhoto from "../../assets/images/pucp.png";
import backArrow from "../../assets/icons/back-arrow.svg";
import threesixty from "../../assets/icons/360.svg";
import './Mapa.css';
import { useNavigate } from 'react-router-dom';
import CircleButton from '../../components/Button/CircleButton';

function Mapa() {
    let navigate = useNavigate();
    const [showMap, setShowMap] = React.useState(false);
    
    let page = {
        data: {},
        labs: [],
        drag: false,
        popup: null,
        zoom: 0
    };
    let ele = null;
    let preventLoadingActive = false;
    let pos = {
        top: 0,
        left: 0,
        x: 0,
        y: 0
    };

    function startLoading() {
        if (preventLoadingActive)
            preventLoadingActive = false;
        else {
            document.querySelector('.loading').classList.add('active');
            document.querySelector('.loading .progress').innerHTML = '';
        }
    }

    function stopLoading() {
        document.querySelector('.loading').classList.remove('active');
    }

    function setData(datos) {
        page.data = datos;
    }

    function mostrarPopup(buildingName, buildingNumber) {
        // console.log(buildingName, 'mostrarPopup', page.drag);
        document.getElementById('popupEdificio').getElementsByClassName('popup-title')[0].innerHTML = buildingName;
        document.getElementById('buildingName').innerHTML = buildingName;
        if (document.getElementById('imgPopup').getElementsByTagName('img')[0]) {
            document.getElementById('imgPopup').getElementsByTagName('img')[0].remove();
        }
        if (document.getElementById('buildingDescription').getElementsByTagName('img')[0]) {
            document.getElementById('buildingDescription').getElementsByTagName('img')[0].remove();
        }
        document.getElementsByClassName('labs-container')[0].innerHTML = '';
        let popupImage = document.getElementById('imgPopup');
        // Find all labs in the building
        const labs = page.labs.filter(lab => lab.Edificio == buildingNumber);
        if (labs && labs.length > 0) {
            document.getElementById('popupEdificio').classList.add('popup-labs');
            popupImage = document.getElementById('buildingImage');
            for (const lab of labs) {
                const labContent = `
                        <div class="lab">
                            <div class="lab-content">
                                <h3 class="lab-title">${lab['LABORATORIO /CENTRO /GRUPO']}</h3>
                                <div class="lab-description">${lab['SECCIÓN']}</div>
                                <div class="lab-info">
                                    <div class="lab-info-item">
                                        <img src="/mapasrc/img/icons/ic_floor.svg" class="svg" />
                                        <p class="lab-floor">${lab['PISO']}</p>
                                    </div>
                                    <div class="lab-info-item">
                                        <img src="/mapasrc/img/icons/ic_user.svg" class="svg" />
                                        <p class="lab-responsible">${lab['RESPONSABLE']}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="lab-links">
                                <a class="button btn" href="mailto:${lab['CORREO']}" target="_blank">
                                    <div class="icon mail">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                                    </div>
                                </a>
                                <a class="button btn" href="${lab['Link 360']}" style="display: ${lab['Link 360'] !== 'No Aplica' && lab['Link 360'] !== ''  ? 'block' : 'none'}" target="_blank">
                                    <div class="icon">
                                        <img src="/mapasrc/img/icons/ic_360_view.svg" class="svg" />
                                    </div>
                                </a>
                                <a class="button btn" href="${lab['Página Web']}" style="display: ${lab['Página Web'] !== 'No Aplica' && lab['Página Web'] !== ''  ? 'block' : 'none'}" target="_blank">
                                    <div class="icon">
                                        <img src="/mapasrc/img/icons/ic_left_arrow.svg" class="svg" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    `
                document.getElementsByClassName('labs-container')[0].innerHTML += labContent;
            }
        } else {
            document.getElementById('popupEdificio').classList.remove('popup-labs');
        }

        if (buildingName == 'Caseta Industrial') {
            popupImage.innerHTML = '<img src="/mapasrc/img/fotos/Caseta-industrial.webp">';
            document.getElementById('btnVista').setAttribute('href', page.data.casetaIndustrial);
        } else if (buildingName == 'ADEIN') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Caseta-1.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.caseta1);
        } else if (buildingName == 'GRUPO PUCP' && buildingNumber === 15) {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Caseta-2.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.caseta2);
        } else if (buildingName == 'GRUPO PUCP' && buildingNumber === 14) {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Caseta-3.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.caseta3);
        } else if (buildingName == 'Icoba') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Icoba.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.icoba);
        } else if (buildingName == 'Pabellón A' && buildingNumber === 1) {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-A1.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonA1);
        } else if (buildingName == 'Pabellón A-2') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-A2.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonA2);
        } else if (buildingName == 'Pabellón Civil') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Ingenieria-Civil.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonCivil);
        } else if (buildingName == 'Pabellón Industrial') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-Industrial.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonIndustrial);
        } else if (buildingName == 'Pabellón K') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-K.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonK);
        } else if (buildingName == 'Pabellón M') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-M.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonM);
        } else if (buildingName == 'Pabellón O') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-O.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonO);
        } else if (buildingName == 'Pabellón U') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-U.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonU);
        } else if (buildingName == 'Pabellón V') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Pabellon-V.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.pabellonV);
        } else if (buildingName == 'Pabellón A' && buildingNumber === 2) {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Suelos.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.suelos);
        } else if (buildingName == 'Complejo de Innovación Académica') {
            popupImage.innerHTML = ('<img src="/mapasrc/img/fotos/Innovacion.webp">');
            document.getElementById('btnVista').setAttribute('href', page.data.innovacion);
        }

        if (!page.drag) {
            page.popup.show();
        }
    }

    const mouseDownHandler = function (e) {
        ele.css = ("cursor", "grabbing");
        ele.css = ("userSelect", "none");
        pos = {
            left: window.pageXOffset,
            top: window.pageYOffset,
            x: e.clientX,
            y: e.clientY,
        };
        page.drag = false;
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;
        page.drag = true;

        // $(document).scrollTop(pos.top - dy);
        // $(document).scrollLeft(pos.left - dx);
        window.scrollTo(pos.left - dx, pos.top - dy);
    };

    const mouseUpHandler = function () {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        ele.css = ("cursor", 'grab');
        ele.removeAttribute('user-select');
        page.drag = false;
    };

    async function loadSvg() {
        const parent = document.getElementsByClassName('content')[0]
        if (!parent) {
            return;
        }
        for (const img of parent.querySelectorAll('img.svg')) {
            const imgID = img.id;
            const imgClass = img.className;
            const imgURL = img.src;
            const text = await fetch(imgURL).then(function (response) {
                return response.text();
            })

            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(text, "text/xml");

            // Get the SVG tag, ignore the rest
            const svg = xmlDoc.getElementsByTagName('svg')[0];

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                svg.setAttribute('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                svg.setAttribute('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            svg.removeAttribute('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute(
                    'width')) {
                svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg
                    .getAttribute('width'))
            }

            // Replace image with new SVG
            if (img.parentNode !== null) {
                parent.removeChild(img);
                parent.appendChild(svg);
                img.remove();
                document.querySelectorAll('svg title').forEach(function (el) {
                    el.remove();
                });
            }
        };
        const copy = JSON.parse(JSON.stringify(parent.innerHTML));
        document.getElementById('mapa').innerHTML = copy;
    }

    function getSvgs() {
        const buildings = [{
                class: 'pabellon-a1',
                name: 'Pabellón A',
                id: 1
            },
            {
                class: 'caseta-industrial',
                name: 'Caseta Industrial',
                id: 7
            },
            {
                class: 'suelos',
                name: 'Pabellón A',
                id: 2
            },
            {
                class: 'caseta1',
                name: 'ADEIN',
                id: 16
            },
            {
                class: 'caseta2',
                name: 'GRUPO PUCP',
                id: 15
            },
            {
                class: 'caseta3',
                name: 'GRUPO PUCP',
                id: 14
            },
            {
                class: 'icoba',
                name: 'Icoba',
                id: 9
            },
            {
                class: 'pabellon-a2',
                name: 'Pabellón A-2'
            },
            {
                class: 'pabellon-civil',
                name: 'Pabellón Civil',
                id: 6
            },
            {
                class: 'pabellon-industrial',
                name: 'Pabellón Industrial',
                id: 11
            },
            {
                class: 'pabellon-k',
                name: 'Pabellón K',
                id: 12
            },
            {
                class: 'pabellon-m',
                name: 'Pabellón M',
                id: 5
            },
            {
                class: 'pabellon-o',
                name: 'Pabellón O',
                id: 13
            },
            {
                class: 'pabellon-u',
                name: 'Pabellón U',
                id: 4
            },
            {
                class: 'pabellon-v',
                name: 'Pabellón V',
                id: 10
            },
            {
                class: 'innovacion',
                name: 'Complejo de Innovación Académica',
                id: 3
            }
        ];

        buildings.forEach(building => {
            let elementsHover = [];
            let elementsBuildings = [];
            // if (building.class !== 'pabellon-civil') {
            //     elementsHover = document.querySelectorAll(`.${building.class}.build.st0`);
            //     elementsBuildings = document.querySelectorAll(`.${building.class}.build.st1`);
            // } else {
            elementsHover = document.querySelectorAll(`.${building.class} .cls-1`);
            elementsBuildings = document.querySelectorAll(`.${building.class} .cls-2`);
            // }

            // console.log('building', building);
            // console.log('elementsHover', elementsHover, 'elementsBuildings', elementsBuildings);

            elementsBuildings.forEach(element => {
                element.addEventListener('mouseover', function () {
                    element.parentElement.classList.add('hover');
                });

                element.addEventListener('mouseleave', function () {
                    element.parentElement.classList.remove('hover');
                });

                element.addEventListener('click', function () {
                    mostrarPopup(building.name, building.id);
                });
            });

            elementsHover.forEach(element => {
                element.addEventListener('mouseover', function () {
                    element.parentElement.classList.add('hover');
                });

                element.addEventListener('mouseleave', function () {
                    element.parentElement.classList.remove('hover');
                });

                element.addEventListener('click', function () {
                    mostrarPopup(building.name, building.id);
                });
            });
        });

        const mapa = document.getElementById('mapa');
        const ancho = mapa.offsetWidth - window.innerWidth;
        const alto = mapa.offsetHeight - window.innerHeight;

        document.documentElement.scrollLeft = ancho / 2;
        document.documentElement.scrollTop = alto / 2;
    }
    
    function Popup(popupId, dismissable, callbackHide) {
        this.popupId = popupId;
        this.dismissable = true;
        this.callbackHide = callbackHide;

        if (typeof dismissable !== 'undefined')
            this.dismissable = dismissable;

        var popup = this;
        document.getElementById(popupId).querySelector('.close').addEventListener('click', function () {
            popup.hide();
        });

        document.getElementById(popupId).addEventListener('mousedown', function (e) {
            if (e.target === document.getElementById(popupId)) {
                popup.hide();
            }
        });

        document.addEventListener('keyup', function (e) {
            if (e.which == 27) {
                popup.hide();
            }
        });
    }

    Popup.prototype.show = function () {
        document.getElementById(this.popupId).classList.add('active');
        var inputs = document.getElementById(this.popupId).querySelectorAll(
            'input[type="text"], input[type="number"], input[type="password"], input[type="email"]');
        if (inputs.length > 0) {
            inputs[0].focus();
        }
        this.removeErrors();
    }

    Popup.prototype.removeErrors = function () {
        var formFields = document.getElementById(this.popupId).querySelectorAll(
            '.popup-box .content .body .form-field');
        formFields.forEach(function (field) {
            field.classList.remove('error');
        });
    }

    Popup.prototype.hide = function () {
        if (this.dismissable) {
            document.getElementById(this.popupId).classList.remove('active');
            this.clearFields();
            this.unlockFields();
            if (typeof this.callbackHide !== 'undefined')
                this.callbackHide();
        }
    }

    Popup.prototype.clearFields = function () {
        if (document.getElementById(this.popupId).querySelector('label')) {
            document.getElementById(this.popupId).querySelector('label').classList.remove('error-label')
        }
        if (document.getElementById(this.popupId).querySelector('input')) {
            document.getElementById(this.popupId).querySelector('input').classList.remove('error')
            document.getElementById(this.popupId).querySelector('input').value = '';
        }
        if (document.getElementById(this.popupId).querySelector('.error-message')) {
            document.getElementById(this.popupId).querySelector('.error-message').innerHTML = '';
        }
        if (document.getElementById(this.popupId).querySelector('select')) {
            document.getElementById(this.popupId).querySelector('select').classList.remove('error')
        }
        if (document.getElementById(this.popupId).querySelector('.image-container')) {
            document.getElementById(this.popupId).querySelector('.image-container').classList.remove('error')
        }
    }

    Popup.prototype.unlockFields = function () {
        if (document.getElementById(this.popupId).querySelector('select')) {
            document.getElementById(this.popupId).querySelector('select').removeAttribute('disabled')
        }
    }


    useEffect(() => {
        if (!showMap) {
            return;
        }
        var htmlLoading =
            '<div class="loading"><div class="loading-container"><label class="progress"></label><svg viewBox="0 0 38 38"><path d="M19 3.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" /><path d="M19 3.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" fill="none" /></svg></div></div>';
        document.getElementsByTagName('body')[0].innerHTML += htmlLoading;

        startLoading();

        fetch('/mapasrc/config.json')
            .then(response => response.json())
            .then(data => {
                // console.log('Config:', data);
                setData(data);
                stopLoading();
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });

        fetch('/mapasrc/places.json')
            .then(response => response.json())
            .then(data => {
                // console.log('Places:', data);
                page.labs = data;
                stopLoading();
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });

        page.drag = false;
        page.popup = new Popup('popupEdificio');
    
        setTimeout(() => {
            getSvgs();
        }, 1200);

        
        document.getElementById('zoomIn').addEventListener('click', function () {
            // console.log('zoomIn');
            var widthInit = document.getElementById('mapa').offsetWidth;
            var heightInit = document.getElementById('mapa').offsetHeight;
            document.querySelector('img.background').style.height = heightInit * 1.3 + 'px';
            document.getElementById('mapa').style.width = widthInit * 1.3 + 'px';
            document.getElementById('mapa').style.height = heightInit * 1.3 + 'px';
            page.zoom++;
            document.getElementById('zoomOut').classList.remove('disabled');
            if (page.zoom == 2)
                document.getElementById('zoomIn').classList.add('disabled');
        });

        document.getElementById('zoomOut').addEventListener('click', function () {
            var widthInit = document.getElementById('mapa').offsetWidth;
            var heightInit = document.getElementById('mapa').offsetHeight;
            document.querySelector('img.background').style.height = heightInit / 1.3 + 'px';
            document.getElementById('mapa').style.width = widthInit / 1.3 + 'px';
            document.getElementById('mapa').style.height = heightInit / 1.3 + 'px';
            page.zoom--;
            document.getElementById('zoomIn').classList.remove('disabled');
            if (page.zoom == 0)
                document.getElementById('zoomOut').classList.add('disabled');
        });

        ele = document.getElementsByTagName('body')[0];
        pos = {
            top: 0,
            left: 0,
            x: 0,
            y: 0
        };

        document.getElementsByClassName('content')[0].addEventListener('mousedown', mouseDownHandler);
        loadSvg();
    }, [showMap]);

    const closePopup = () => {
        // console.log('closePopup', page);
        page.popup.hide()
    }

    return (
        (showMap &&
            <div className='mapa-container' style={{width: '100vw', height: '100dvh'}}>
                <div className="scroll-hidden" style={{width: '100%', height: '100%'}}>
                    <div className="content" id="mapa">
                        <img className="background" src="/mapasrc/img/background.webp" />
                        <img src="/mapasrc/img/buildings/caseta-industrial-silueta.svg" className="caseta-industrial building svg" />
                        <img src="/mapasrc/img/buildings/caseta1-silueta.svg" className="caseta1 building svg" />
                        <img src="/mapasrc/img/buildings/caseta2-silueta.svg" className="caseta2 building svg" />
                        <img src="/mapasrc/img/buildings/caseta3-silueta.svg" className="caseta3 building svg" />
                        <img src="/mapasrc/img/buildings/icoba-silueta.svg" className="icoba building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-A1-silueta.svg" className="pabellon-a1 building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-A2-silueta.svg" className="pabellon-a2 building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-civil-silueta.svg" className="pabellon-civil building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-industrial-silueta.svg" className="pabellon-industrial building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-M-silueta.svg" className="pabellon-m building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-K-silueta.svg" className="pabellon-k building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-O-silueta.svg" className="pabellon-o building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-U-silueta.svg" className="pabellon-u building svg" />
                        <img src="/mapasrc/img/buildings/pabellon-V-silueta.svg" className="pabellon-v building svg" />
                        <img src="/mapasrc/img/buildings/suelos-silueta.svg" className="suelos building svg" />
                        <img src="/mapasrc/img/buildings/innovacion-silueta.svg" className="innovacion building svg" />
                    </div>
                    <div className="zoom">
                        <a id="zoomIn" className="button btn-small">+</a>
                        <a id="zoomOut" className="button btn-small disabled">-</a>
                    </div>
                    <div className="popup" id="popupEdificio">
                        <div className="popup-box">
                            <div className="close" onClick={closePopup}></div>
                            <div className="content">
                                <div className="body">
                                    <div id="imgPopup" className="image-container"></div>
                                    <div className="description">
                                        <h2 className="popup-title"></h2>
                                        <div className="actions" style={{display: 'none'}}>
                                            <a id="btnVista" className="button btn-secondary-color btn-medium" target="_blank">
                                                <label>Detalle</label>
                                                <div className="icon original-color">
                                                    <img src="/mapasrc/img/icons/ic_arrow.svg" className="svg" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="labs-body">
                                    <div className="building-info">
                                        <div id="buildingImage" className="image-container"></div>
                                        <div className="building-description">
                                            <h2 id="buildingName"></h2>
                                            <p id="buildingDescription"></p>
                                        </div>
                                    </div>
                                    <div className="labs-container">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        ||
        <Auxiliar>
          <Box>
            <Grid container className="titleLayoutPI" alignItems="center">
                <Grid item xs={12} className="imageLogoContainer" justifyContent='center' alignItems='center'>
                  <img src={pucpLogo} className="loginImg" alt="PUCP-IN Logo" />
                  <img
                    src={pucpInCaps}
                    className="loginCaps"
                    alt="PUCP-IN palabra"
                  />
                </Grid>
            </Grid>
            <img src={pucpPhoto} className="pucpPhoto" alt="PUCP-IN" />
            <Grid container className="mapDescription" alignItems="center">
                <Typography variant="body2" className="mapDescriptionTitle">
                    Bienvenido a PUCP-IN, énterate de los anuncios, talleres, eventos y noticias. Descubre los espacios de la universidad y los beneficios que ofrece la PUCP:
                </Typography>
            </Grid>
            <Grid container className="mapDescription" alignItems="center" gap="20px">
                <CircleButton
                    buttonText={'Regresar'}
                    iconButton={backArrow}
                    onClick={() => {navigate(-1)}}
                    hx={"h4"}
                />
                <CircleButton
                    buttonText={'Mapa 360°'}
                    iconButton={threesixty}
                    onClick={() => {setShowMap(true)}}
                    hx={"h4"}
                />
            </Grid>
            <CssBaseline />
          </Box>
        </Auxiliar>
    )
}

export default Mapa;
import * as actionsTypes from "../actions/actionsType";

const InitialState = {
  activities: {},
};

const activitiesReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionsTypes.SET_ACTIVITIES:
      return {
        activities: action.activities,
      };
    default:
      return state;
  }
};

export default activitiesReducer;
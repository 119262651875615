import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { geolocated, geoPropTypes } from "react-geolocated";
import axios from 'axios';
import * as ROUTES from "../routes";
import Login from "../../views/Login/Login.js";
import Layout from "../../hoc/Layout/Layout.js";
import Activities from "../../views/Activities/Activities.js";
import Attendance from "../../views/Attendance/Attendance.js";
import AttendanceResults from "../../views/Attendance/AttendanceResults.js";
import Capacity from "../../views/Capacity/Capacity.js";
import CapacityResults from "../../views/Capacity/CapacityResults.js";
import Incidents from "../../views/Incidents/Incidents.js";
import OnBoarding from "../../views/OnBoarding/OnBoarding.js";
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../store/actions/actionsType';
import QRScan from '../../views/QRScan/QRScanPre';
import LocationDetails from '../../views/LocationDetails/LocationDetails';
import { getLastDayCheckIn } from '../../services/attendance/AttendanceServices';
import QRScanScanner from '../../views/QRScan/QRScanScanner';
import { formatNameCapitals } from '../../constants/commonFunctions';
import Profile from '../../views/Profile/Profile';
import AttendanceReport from '../../views/Attendance/AttendanceReport';
import AttendanceStudentScan from '../../views/Attendance/AttendanceStudentScan';
import AttendanceStudentInfo from '../../views/Attendance/AttendanceStudentInfo';
import ActivityDetail from '../../views/Activities/ActivityDetail';
import Mapa from '../../views/Mapa/Mapa.js';

const API_GOOGLE = 
window.globalConfig || { apiGoogleKey: process.env.REACT_APP_API_GOOGLE} ;

const AppRouter = (props) => {

    const apiGoogleUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=";
    const apiGoogleKey = API_GOOGLE.apiGoogleKey;

    const [username, setUsername] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [lastCheckInObject, setLastCheckInObject] = React.useState(null);

    const userInf = useSelector(state => state.userReducer.user);
    const userInfLocal = JSON.parse(localStorage.getItem("userPUCPIN"));
    const dispatch = useDispatch();

    React.useEffect( () => {
        if (userInf && Object.keys(userInf).length === 0) {
            
            if (userInfLocal!=null){
                dispatch({ type: actionTypes.LOGIN, user: userInfLocal });
            }
        }   
    }, []);


    React.useEffect(() => {
        if (userInf.name) setUsername(userInf.name);
        
        if(userInf && userInf.role && userInf.role != null) {
            // Get the last check in if exists and send the site info
            setLoading(true);
            getLastDayCheckIn( {user: userInf.email, personId: userInf.role.personId} )
                .then( (response) => {
                    if(response.data.buildingId !== null) {
                        setLastCheckInObject(response.data);
                    }
                    else {
                        setLastCheckInObject(null);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => setLoading(false));
        }
    }, [userInf])

    React.useEffect(()=>{
        if(props.isGeolocationAvailable && props.isGeolocationEnabled && props.coords){
            axios.get(
                apiGoogleUrl + props.coords.latitude + "," + props.coords.longitude + apiGoogleKey
            )
            .then( response => {
                const getResultsFiltered = (results) =>{
                    const resultFiltered=[];
                    results.forEach(element => {
                        const comps = element.address_components;
                        let distritos = comps.filter((component => component.types.includes("locality")));
                        let distritoSelected = distritos.length > 0 ? distritos[0] : comps.filter((component => component.types.includes("sublocality"))).length>0 ? comps.filter((component => component.types.includes("sublocality")))[0] : {};
                        if (distritoSelected.long_name !== "Cercado de Lima" && distritoSelected.long_name !== "Lima" ) resultFiltered.push(element);
                    });
                    return resultFiltered.length > 0 ? resultFiltered[0]:results[0];
                }

                //filter when the result is cercado de Lima
                let result = getResultsFiltered(response.data.results);     
                
                let components = result.address_components;

                const department = components.filter((component => component.types.includes("administrative_area_level_1")))[0]
                
                const province = components.filter((component => component.types.includes("administrative_area_level_2")))[0]

                let districts = components.filter((component => component.types.includes("locality")))

                
                const district = districts.length > 0 ? districts[0] : components.filter((component => component.types.includes("sublocality"))).length>0 ? components.filter((component => component.types.includes("sublocality")))[0] : {}

                //console.log(department.long_name, province.long_name, district.long_name);
            })
            .catch(e => {
                console.warn(e)
            })
        }
    
    }, [props, apiGoogleKey])

    let publicRoutes = (
        <Routes>       
            <Route index element={<Login/>} />
            <Route path="*" element={<Login />} />
            <Route path={ROUTES.MAPA} element={<Mapa/>} />
        </Routes>
    )

    let routes = (
        <Routes>  
            <Route index element={<Login/>} />      
            <Route path={ROUTES.ONBOARDING} element={<OnBoarding title={"Bienvenido, " + formatNameCapitals(username)}/>}/>    
            <Route path={ROUTES.ACTIVITIES} element={loading === true ? <></> : <Layout title='Inicio' lastCheckIn={lastCheckInObject === null}><Activities checkIn={lastCheckInObject} setCheckIn={setLastCheckInObject}/></Layout>}/>    
            <Route path={ROUTES.ACTIVITY_DETAIL} element={<Layout title="Detalle" lastCheckIn={lastCheckInObject === null} optionBack><ActivityDetail checkIn={lastCheckInObject}/></Layout>}/>    
            <Route path={ROUTES.ATTENDANCE} element={<Layout title="Mi Asistencia" lastCheckIn={lastCheckInObject === null} optionBack><Attendance/></Layout>}/> 
            <Route path={ROUTES.ATTENDANCE_RESULTS} element={<Layout title="Resultados de Asistencia" lastCheckIn={lastCheckInObject === null} optionBack><AttendanceResults/></Layout>}/>   
            <Route path={ROUTES.ATTENDANCE_REPORT} element={<Layout title="Resumen de asistencias" lastCheckIn={lastCheckInObject === null} optionBack><AttendanceReport/></Layout>}/>  
            <Route path={ROUTES.ATTENDANCE_STUDENT_SCAN} element={<Layout title="Toma de Asistencia" lastCheckIn={lastCheckInObject === null} optionBack><AttendanceStudentScan/></Layout>}/>   
            <Route path={ROUTES.ATTENDANCE_STUDENT_INFO} element={<Layout title="Toma de Asistencia" lastCheckIn={lastCheckInObject === null} optionBack><AttendanceStudentInfo/></Layout>}/>  
            <Route path={ROUTES.CAPACITY} element={<Layout title="Consulta de Aforos" lastCheckIn={lastCheckInObject === null}><Capacity/></Layout>}/> 
            <Route path={ROUTES.CAPACITY_RESULTS} element={<Layout title="Consulta de Aforos" lastCheckIn={lastCheckInObject === null} optionBack><CapacityResults/></Layout>}/> 
            <Route path={ROUTES.INCIDENTS} element={<Layout title="Reportar Incidencia" lastCheckIn={lastCheckInObject === null} incident><Incidents/></Layout>}/>          
            <Route path={ROUTES.QR_PRE} element={loading === true ? <></> : <Layout title={lastCheckInObject === null ? "Escanear Código QR" : "Estas aquí:"} lastCheckIn={lastCheckInObject === null}>{lastCheckInObject === null ? <QRScan/> : <LocationDetails checkIn={lastCheckInObject} setCheckIn={setLastCheckInObject}/>}</Layout>}/>  
            <Route path={ROUTES.QR_SCAN} element={<Layout title="Escanear Código QR" lastCheckIn={lastCheckInObject === null} optionBack><QRScanScanner/></Layout>}/>        
            <Route path={ROUTES.LOCATION_DETAILS + '/:key'} element={<Layout title={lastCheckInObject !== null ? 'Estas aquí:' : 'Te encuentras aquí:'} lastCheckIn={lastCheckInObject === null} optionBack><LocationDetails checkIn={lastCheckInObject} setCheckIn={setLastCheckInObject} /></Layout>}/>          
            <Route path={ROUTES.PROFILE} element={<Layout title="Mi Perfil" lastCheckIn={lastCheckInObject === null}><Profile/></Layout>}/>   
        </Routes>
    );

    return(
        <BrowserRouter>
            {userInf && userInf.role && userInf.role != null ? routes : publicRoutes}
        </BrowserRouter>
    )


};

AppRouter.propTypes = { ...AppRouter.propTypes, ...geoPropTypes };

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(AppRouter);
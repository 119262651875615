import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import './ActivityCard.css';
import ButtonWithIcon from '../../Button/ButtonWithIcon';
import accessIcon from '../../../assets/icons/check-in.svg';
import exitIcon from '../../../assets/icons/check-out.svg';
import {MARINE_BLUE, ORANGE, SKY_BLUE} from '../../../constants/colors';
import { formatNameCapitals } from '../../../constants/commonFunctions';

const ActivityCard=(props)=> {
    const {activityId, title, site, type, time, checkIn, checkOut, sessionId, register, unscheduled, timeCheckIn, role, isAbsent, isReport} = props;
    const activityNameRef = React.useRef('');
    const siteNameRef = React.useRef('');
    const [timeIn, setTimeIn] = React.useState(timeCheckIn);
    const [hoverActivityNameRef, setHoverActivityNameRef] = React.useState(false);
    const [hoverSiteNameRef, setHoverSiteNameRef] = React.useState(false);

    const compareSizeSiteName = () => {
        const compareSiteName =
        siteNameRef.current.scrollWidth >
        siteNameRef.current.clientWidth;
        setHoverSiteNameRef(compareSiteName);
    };
    const compareSizeActivityName = () => {
        const compareActivityName =
        activityNameRef.current.scrollWidth >
        activityNameRef.current.clientWidth;
        setHoverActivityNameRef(compareActivityName);
    };
  
    // compare once and add resize listener on "componentDidMount"
    React.useEffect(() => {
    compareSizeSiteName();
    compareSizeActivityName();
    window.addEventListener('resizeActivityName', compareSizeSiteName);
    window.addEventListener('resizeSiteName', compareSizeActivityName);
    }, []);

    // remove resize listener again on "componentWillUnmount"
    React.useEffect(
    () => () => {
        window.removeEventListener('resizeActivityName', compareSizeSiteName);
        window.removeEventListener('resizeSiteName', compareSizeActivityName);
    },
    []
    );

    return (
        <Card className={((isReport !== undefined && isReport !== null && isReport === true) ? "activityCardPIReport" : "activityCardPI")}>
                <CardContent className="cardContent">
                    {unscheduled ? 
                        <Grid container direction="column">
                            <Grid item className='text-title-container max-width-full'>
                                <Typography 
                                    ref={siteNameRef}
                                    align='left'
                                    variant="h4" 
                                    color="primary"
                                    component="h4"
                                    gutterBottom
                                    className='text-overflow'
                                >
                                    {formatNameCapitals(title)}
                                </Typography>
                                {hoverSiteNameRef ? 
                                (
                                    <span className="tooltiptext">{formatNameCapitals(title)}</span>
                                )
                                :
                                (
                                    <></>
                                )}
                            </Grid>
                            <Typography 
                                    ref={activityNameRef}
                                    align='left'
                                    component="h4"                     
                                    variant="h6"
                                    color={ORANGE}
                                    gutterBottom
                                    className='text-overflow'
                                >
                                    {"Ingreso: " + timeIn}
                                </Typography>
                                {hoverActivityNameRef ? 
                                (
                                    <span className="tooltiptext">{"Ingreso: " + timeIn}</span>
                                )
                                :
                                (
                                    <></>
                                )}
                            <Grid item container>
                                <ButtonWithIcon 
                                    cardButton
                                    className='button'
                                    color={checkIn ? "access" : "exit"}
                                    onClick={ () => register(activityId, sessionId, checkOut ? 2 : 1, site) }
                                    iconButton={checkIn || (!checkOut && !checkIn) ? accessIcon : exitIcon}
                                    leftIcon={checkIn && !checkOut || (!checkOut && !checkIn)}
                                    rightIcon={checkOut && !checkIn}
                                    buttonText={checkIn || (checkIn && !checkOut) || (!checkOut && !checkIn) ? "Registrar Ingreso" : "Registrar Salida"}
                                    disabled={!checkOut && !checkIn ? true : false }
                                />  
                            </Grid>
                        </Grid>
                    :
                        <Grid container direction="column">
                            <Grid item className='text-title-container max-width-full'>
                                <Typography 
                                    ref={activityNameRef}
                                    align='left'
                                    component="h4"                     
                                    variant="h6"
                                    color="primary"
                                    gutterBottom
                                    className='text-overflow'
                                >
                                    {type && type.toUpperCase() === "EVENTO" ? formatNameCapitals(title.split(' - ')[1]) : formatNameCapitals(title) }
                                </Typography>
                                {hoverActivityNameRef ? 
                                (
                                    <span className="tooltiptext">{type && type.toUpperCase() === "EVENTO" ? formatNameCapitals(title.split(' - ')[1]) : formatNameCapitals(title) }</span>
                                )
                                :
                                (
                                    <></>
                                )}
                            </Grid>
                            <Grid container direction="row" className='text-container'>
                                <Grid item xs={12}>
                                    <Typography 
                                        ref={siteNameRef}
                                        align='left'
                                        variant="h5" 
                                        color={MARINE_BLUE}
                                        component="h5"
                                        gutterBottom
                                        className='text-overflow'
                                    >
                                        {formatNameCapitals(site)}
                                    </Typography>
                                    {hoverSiteNameRef ? 
                                    (
                                        <span className="tooltiptext">{formatNameCapitals(site)}</span>
                                    )
                                    :
                                    (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <Typography 
                                        align='left'
                                        variant="h5" 
                                        color={SKY_BLUE} 
                                        component="h5"
                                    >
                                        {formatNameCapitals(type)}
                                    </Typography>
                                </Grid>           
                                {time && time !== null && (
                                    <Grid item container xs={4} justifyContent="flex-end">
                                        <div className='shift'>
                                            <Typography  className='shift-text'
                                                variant="h6" 
                                                color={ORANGE}
                                                component="h6"
                                            >
                                                {formatNameCapitals(time)}
                                            </Typography>    
                                        </div>
                                    </Grid>
                                )}             
                            </Grid>

                            {(isAbsent === undefined || isAbsent === null || role > 0) && register &&
                            <Grid item container>
                                <ButtonWithIcon 
                                    cardButton
                                    className='button'
                                    color={checkIn ? "access" : "exit"}
                                    onClick={ () => register(activityId, sessionId, checkOut ? 2 : 1, site) }
                                    iconButton={checkIn || (!checkOut && !checkIn) ? accessIcon : exitIcon}
                                    leftIcon={checkIn && !checkOut || (!checkOut && !checkIn)}
                                    rightIcon={checkOut && !checkIn}
                                    buttonText={checkIn || (checkIn && !checkOut) || (!checkOut && !checkIn) ? "Registrar Ingreso" : "Registrar Salida"}
                                    disabled={!checkOut && !checkIn ? true : false }
                                />  
                            </Grid>
                            }
                            {role === 0 && isAbsent === 0 &&
                            <Grid item container className='label-attended'>
                                <Typography variant='outlined'>Asistió</Typography>
                            </Grid>
                            }
                            {role === 0 && isAbsent === 1 &&
                            <Grid item container className='label-absent'>
                                <Typography variant='outlined'>Faltó</Typography>
                            </Grid>
                            }
                        </Grid>
                    }
                </CardContent>    
                
        </Card>
    );
}
export default ActivityCard;
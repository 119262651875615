import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../routes/routes.js';
import { 
    Button,
    Container,
    Box,
    Grid,
    Tabs,
    Tab,
    Input,
    TextField,
    Select,
    MenuItem
} from '@mui/material';
import Typography from "@mui/material/Typography";
import { useDispatch } from 'react-redux';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import pucpLogo from "../../assets/icons/Logo_PUCP.svg";
import pucpInCaps from "../../assets/icons/PUCP-IN.svg";
import { getInfoUser } from "../../services/security/securityServices";
import * as actionTypes from '../../store/actions/actionsType';
import BasicAlerts from '../../components/Alert/Alert';
import { WHITE } from "../../constants/colors"
import './Login.css';
import { getActivities } from '../../services/activities/ActivitiesServices.js';
import { getLastDayCheckIn } from '../../services/attendance/AttendanceServices.js';
import { registerUser } from '../../services/user/userServices.js';

const clientId = window.globalConfig.clientId || "219340455324-8pm0uhcsh803q8rqscat8dhuvnvfoddu.apps.googleusercontent.com";

const Login = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [doiType, setDoiType] = React.useState(null);
    const [doiNumber, setDoiNumber] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [showloginButton, setShowloginButton] = React.useState(true);
    const [showlogoutButton, setShowlogoutButton] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [messageError, setMessageError] = React.useState("");
    const [waiting, setWaiting] = React.useState(false);

    const session = JSON.parse(localStorage.getItem("session"));

    React.useEffect( () => {
      // Acts like a firewall
      if(localStorage.getItem("userPUCPIN") === null) {
        setLoading(false);
        navigate('/');
      }

      if(window.location.pathname !== '/') {
        return;
      }

      // Been here before
      if(localStorage.getItem("session") && localStorage.getItem("userPUCPIN")) {
        const userPucpIn = JSON.parse(localStorage.getItem("userPUCPIN")) ;

        let email, personId;

        if(userPucpIn && userPucpIn.role && userPucpIn.role != null) {
          personId = userPucpIn.role.personId
        }

        email = userPucpIn.email;

        getNextPage(email, personId);
      }
      // First time
      else if(localStorage.getItem("userPUCPIN")) {
        navigate(ROUTES.ONBOARDING);
      }
    }, []);

    const getNextPage = (username, personId) => {
      getLastDayCheckIn({user: username, personId: personId})
        .then((response) => {
          if(response.data.resultCode === 0) {
            // There is an active check-in
            navigate(ROUTES.QR_PRE);
          }
          else {
            let date = new Date()
                    
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let hour = date.getHours();
            let minute = date.getMinutes();
            let seconds = date.getSeconds();
                        
            let stringDate = `${day < 10 ? '0'+day : day}/${month < 10 ? '0'+month : month}/${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${seconds < 10 ? '0' + seconds : seconds}`
                            
            const variables = {
                firstResult: 1,
                maxResults: 1000,
                user: username,
                personId: personId,
                date: stringDate
            };
                  
            getActivities(variables)
              .then(function (response) {
                if(response.data.resultCode === 1003) {
                  // No activities
                  navigate(ROUTES.QR_PRE);
                }
                else {
                  // User has activities
                  navigate(ROUTES.ACTIVITIES);
                }
              })
              .catch(function (error) {
                console.log(error);
                navigate(ROUTES.QR_PRE);
              });
          }
        })
        .catch((error) => {
          console.log("Login error: ", error);
          navigate(ROUTES.QR_PRE);
        });
    }

    const onLoginSuccess = (res) => {
        var email = res.profileObj.email;
        var role = null
        var personId = null

        setWaiting(true);

        getInfoUser({user : email})
            .then(async function (response) {

              if(response.data.resultCode === 1003) {
                // Register the user
                const aux = await registerUser({
                  email: email,
                  fullName: (res.profileObj.givenName + ' ' + res.profileObj.familyName).toUpperCase()
                });

                role = {
                  fullName: (res.profileObj.givenName + ' ' + res.profileObj.familyName).toUpperCase(),
                  message: "OK",
                  resultCode: 0,
                  roleId: 3,
                  roleName: "regular",
                  user: email
                };
              }
              else {
                role = response.data;
              }              

              dispatch({ type: actionTypes.LOGIN, user: {...(res.profileObj), role} });
                localStorage.setItem("userPUCPIN", JSON.stringify( {...(res.profileObj), role}));
                // First time using the App
                if(session === null) {
                  localStorage.setItem("session", JSON.stringify(true));
                  navigate(ROUTES.ONBOARDING);
                }
                // Been here before
                else {
                  getNextPage(email, null);
                }
              })
              .catch(function (error) {
                  setError(true);
                  setMessageError('Su usuario no está habilitado para ingresar al sistema.');
                  console.log(error);
                  setWaiting(false);
              });
    };

    const onLoginFailure = (res) => {
      setError(true);
      setMessageError('Inicio de sesión fallido: ' + res.error);
      console.log("google login error", res);
    };

    const onSignoutSuccess = () => {
        alert("You have been logged out successfully");
        console.clear();
        setShowloginButton(true);
        setShowlogoutButton(false);
    };

    const onSignoutFailure = (res) => {
      setError(true);
      setMessageError('Cierre de sesión fallido: ' + res.error);
      console.log("google signout error", res);
    };

    const externalLogin = () => {
      var email = null
      var role = null
      var personId = null

      setWaiting(true);

      getInfoUser({doiType : doiType, doiNumber: doiNumber, password: password})
      .then((response) => {
        role = response.data;
        email = response.data.user;
        personId = response.data.personId;
        dispatch({ type: actionTypes.LOGIN, user: {role} });
        localStorage.setItem("userPUCPIN", JSON.stringify( {role}));
        // First time using the App
        if(session === null) {
          localStorage.setItem("session", JSON.stringify(true));
          navigate(ROUTES.ONBOARDING);
        }
        // Been here before
        else {
          getNextPage(email, personId);
        }
      })
      .catch(function (error) {
        setError(true);
        setMessageError('Su usuario no está habilitado para ingresar al sistema.');
        console.log(error);
        setWaiting(false);
      });
    }

    React.useEffect(()=>{
        if (error){
            setTimeout(() => {
                setError(false);
            }, 5000);
        }

    }, [error])

    return (
      <>
        {error && <BasicAlerts type="error" message={messageError} />}
        {loading === false && (
          <Grid container xs={12} alignItems='center' justifyContent='center' className="loginContainer">
            <Grid container xs={12} alignItems='center'>
              <Grid container xs={12} md={6} justifyContent='center' alignItems='center'>
                <Grid container xs={12} className="imageLogoContainer" justifyContent='center' alignItems='center'>
                  <img src={pucpLogo} className="loginImg" alt="PUCP-IN Logo" />
                  <img
                    src={pucpInCaps}
                    className="loginCaps"
                    alt="PUCP-IN palabra"
                  />
                </Grid>
                <Grid alignItems='center' container justifyContent='center'>
                  <Typography
                    variant="h3"
                    color={WHITE}
                    component="div"
                    className="textInitial"
                  >
                    Somos&nbsp;<Box display="inline" fontWeight="fontWeightBold">PUCP</Box>, seámoslo siempre.
                  </Typography>
                </Grid>
              </Grid>

              <Grid justifyContent='center' container xs={12} md={6}>
                <Grid className='form-login' justifyContent='center' container xs={12}> 
                  <Grid justifyContent='center' container xs={12}>
                    <Tabs className='scrollable' value={value} onChange={(event, newValue) => {setDoiType(null); setDoiNumber(''); setPassword(''); setValue(newValue)}}>
                      <Tab value={0} label="Comunidad PUCP"/>
                      <Tab value={2} label="Visitantes y Externos"/>
                      <Tab value={1} label="Externo"/>
                    </Tabs>
                  </Grid>
                  <Grid container xs={12} justifyContent='center'>
                    {
                    value === 2 ? 
                    <Grid xs={12} container>
                      <Button
                        variant="contained"
                        className="buttonGoogleEnter"
                        onClick={() => {navigate(ROUTES.MAPA)}}
                        color="login"
                      >
                        <Typography variant="button" color="primary" component="span">
                          Ver Mapa
                        </Typography>
                      </Button>
                    </Grid>
                    : value === 0 ? 
                    <>
                      {showloginButton ? (
                        <GoogleLogin
                          clientId={clientId}
                          render={(renderProps) => (
                            <Button
                              disabled={waiting}
                              variant="contained"
                              className="buttonGoogleEnter"
                              onClick={renderProps.onClick}
                              color="login"
                            >
                              <Typography variant="button" color="primary" component="span">
                                Ingresar
                              </Typography>
                            </Button>
                          )}
                          onSuccess={onLoginSuccess}
                          onFailure={onLoginFailure}
                          cookiePolicy={"single_host_origin"}
                        />
                      ) : null}

                      {showlogoutButton ? (
                        <Grid container xs={12}>
                          <GoogleLogout
                            clientId={clientId}
                            render={(renderProps) => (
                              <Button
                                variant="contained"
                                className="buttonGoogleEnter"
                                onClick={renderProps.onClick}
                                color="login"
                              >
                                <Typography variant="button" color="primary" component="span">
                                  Salir
                                </Typography>
                              </Button>
                            )}
                            buttonText="Sign Out"
                            onLogoutSuccess={onSignoutSuccess}
                            onFailure={onSignoutFailure}
                          ></GoogleLogout>
                        </Grid>
                      ) : null}
                    </> : 
                    <Grid my='10px' xs={12} container>
                      <Grid my='10px' xs={12} container>
                        <TextField select onChange={(event) => {setDoiType(event.target.value)}} value={doiType} label='Tipo de DOI' color='primary' size='medium' fullWidth>
                          <MenuItem value={0}>(DNI) Documento Nacional de Identidad</MenuItem>
                          <MenuItem value={1}>(CE) Carné de extranjería</MenuItem>
                        </TextField>                       
                      </Grid>
                      <Grid my='10px' xs={12} container>
                        <TextField value={doiNumber} onChange={(event) => {setDoiNumber(event.target.value)}} fullWidth size='medium' label='Número de DOI'/>
                      </Grid>
                      <Grid my='10px' xs={12} container>
                        <TextField value={password} onChange={(event) => {setPassword(event.target.value)}} fullWidth size='medium' label='Contraseña' type='password'/>
                      </Grid>
                      <Grid xs={12} container>
                        <Button
                          variant="contained"
                          className="buttonGoogleEnter"
                          onClick={() => {externalLogin()}}
                          color="login"
                        >
                          <Typography variant="button" color="primary" component="span">
                            Ingresar
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    }
                </Grid>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );

    
}

export default Login;
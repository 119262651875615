import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Auxiliar from '../Auxiliar/Auxiliar.js';
import { useNavigate, useLocation } from 'react-router-dom';
import * as ROUTES from '../../routes/routes.js';
import ArrowBackIcon from '../../assets/icons/back-arrow.svg';
import HomeIcon from '../../assets/icons/inicio.svg';
import QRIcon from '../../assets/icons/qr.svg';
import locationIcon from '../../assets/icons/location.svg'
import CapacityIcon from '../../assets/icons/aforo.svg';
import IncidentIcon from '../../assets/icons/incidencia.svg';
import ProfileIcon from '../../assets/icons/perfil.svg';
import TopBar from "../../components/TopBar/TopBar";
import Navbar from "../../components/Navbar/Navbar";
import './Layout.css';

const Layout  = (props) => {   
    const {title, optionBack, cursiveTitle, incident, lastCheckIn} = props;
    const [value, setValue] = React.useState(0);
    const [goBackActive, setGoBackActive] = React.useState(false);
    const ref = React.useRef(null);
    let navigate = useNavigate();
    let location = useLocation();


    const switchBottomOption = () =>{
        switch(location.pathname){
            case ROUTES.ACTIVITIES:
                setValue(0);
                break;
            case ROUTES.CAPACITY:
            case ROUTES.CAPACITY_RESULTS:
                setValue(1);
                break;
            case ROUTES.QR_PRE:
            case ROUTES.QR_SCAN:
            case ROUTES.LOCATION_DETAILS:
            case ROUTES.ATTENDANCE_REPORT:
            case ROUTES.ATTENDANCE_STUDENT_SCAN:
            case ROUTES.ATTENDANCE_STUDENT_INFO:
                setValue(2);
                break;
            case ROUTES.INCIDENTS:
                setValue(3);
                break;
            case ROUTES.PROFILE:
            case ROUTES.ATTENDANCE:
            case ROUTES.ATTENDANCE_RESULTS:
                setValue(4);
            default:
                break;
        };

    }
    React.useEffect(() => {
        switchBottomOption();
    }, [location]);

    React.useEffect(() => {        
        switchBottomOption();        
    }, []);

    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;        
    }, [value]);   
    
    const changeMenuOption = (value) =>{
        switch(value){
            case 0:
                navigate(ROUTES.ACTIVITIES);
                break;
            case 1:
                navigate(ROUTES.CAPACITY);
                break;
            case 2:
                navigate(ROUTES.QR_PRE);
                break;
            case 3:
                navigate(ROUTES.INCIDENTS);
                break;
            case 4:
                navigate(ROUTES.PROFILE);
                break;
            default:
                break;
        };

    }

    const goBack = () => {      
        setGoBackActive(true);  
        navigate(-1);
    }

    return (
      <Auxiliar>
        <Box ref={ref}>
          <TopBar
            optionBack={optionBack || (incident && location.state && location.state.location )}
            ArrowBackIcon={ArrowBackIcon}
            goBack={goBack}
            title={title}
          />
          {props.children}
          <CssBaseline />
          <Navbar
            value={value}
            setValue={setValue}
            changeMenuOption={changeMenuOption}
            firstIcon={HomeIcon}
            secondIcon={CapacityIcon}
            thirdIcon={lastCheckIn === true ? QRIcon : locationIcon}
            fourthIcon={IncidentIcon}
            fifthIcon={ProfileIcon}
          />
        </Box>
      </Auxiliar>
    );
};


export default Layout;
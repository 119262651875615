import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const registerIncident = (variables) => {
    const obj = {
        keyCode: API_URL.keyCode,
        user: variables.user,
        personId: variables.personId,
        reasonIncidentId: variables.reasonIncidentId,
        siteId: variables.siteId,
        buildingId: variables.buildingId,
        insideCampus: variables.insideCampus,
        incidentDate: variables.incidentDate,
        description: variables.description
    }
    return axios.post(
      API_URL.url + "incident/register",
      obj,
    );
};
import React, {useRef} from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AppRouter from "./routes/AppRouter/AppRouter";
import theme from "./assets/theme/themeConfig";
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import es_PELocale from "date-fns/locale/es";
import { StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const App = () => {

  const innerRef = useRef();

  return (    
    <StyledEngineProvider injectFirst>   
      <ThemeProvider theme={theme}>  
        <CssBaseline/>
          <LocalizationProvider dateAdapter={DateAdapter} locale={es_PELocale}>       
            <AppRouter onError={(error) => console.log(error)} ref={innerRef} />   
          </LocalizationProvider>      
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;



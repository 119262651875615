import * as React from 'react';
import { 
    Button,
} from '@mui/material';
import './ButtonWithIcon.css';
import { formatNameCapitals } from '../../constants/commonFunctions';

const ButtonWithIcon  = (props) => {
    const {buttonText, iconButton, onClick, leftIcon, rightIcon, color, disabled, fullWidth, noCaps, cardButton, variant} = props;
    const icon = (<img className={disabled ? 'disabledIcon' : 'icon'} src={iconButton}/>);

    return(
            <Button 
                className={cardButton?"cardButton":"buttonWithIcon"}
                variant={variant ? variant : 'contained'}
                color={color}
                onClick={ () => onClick() }
                disabled={disabled}
                fullWidth={fullWidth}
            >
                {leftIcon ? icon : null}
                
                {noCaps ? buttonText : formatNameCapitals(buttonText)}
                
                {rightIcon ? icon : null}

            </Button>
    )

};

export default ButtonWithIcon;
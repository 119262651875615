import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Container, Grid, Typography } from '@mui/material';
import qrIcon from '../../../assets/icons/qr.svg';
import infoBlueIcon from '../../../assets/icons/infoBlue.svg';
import locationSiteIcon from '../../../assets/icons/locationSite.svg';
import clockIcon from '../../../assets/icons/clock.svg';
import syncIcon from '../../../assets/icons/sync.svg';
import arrowIcon from '../../../assets/icons/arrowNext.svg';
import siteImage from '../../../assets/images/site.png';
import { formatDate, formatJustTimeNoSeconds, formatJustTimeNoSecondsLowercase, formatNameCapitals, formatSeconds, secondsOfTime } from '../../../constants/commonFunctions';
import './LocationDetailsCard.css'

const LocationDetailsCard = (props) => {
    const { activityLabel, typeActivity, siteName, buildingName, span, shift, role, totalRegistered, totalSchedule, arrive, lastCheckIn, noImage, isActivity, onClick, checkIn, today} = props;
    const [now, setNow] = React.useState(new Date());
    const [activityCode, setActivityCode] = React.useState('');
    const [activityName, setActivityName] = React.useState('');
    const [activityScheduleCode, setActivitySchedyleCode] = React.useState('');

    React.useEffect(() => {
        const interval = setInterval( () => setNow(new Date()), 1000 );
        if(activityLabel !== null){
            const act = activityLabel.split('-');
            setActivityCode(act[0])
            setActivityName(act[1])
            setActivitySchedyleCode(act[2])
        }
    }, [])

    return (
        
        <Card className="locationDetailCard">
                <CardContent className="cardLocationContent">
                    <Grid container>
                        <Grid container xs={noImage && ((checkIn !== null && checkIn !== undefined) || (((checkIn === null || checkIn === undefined) && (isActivity || role === 1) && today === formatDate(new Date())))) ? 11 : 12} className="containerLocation">
                            <Grid item>
                                <Typography 
                                    align='left'
                                    color='#000000'
                                    variant="h4" 
                                    className='label-weight-location'
                                    gutterBottom>
                                    {activityLabel ? activityCode + (activityName ? formatNameCapitals(" - " + activityName) : '') + (activityScheduleCode ? ' - ' + activityScheduleCode : '') : "Actividad no programada"}
                                </Typography>   
                                <Typography 
                                    align='left'
                                    color='#5488C5'
                                    variant="h5"
                                    gutterBottom>
                                    {typeActivity}
                                </Typography>   
                                <Grid display='flex' alignItems='center' marginBottom="2px">
                                    <img style={{color: '#004EA8'}} src={locationSiteIcon}></img>
                                    <Typography
                                        align='left'
                                        color='#004EA8'
                                        variant="h5"
                                        className='label-weight-location'
                                        marginLeft="5px"
                                        marginRight="5px">
                                        {siteName + (buildingName ? " - " + buildingName : '')}
                                    </Typography>

                                </Grid>
                                <Grid display='flex' marginBottom="2px">
                                    <img src={clockIcon}></img>
                                    <Typography
                                        align='left'
                                        color='#003777'
                                        variant="h5"
                                        className='label-weight-location'
                                        marginLeft="5px">
                                        {shift}
                                    </Typography>   
                                </Grid>
                                {checkIn !== null && arrive && 
                                    <Typography
                                        align='left'
                                        color='primary'
                                        variant="h5"
                                        className='label-weight-location'>
                                            {"Ingreso: " + formatJustTimeNoSeconds(arrive)}
                                    </Typography>   
                                }
                                
                            </Grid>
                            {!noImage && <Grid>
                                <Grid className='timer' alignItems='center'>
                                    <div id="countdown" style={{'--time-value': 412 - (412/99*(secondsOfTime((now.getTime() - (checkIn !== null && checkIn !== undefined? lastCheckIn.getTime() : now.getTime()))/1000)))}}>
                                        <svg height="100" width="100">
                                            <circle r="40" cx="90" cy="51.5">
                                            </circle>
                                            Sorry, your browser does not support inline SVG. 
                                        </svg>
                                    </div>
                                    <Grid className='image-site'>
                                        <img src={siteImage}/>
                                    </Grid>
                                </Grid>
                                
                                {checkIn !== null && checkIn !== undefined && 
                                <Typography 
                                    className='timerTextNumber'
                                    variant='h5'
                                    component='h5'
                                    color='primary'
                                    marginTop="5px">
                                        {formatSeconds((now.getTime() - lastCheckIn.getTime())/1000)}
                                </Typography>
                                }
                            </Grid>
                            }
                        </Grid>
                        {noImage && ((checkIn !== null && checkIn !== undefined) || ((checkIn === null || checkIn === undefined) && (isActivity || role === 1) && today === formatDate(new Date()))) && 
                        <Grid item className='image-arrow'>
                            <img src={arrowIcon} onClick={onClick}></img>
                        </Grid>
                        }
                    </Grid>
                </CardContent>    
        </Card>
    )
}

export default LocationDetailsCard;
import { Button, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useLocation, useNavigate } from 'react-router-dom';
import BasicAlerts from '../../components/Alert/Alert';
import { getStudentActivity } from "../../services/activities/ActivitiesServices";
import * as ROUTES from "../../routes/routes";

import { ATTENDANCE_STUDENT_INFO } from '../../routes/routes';
import './AttendanceStudentScan.css';

const AttendanceStudentScan  = () => {

    const { state } = useLocation();
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if(!(state && state.sessionId)) {
            navigate('/');
            return;
        }

        if(state.message) {
            setAlertType(state.alertType);
            setAlertMessage(state.message);
            setActiveMessage(true);
        }
    }, []);

    React.useEffect(()=>{
        
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage]);

    const handleScan = (err, result) => {
        if (result) {
            //console.log('Scanned: ' + result.text);
            
            getStudentActivity({documentNumber: result.text})
            .then((response) => {
                //console.log(response.data);
                navigate(ATTENDANCE_STUDENT_INFO, {
                    state: {
                        documentNumber: result.text,
                        sessionId: state.sessionId,
                        origin: 2
                    }
                });
                
            })
            .catch((error) => {
                if(error && error.response && error.response.data) {
                    if(error.response.data.resultCode === 1002) {
                        setAlertType("error");
                        setAlertMessage("La persona no esta registrada.");
                        setActiveMessage(true);
                    }
                }
            });
        }
    }

    return (
        <>
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }

            <Grid container className='instructionsPreScan' justifyContent='center'>
                <Typography variant='h4' component='h4' color='primary' align='center'>Coloque el código de Barra o QR en el interior del rectángulo para escanear</Typography>
            </Grid>
            
            <Grid spacing={0} container direction="row">
                <Grid item xs={0} lg={4}/>
                <Grid item xs={12} lg={4} >
                    <BarcodeScannerComponent
                        width='100%'
                        height='100%'
                        torch={false}
                        delay={500}
                        onUpdate={handleScan}
                        facingMode='environment'
                    />
                </Grid>
            </Grid>  


            <Grid container className='button-back'>
                <Button
                    className='button-return'
                    variant='outlined'
                    onClick={() =>
                        navigate(ROUTES.QR_PRE)}
                    fullWidth
                >
                    Volver
                </Button>
            </Grid>
            
        </>
    );

}

export default AttendanceStudentScan;
import * as React from 'react';
import { 
    Button, Typography,
} from '@mui/material';
import './CircleButton.css';
import { formatNameCapitals } from '../../constants/commonFunctions';

const CircleButton  = (props) => {
    const {buttonText, iconButton, onClick, disabled, noCaps, className, hx} = props;
    const icon = (<img src={iconButton}/>);

    return(
            <Button 
                className={className ? className : "circleButton"}
                variant="contained"
                onClick={ () => onClick() }
                disabled={disabled}
            >
                {icon}
                <Typography 
                    variant={hx}
                >
                    {noCaps ? buttonText : formatNameCapitals(buttonText)}
                </Typography>
            </Button>
    )

};

export default CircleButton;
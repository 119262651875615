import React from 'react'
import {Grid, Typography } from "@mui/material";
import {GoogleLogout } from "react-google-login";
import { useNavigate} from "react-router-dom";
import LogOut from "../../assets/icons/logout.svg";
import "./TopBar.css";

const TopBar = (props) => {
  const { optionBack, ArrowBackIcon, goBack, title } = props;

  const clientId = window.globalConfig.clientId || "219340455324-8pm0uhcsh803q8rqscat8dhuvnvfoddu.apps.googleusercontent.com";

  let navigate = useNavigate();

  const logOut = () => {
    //dispatch({ type: actionTypes.LOGOUT, user: {} });
    localStorage.clear();
    localStorage.setItem("session", JSON.stringify(true));
    navigate("/");
  };

  const onSignoutFailure = (res) => {
    console.log("google signout error", res);
  };

  return (
    <Grid container className="titleLayoutPI">
      <Grid item container xs={10} md={11} alignItems="center">
        {optionBack && (
          <img
            src={ArrowBackIcon}
            alt="ícono de flecha para retroceder"
            className="iconTitlePI"
            onClick={() => goBack()}
          />
        )}
        {optionBack ? (
          <Typography
            className="title-header titleIconPI"
            variant="h3"
            color="#003777"
            component="span"
          >
            {title}
          </Typography>
        ) : (
          <Typography variant="h3" color="#004EA8" component="span" className='title-header'>
            {title}
          </Typography>
        )}
      </Grid>
      <Grid item container xs={2} md={1} direction="column" justifyContent="center" alignItems="center">
        <GoogleLogout
          clientId={clientId}
          render={(renderProps) => (
            <>
              <img
                src={LogOut}
                alt="ícono para cerrar sesión"
                className="iconTitlePI"
                onClick={renderProps.onClick}
              />           
              <span className="iconTextPI"  onClick={renderProps.onClick}>Salir</span>            
            </>
          )}
          buttonText="Sign Out"
          onLogoutSuccess={logOut}
          onFailure={onSignoutFailure}
        ></GoogleLogout>
      </Grid>
    </Grid>
  );
}

export default TopBar;

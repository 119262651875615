import { Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { QrReader } from 'react-qr-reader';
import { useLocation, useNavigate } from "react-router-dom";
import BasicAlerts from '../../components/Alert/Alert';
import * as ROUTES from "../../routes/routes";
import { getLocationInfoByQrKey } from '../../services/commons/commonsServices';
import './QRScan.css';
import { useSelector } from 'react-redux';


const QRScanScanner  = () => {

    const { state } = useLocation();
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [hasCameraPermission, setHasCameraPermission] = React.useState(false);
    const userInf = useSelector(state => state.userReducer.user); 
    const navigate = useNavigate();
    const [incident, setIncident] = React.useState(localStorage.getItem("incident"));

    React.useEffect(() => {
        if(!(state && state === 1 || state.state === 5 || state.sessionId)) {
            navigate(ROUTES.QR_PRE);
        }

    }, []);

    const onNewScanResult = (result, error) => {
        if (!!result) {
            const index = result?.text.search('/location/');
            if(index === -1) {
                setAlertType("error");
                setAlertMessage("El código QR es inválido.");
                setActiveMessage(true);
            }
            else {
                const key = result?.text.substring(index + 10);
                getLocationInfoByQrKey({ value: key }).then( (response) => {
                    if(response.data.resultCode === 1003) {
                        setAlertType("error");
                        setAlertMessage("El código QR es inválido.");
                        setActiveMessage(true);
                    }
                    else {
                        if(state.state===5) {
                            var locationObject = {
                                id: response.data.siteId !== null ? response.data.siteId : response.data.buildingId,
                                type: response.data.siteId !== null ? 0 : 1
                            };

                            if(response.data.siteId !== null) {
                                locationObject.comboName = (response.data.siPucpLabel === null ? response.data.siteName : response.data.siPucpLabel) + ' - ' + (response.data.shortName === null ? response.data.buildingName : response.data.shortName);
                            }
                            else {
                                locationObject.comboName = response.data.shortName === null ? response.data.buildingName : response.data.shortName;
                            }

                            navigate(ROUTES.INCIDENTS, {
                                state: {
                                    locationScanned: locationObject,
                                    "state" : 1
                                }
                            });
                        }
                        else {
                            // Validate the schedule id if it was sent
                            var obj = undefined;
                            if(state && state.sessionId) {
                                obj = {state: {sessionId: state.sessionId}}
                                if(!(response.data.siteId && response.data.siteId === state.siteId)) {
                                    console.log('Site passed doesnt match the scanned site.');
                                    setAlertType("error");
                                    setAlertMessage("El QR no corresponde al ambiente de la actividad elegida.");
                                    setActiveMessage(true);
                                    return;
                                }
                            }
                            
                            navigate(ROUTES.LOCATION_DETAILS + '/' + key, obj);
                        }
                    }
                }
                ).catch(function (error) {
                    console.log(error);
                    setAlertType("error");
                    setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                    setActiveMessage(true);
                });            
            }
        } 
    }

    React.useEffect(()=>{
        
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage])

    return(
        <>  
            <Container className='instructionsPreScan'>
                <Typography variant='h4' component='h4' color='primary' align='center' className='text'>Coloque el código QR en el interior del rectángulo para escanear</Typography>
            </Container>
            
            
            <Grid spacing={0} container direction="row">
                <Grid item xs={0} lg={4}/>
                <Grid item xs={12} lg={4} >
                    <QrReader
                        constraints={{facingMode: 'environment'}}
                        scanDelay={200}
                        onResult={onNewScanResult}
                    />
                </Grid>
            </Grid>  

            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }
        </>
    )

};

export default QRScanScanner;
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import { Container } from '@mui/material';
import LocationDetailsCard from '../../components/Card/LocationDetails/LocationDetailsCard';
import { formatNameCapitals } from '../../constants/commonFunctions';
import GroupCircleButton from '../../components/Button/GroupCircleButton';
import ArrowBackIcon from '../../assets/icons/back-arrow.svg';
import reportIncidentIcon from '../../assets/icons/reportIncident.svg';
import qrIcon from '../../assets/icons/qrbig.svg';

const ActivityDetail  = (props) => {

    const {checkIn} = props;
    const {state} = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        if(checkIn !== null || !state) {
            navigate(ROUTES.ACTIVITIES);
        }
    }, []);

    const registerIncident = () => {
        navigate(ROUTES.INCIDENTS, {
            state: {
                location: {
                    id: state.activity.siteId !== null ? state.activity.siteId : state.activity.buildingId,
                    type: state.activity.siteId !== null ? 0 : 1,
                    comboName: state.activity.siteId !== null ? (state.activity.siPucpLabel === null ? state.activity.siteName : state.activity.siPucpLabel) + ' - ' + (state.activity.shortName === null ? state.activity.buildingName : state.activity.shortName) : state.activity.shortName === null ? state.activity.buildingName : state.activity.shortName
                }
            }
    })}

    const returnActivities = () => {
        navigate(ROUTES.ACTIVITIES);
    }

    const registerCheckIn = () => {
        navigate(ROUTES.QR_SCAN, {
            state: {
                sessionId : state.activity.sessionId,
                siteId : state.activity.siteId
            }
        });
    }

    return (
        <>
            <Container className="containerCardsView">
                <div className="siteDetails" align='center'>
                {state && state.activity && 
                <>
                <LocationDetailsCard
                    activityLabel={state.activity.activityDescription}
                    typeActivity={state.activity.activityType}
                    siteName={formatNameCapitals(state.activity.site ? (state.activity.siPucpLabel === null ? state.activity.site : state.activity.siPucpLabel) : '')}
                    buildingName={formatNameCapitals(state.activity.shortName === null ? state.activity.building : state.activity.shortName)}
                    span={state.activity.building + (state.activity.site ? ' - ' + state.activity.site : '')}
                    shift={state.activity.shift}
                    lastCheckIn={new Date()}    
                />
                <br/>
                <GroupCircleButton
                    numButt={3}
                    buttonTexts={['Volver al Menú', 'Reportar Incidencia', 'Registrar Ingreso']}
                    iconButtons={[ArrowBackIcon, reportIncidentIcon, qrIcon]}
                    onClicks={[returnActivities, registerIncident, registerCheckIn]}
                />
                </>}
                </div>

            </Container>
        </>
    );
}

export default ActivityDetail;
import * as React from 'react';
import { 
    Container,
    Grid
} from '@mui/material';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon';
import DateField from '../../components/DateField/DateField.js';
import LinkWithIcon from '../../components/Link/LinkWithIcon';
import TuneIcon from '@mui/icons-material/Tune';
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useSelector } from 'react-redux';
import { getSitesAutocomplete, getDivisonsAutocomplete, getSectorsAutocomplete, getPersonsAutocomplete } from '../../services/commons/commonsServices';
import BasicAlerts from '../../components/Alert/Alert';
import {formatDate} from '../../constants/commonFunctions';
import ComboboxStyled from '../../components/Combobox/Combobox';
import searchIcon from '../../assets/icons/search.svg'
import './Attendance.css';
import { formatDateHyphen } from '../../constants/commonFunctions';

const Attendance  = () => {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());    
    const [compareDates, setCompareDates] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [advancedSetttings, setAdvancedSetttings] = React.useState(false);
    const userInf = useSelector(state => state.userReducer.user);    
    const [role, setRole] = React.useState(userInf && userInf.role? userInf.role.idRol:-1);

    const [person, setPerson] = React.useState("");
    const [idPerson, setIdPerson] = React.useState(-1);
    const [peopleObj, setPeopleObj] = React.useState([])
    const [people, setPeople] = React.useState([]);
    const [searchPerson, setSearchPerson] = React.useState('');

    const [place, setPlace] = React.useState("");
    const [idPlace, setIdPlace] = React.useState(-1);
    const [placesObj, setPlacesObj] = React.useState([])
    const [places, setPlaces] = React.useState([]);
    const [searchPlace, setSearchPlace] = React.useState('');

    const [division, setDivision] = React.useState("");
    const [idDivision, setIdDivision] = React.useState(-1);
    const [divisionsObj, setDivisionsObj] = React.useState([])
    const [divisions, setDivisions] = React.useState([]);
    const [searchDivision, setSearchDivision] = React.useState('');

    const [sector, setSector] = React.useState("");
    const [idSector, setIdSector] = React.useState(-1);
    const [sectorsObj, setSectorsObj] = React.useState([])
    const [sectors, setSectors] = React.useState([]);
    const [searchSector, setSearchSector] = React.useState('');

    let navigate = useNavigate();

    const goToResults = () => {
        let variables = {
          firstResult: 1,
          maxResults: 3,
          user: userInf.email,
          startDate: formatDate(startDate),  
        } 
        if (endDate){
            variables['endDate'] = formatDate(endDate);
        }
        if (advancedSetttings){
            if (endDate){
                variables['endDate'] = formatDate(endDate);
            }
            if (idPerson>-1){
                variables['idPerson'] = idPerson;
            }
            if (idPlace>-1){
                variables['idPlace'] = idPlace;
            }
            if (idDivision>-1){
                variables['idDivision'] = idDivision;
            }
            if (idSector>-1){
                variables['idSector'] = idSector;
            }            
        }
        navigate(ROUTES.ATTENDANCE_RESULTS, {
            state: {
                variables
            }
        });
    }

    const autocompleteFunction = (search, autocompleteService, setArray, setArrayObj, setId) => {
        if(search !== undefined && search.length > 2) {

            let variables = {
                value: search
            }

            autocompleteService(variables)
                .then((response) => {
                    let array = []
                    let arrayObjs = []
                    for (const obj of response.data.list) {
                        arrayObjs.push(obj)
                        array.push(obj.value)
                        if(obj.nombre === search) setId(obj.key)
                    }
                    setArray(array)
                    setArrayObj(arrayObjs)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }

    const setIdSearch = (value, array, setId) => {
        for (const obj of array) {
           if(obj.value === value) setId(obj.key) 
        }
    }

    React.useEffect(()=>{
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }
    }, [activeMessage])

    React.useEffect(()=>{
        if(startDate && endDate && startDate <= endDate)setCompareDates(true)
        else setCompareDates(false)
    })

    React.useEffect(()=>{
        if (advancedSetttings){
            //call commons persons but if there is a person value call the autocomplete persons
            /*getPersonsAutocomplete({searchTerm: ""});
            //call commons places
            getPlaces();*/
        }

    },[advancedSetttings]);

    React.useEffect(() => {
        autocompleteFunction(searchPlace, getSitesAutocomplete, setPlaces, setPlacesObj, setIdPlace)
    }, [searchPlace])

    React.useEffect(() => {
        setIdSearch(place, placesObj, setIdPlace)
    }, [place, placesObj])

    React.useEffect(() => {
        autocompleteFunction(searchPerson, getPersonsAutocomplete, setPeople, setPeopleObj, setIdPerson)
    }, [searchPerson])

    React.useEffect(() => {
        setIdSearch(person, peopleObj, setIdPerson)
    }, [person, peopleObj])

    React.useEffect(() => {
        autocompleteFunction(searchDivision, getDivisonsAutocomplete, setDivisions, setDivisionsObj, setIdDivision)
    }, [searchDivision])

    React.useEffect(() => {
        setIdSearch(division, divisionsObj, setIdDivision)
    }, [division, divisionsObj])

    React.useEffect(() => {
        autocompleteFunction(searchSector, getSectorsAutocomplete, setSectors, setSectorsObj, setIdSector)
    }, [searchSector])

    React.useEffect(() => {
        setIdSearch(sector, sectorsObj, setIdSector)
    }, [sector, sectorsObj])

    const activeAdvancedSettings = () =>{
        let oldValueAdvancedSettings = advancedSetttings;
        setAdvancedSetttings(!advancedSetttings);
    }

    return(
        <>   
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }

            <Container 
                className="containerView"
            > 
                <Grid container spacing={2} direction="column">

                    <DateField
                        xsProp={9}
                        label="Desde:"
                        dateSelected={startDate}
                        setDateSelected={setStartDate}         
                    />
                    <DateField
                        xsProp={9}
                        label="Hasta:"
                        dateSelected={endDate}
                        setDateSelected={setEndDate}                    
                    />
                    {role !== 1 /*&& !advancedSetttings && (
                    <LinkWithIcon
                        linkText="Configuración Avanzada"
                        icon={<TuneIcon 
                                color="primary"
                                className="iconStyle"/>}
                        callFunction={activeAdvancedSettings}
                    />
                    )}
                    {advancedSetttings && 
                        <ComboboxStyled
                            label="Persona:" 
                            comboboxOptions={people} 
                            value={person}
                            setValue={setPerson}
                            setSearch={setSearchPerson}
                            active={true}
                        /> 
                    }
                    {advancedSetttings && 
                        <ComboboxStyled
                            label="Ambiente:" 
                            comboboxOptions={places} 
                            value={place}
                            setValue={setPlace}
                            setSearch={setSearchPlace}
                            active={(sector === "" || sector === null) && (division === "" || division === null)}
                        /> 
                    }
                    {advancedSetttings &&
                        <ComboboxStyled
                            label="Edificio:" 
                            comboboxOptions={divisions} 
                            value={division}
                            setValue={setDivision}
                            setSearch={setSearchDivision}
                            active={(sector === "" || sector === null) && (place === "" || place === null)}
                        /> 
                    }
                    {/*advancedSetttings &&
                        <ComboboxStyled
                            label="Sector:" 
                            comboboxOptions={sectors} 
                            value={sector}
                            setValue={setSector}
                            setSearch={setSearchSector}
                            active={(division === ""  || division === null ) && (place === "" || place === null)}
                        /> 
                    */}   
                    {advancedSetttings && <LinkWithIcon
                        linkText="Configuración Simple"
                        icon={<TuneIcon 
                                color="primary"
                                className="iconStyle"/>}
                        callFunction={activeAdvancedSettings}
                    />}
                    <Grid item>
                        <div align='center'>
                            <ButtonWithIcon 
                                buttonText="Consultar" 
                                onClick={()=>goToResults()} 
                                iconButton={searchIcon}
                                leftIcon
                                fullWidth
                                disabled={!compareDates}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>  
        </>
    )

};

export default Attendance;
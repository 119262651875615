import * as React from 'react';
import { Container, Grid, ToggleButtonGroup, ToggleButton, Typography, Button, Pagination, TextField, InputAdornment, Box, Tabs, Tab } from '@mui/material';
import './AttendanceReport.css';
import "./AttendanceStudentInfo.css";
import ButtonWithIcon from '../../components/Button/ButtonWithIcon';
import { getActivityAttendance, getActivityAttendancePerson, registerStudentAttendance } from '../../services/attendance/AttendanceServices';
import { getStudentActivity } from '../../services/activities/ActivitiesServices';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ATTENDANCE_STUDENT_INFO, ATTENDANCE_STUDENT_SCAN } from '../../routes/routes';
import { INCIDENTS } from '../../routes/routes';
import AlertOptionsRow from '../../components/Alert/AlertOptionsRow';
import BasicAlerts from '../../components/Alert/Alert';
import SearchIcon from '@mui/icons-material/Search';
import AlertOptions from '../../components/Alert/AlertOptions';
import ActivityCard from '../../components/Card/Activity/ActivityCard';
import ProfileDefaultImage from "../../assets/images/profile-default.png";
import ScheduledActivityIcon from "../../assets/icons/ScheduledActivity.svg";
import NoScheduledActivityIcon from "../../assets/icons/NoScheduledActivity.svg";
import AnotherScheduledActivityIcon from "../../assets/icons/AnotherScheduledActivity.svg";
import arrowIcon from '../../assets/icons/arrowNext.svg';
import NoPersonsRegistered from '../../assets/images/0-registrados.svg';

const AttendanceReport  = () => {

    const { state } = useLocation();
    const [moreData, setMoreData] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [action, setAction] = React.useState(-1);
    const [totalPending, setTotalPending] = React.useState('-');
    const [totalRegistered, setTotalRegistered] = React.useState('-');
    const [totalResults, setTotalResults] = React.useState(0);
    const [resultsPerPage, setResultsPerPage] = React.useState(4);
    const [count, setCount] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [students, setStudents] = React.useState([]);
    const [sessionId, setSessionId] = React.useState('');
    const [scheduleId, setScheduleId] = React.useState(0);
    const [personId, setPersonId] = React.useState(0);
    const [titleAlert, setTitleAlert] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [objFilter, setObjFilter] = React.useState({});
    const [codeDNI, setCodeDNI] = React.useState('');
    const [isPersonFound, setIsPersonFound] = React.useState(false);
    const [personFound, setPersonFound] = React.useState('');
    const [personFoundFull, setPersonFoundFull] = React.useState({});
    const [activityStatusText, setActivityStatusText] = React.useState("");
    const [activityStatusColor, setActivityStatusColor] = React.useState("");
    const [iconButton, setIconButton] = React.useState("");
    const [showResults, setShowResults] = React.useState(false);
    const [isCalled, setIsCalled] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const userInf = useSelector(state => state.userReducer.user); 
    const navigate = useNavigate();

    React.useEffect(() => {
        setSessionId(state.sessionId);
        const variables = {
            user : userInf.email,
            firstResult: 1,
            maxResults: resultsPerPage,
            sessionId : state.sessionId,
            type: 0
        }
        setPage(1);
        searchAndUpdate(variables, 0);
    },[])

    const handleClickMoreItems = () => {
        const newResulstPerPage = resultsPerPage + 4
        setResultsPerPage(newResulstPerPage);

        const variables = {
            user : userInf.email,
            firstResult: 1,
            maxResults: newResulstPerPage,
            sessionId : state.sessionId,
            type: value
        }
        searchAndUpdate(variables, value);
    }

    const handleSearchByNameDocumentNumber = () => {
        setIsCalled(false)
        setTotalResults(0);
        setCount(0);
        setStudents([]);
        setPage(1);

        let variables = {
            user : userInf.email,
            firstResult: 1,
            maxResults: resultsPerPage,
            sessionId : sessionId,
            type: 3,
            nameDocumentNumber: codeDNI
        }
        searchAndUpdate(variables, 3);
    }

    const handleChange = (event, newValue) => {
        if(newValue === null){
            return;
        }
        setValue(newValue);
        setTotalResults(0);
        setResultsPerPage(4)
        setCount(0);
        setStudents([]);
        setPage(1);

        let variables = {
            user : userInf.email,
            firstResult: 1,
            maxResults: resultsPerPage,
            sessionId : sessionId,
            type: newValue
        }
        searchAndUpdate(variables, newValue);
    };  

    const updateCounts = (currentValue) => {
        let variables = {
            user : userInf.email,
            firstResult: 1,
            maxResults: 1,
            sessionId : state.sessionId,
            type: 2
        }
        getActivityAttendance(variables)
        .then(function (response) {
            setTotalPending(response.data.totalPending);
            setTotalRegistered(response.data.totalRegistered);
            setTotalResults(currentValue === 0 ? response.data.totalPending : response.data.totalRegistered);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const searchAndUpdate = (obj, currentValue) => {
        setIsLoading(true);
        getActivityAttendance(obj)
        .then((response) => {
            if(response.data.list.length === 0 && response.data.total > 0) {
                const lastPage = Math.ceil(response.data.total / resultsPerPage);
                obj.firstResult = lastPage;
                setPage(lastPage);
                searchAndUpdate(obj, currentValue);
            }
            else {
                if(response.data.total > response.data.list.length) {
                    setMoreData(true);
                }
                else {
                    setMoreData(false);
                }

                setStudents(response.data.list);
                setTotalRegistered(response.data.totalRegistered);
                setTotalPending(response.data.totalPending);
                setTotalResults(response.data.total);
                setIsPersonFound(false);
                setIsLoading(false);
            }
        });
    }

    React.useEffect(() => {
        if(totalResults > 0) {
            setCount(Math.ceil(totalResults / resultsPerPage));
            document.getElementById("search-textfield").focus();
        }
    }, [totalResults]);

    const handlePagination = (event, pageValue) => {
        setPage(pageValue);
        let variables = {
            user : userInf.email,
            firstResult: pageValue,
            maxResults: resultsPerPage,
            sessionId : sessionId,
            type: value,
            nameDocumentNumber: codeDNI.trim() !== '' ? codeDNI : null
        }
        searchAndUpdate(variables, value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const searchByFilter = (pageValue, obj) => {
        obj.firstResult = pageValue;
        obj.maxResults = resultsPerPage;
        obj.user = userInf.email;
        obj.sessionId = sessionId;
        searchAndUpdate(obj, value);
    }

    const goToResults = (currentValue) => {
        setValue(currentValue);
    }

    const handleTeacherAction = (scheduleId, type) => {
        const obj = {
            user: userInf.email,
            studentId: personId,
            sessionId: sessionId,
            scheduleId: scheduleId,
            type: type,
            registerType: 1
        }
        registerStudentAttendance(obj)
        .catch((error) => {
            if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                setAlertMessage(error.response.data.message);
                setAlertType('warning');
                setActiveMessage(true);
            }
        })
        .finally(() => {
            // Reload
            const variables = {
                user : userInf.email,
                firstResult: page,
                maxResults: resultsPerPage,
                sessionId : sessionId,
                type: value
            }
            setCodeDNI('');
            searchAndUpdate(variables, value);      
            setAlertOpen(false);
            document.getElementById("search-textfield").focus();
        });

    }

    const handleAttendance = (scheduleId, personId) => {
        setTitleAlert("¿Desea confirmar el registro de asistencias?")
        setAlertOpen(true);
        setScheduleId(scheduleId);
        setPersonId(personId);
        setAction(0);         
    }

    const handleAbsence = (scheduleId, personId) => {
        setTitleAlert("¿Desea confirmar el registro de falta?")
        setAlertOpen(true);
        setScheduleId(scheduleId);
        setPersonId(personId);
        setAction(1); 
    }

    const handleCancel = (scheduleId, personId) => {
        setTitleAlert("¿Desea confirmar la anulación?")
        setAlertOpen(true);
        setScheduleId(scheduleId);
        setPersonId(personId);
        setAction(2); 
    }

    const handleTakeAttendance = () => {
        navigate(ATTENDANCE_STUDENT_SCAN, {
            state: {
                sessionId: sessionId
            }
        });
    }

    const handleReturn = () => {
        setCodeDNI('');
        const obj = {
            user : userInf.email,
            firstResult: 1,
            maxResults: resultsPerPage,
            sessionId : sessionId,
            type: 0
        }
        searchAndUpdate(obj, 0);
        setIsCalled(true);
        document.getElementById("search-textfield").focus();
        setValue(0);
    }

    const handleSearchCodeDNI = (codeDNI) => {   
        setCodeDNI(codeDNI);
        if(codeDNI.length === 8 && /^\d+$/.test(codeDNI)){
            const obj = {
                sessionId: sessionId,
                value: codeDNI
            }
            getActivityAttendancePerson(obj)
            .then((response) => {
                if(response.data.personId !== null){
                    // Redirect
                    navigate(ATTENDANCE_STUDENT_INFO, {
                        state: {
                        documentNumber: codeDNI,
                        sessionId: sessionId,
                        origin: 2 // Scan
                        },
                    }
                    )
                    return;
                } else {
                    setStudents([])
                    setTotalResults(0);
                    setValue(0);
                }
            });
        }
        else {
            if(codeDNI.trim().length > 0) {
                setIsCalled(false);
                const obj = {
                    user : userInf.email,
                    firstResult: 1,
                    maxResults: resultsPerPage,
                    sessionId : sessionId,
                    type: 0,
                    nameDocumentNumber: codeDNI
                }
                searchAndUpdate(obj, 0);
            }
            else {
                setIsCalled(true);
                const obj = {
                    user : userInf.email,
                    firstResult: 1,
                    maxResults: resultsPerPage,
                    sessionId : sessionId,
                    type: 0
                }
                searchAndUpdate(obj, 0);
            }         
        }
    }

    React.useEffect(() => {
        if(personFound !== ''){
            getStudentActivity({ documentNumber: personFound.documentNumber })
            .then((response) => {
                setPersonFoundFull(response.data);
                const auxPerson = response.data;
                getActivityStatus(auxPerson);
                setStudents([]);
                setIsPersonFound(true);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [personFound])

    const getActivityStatus = (auxPerson) => {
        if (state.activityDescription === auxPerson.fullDescription) {
          setIconButton(ScheduledActivityIcon);
          setActivityStatusText("Tiene una actividad programada en este ambiente." + (personFound.isAbsent === null ? "" : (personFound.isAbsent === 0 ? " Se registró su asistencia." : " Se registró su falta.")));
          setActivityStatusColor("has-activity");
        } else if (auxPerson.fullDescription !== null) {
          setIconButton(AnotherScheduledActivityIcon);
          setActivityStatusText("Tiene una actividad programada en otro ambiente.");
          setActivityStatusColor("other-activity");
        } else {
          setIconButton(NoScheduledActivityIcon);
          setActivityStatusText(
            "No tiene una actividad programada en este ambiente ni a esta hora."
          );
          setActivityStatusColor("no-activity");
        }
    };

    const handleRegisterAttendance = () => {
        const obj = {
            user: userInf.email,
            studentId: personFoundFull.personId,
            sessionId: state.sessionId,
            scheduleId: personFoundFull.scheduleId,
            type: 0,
            registerType: 2
        };
    
        registerStudentAttendance(obj)
        .then((response) => {
            const variables = {
                user : userInf.email,
                firstResult: page,
                maxResults: resultsPerPage,
                sessionId : sessionId,
                type: value
            }
            setCodeDNI('');
            searchAndUpdate(variables, value);      
            setAlertOpen(false);
            document.getElementById("search-textfield").focus();
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => setAlertOpen(false));
      };

    React.useEffect(() => {
        if(codeDNI === ''){
            setShowResults(false);
            setSessionId(state.sessionId);
            const variables = {
                user : userInf.email,
                firstResult: 1,
                maxResults: resultsPerPage,
                sessionId : state.sessionId,
                type: value
            }
            setPage(1);
            searchAndUpdate(variables, value);
            setShowResults(true);
        }
    }, [codeDNI])

    React.useEffect(()=>{
        
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage]);
    
    return (
        totalPending !== '-' &&
       <div className="container">
            <AlertOptionsRow
                title={titleAlert}
                alertOpen={alertOpen}
                setAlertOpen={setAlertOpen}
                onConfirm={() => isPersonFound ? handleRegisterAttendance() : handleTeacherAction(scheduleId, action)}
            />
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }
            <Grid className='container-search'>
                {/* <Typography
                    className='text-search'
                >
                        {value === 0 ? "Buscar:" : "Buscar alumno:"}
                </Typography> */}
                <TextField
                    id='search-textfield'
                    label='Buscar por Código, D.N.I. o Nombres'
                    type='text'
                    size='medium'
                    value={codeDNI}
                    onChange={(e) => handleSearchCodeDNI(e.target.value)}
                    onKeyDown={(event) => { if(event.key === 'Enter') { handleSearchByNameDocumentNumber() } }}
                    onClick={() => {if(codeDNI === 'Ingrese el código o DNI') setCodeDNI('');}}
                    className={codeDNI === 'Ingrese el código o DNI' ? 'enter-label' : ''}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon onClick={() => handleSearchByNameDocumentNumber()}
                                icon="material-symbols:search-sharp"
                                color="#a5b0c1"
                            />
                          </InputAdornment>
                        ),
                      }}
                    
                >
                </TextField>
            </Grid>
            {
                isCalled === true && (
                    <Grid container xs={12}>
                        <Tabs value={value} variant='scrollable' scrollButtons
                        allowScrollButtonsMobile onChange={handleChange}>
                            <Tab value={0} label={<span className='tab-label'>Sin registrar</span>}/>
                            <Tab value={1} label={<span className='tab-label'>Asistentes</span>}/>
                            <Tab value={2} label={<span className='tab-label'>Faltantes</span>}/>
                            <Tab value={3} label={<span className='tab-label'>Todos</span>}/>
                        </Tabs>


                        {/* <ToggleButtonGroup
                            className='toggle-group'
                            orientation="horizontal"
                            value={value}
                            onChange={handleChange}
                            size="small"
                            exclusive
                            color='primary'
                        >
                            <ToggleButton className={value===0 ? "toggle-option barcode-optionOn" : "toggle-option barcode-optionOff"} value={0} aria-label="list">
                                Sin registrar
                            </ToggleButton>
                            <ToggleButton className={value===1 ? "toggle-option barcode-optionOn" : "toggle-option barcode-optionOff"} value={1} aria-label="list">
                                Asistentes
                            </ToggleButton>
                            <ToggleButton className={value===2 ? "toggle-option barcode-optionOn" : "toggle-option barcode-optionOff"} value={2} aria-label="list">
                                Faltantes
                            </ToggleButton>
                            <ToggleButton className={value===3 ? "toggle-option barcode-optionOn" : "toggle-option barcode-optionOff"} value={3} aria-label="list">
                                Todos
                            </ToggleButton>
                        </ToggleButtonGroup> */}
                    </Grid>
                )
            }
            <div className='container-students'>
            {showResults && isPersonFound ?
                <>
                    {personFound !== '' ?
                        <>
                        <div className="TI-div">
                        <Grid item xs={0} sm={1} md={2} lg={4}></Grid>
                        <Grid item xs={3} sm={6} md={6} lg={1} className="TI-image-data">
                        {/* <img
                        src={userInf.imageUrl} //TODO: get image of that particular student
                        referrerpolicy="no-referrer"
                        className="TI-image"
                    /> */}
                            <img
                                src={ProfileDefaultImage}
                                referrerPolicy="no-referrer"
                                className="TI-image"
                            />

                            {personFoundFull.documentNumber !== null && (
                                <Typography
                                component="h6"
                                variant="h6"
                                color="PRIMARY"
                                align="center"
                                >
                                DNI: {personFoundFull.documentNumber}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={9} sm={6} md={6} lg={3} className="TI-user-data">
                            <Typography component="h4" variant="h4" color="PRIMARY">
                                Código: {personFoundFull.pucpCode}
                            </Typography>
                            <hr />
                            <Typography component="h3" variant="h3" color="PRIMARY">
                                {personFoundFull.paternalSurname !== null ? (
                                <>
                                    {personFoundFull.paternalSurname}
                                    <br />
                                    {personFoundFull.maternalSurname}
                                    <br />
                                    {personFoundFull.firstName}
                                </>
                                ) : (
                                <>{personFoundFull.fullName.replace(",", "")}</>
                                )}
                            </Typography>
                            <hr />
                            <Typography component="h6" variant="h6" color="MARINE_BLUE">
                                {personFoundFull.unitName}
                            </Typography>
                        </Grid>
                    </div>

                    <Grid container>
                        <Grid item xs={0} sm={1} md={2} lg={4}></Grid>
                        <Grid item xs={0} sm={1} md={2} lg={4}>
                            <div className={`activity-status ${activityStatusColor}`}>
                            <img className="icon-status" src={iconButton} />
                            <Typography component="h5" variant="h5">
                                {activityStatusText}
                            </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    {activityStatusColor !== "no-activity" && (
                        <Grid align="center">
                        <ActivityCard
                            time={personFoundFull.shift}
                            title={personFoundFull.activityDescription}
                            site={personFoundFull.site}
                            type={personFoundFull.activityType}
                            scheduleId={personFoundFull.scheduleId}
                            isReport={true}
                        />
                        </Grid>
                    )}

                    {activityStatusColor === "has-activity" ? (
                        <Grid container align='center' className="group-buttons-report">
                            {/* <Grid xs={9} sm={6} md={6} lg={12} item></Grid>
                            <Grid xs={9} sm={6} md={6} lg={12} item display="flex" justifyContent="center" alignItems="center">
                                <ButtonWithIcon
                                onClick={() =>
                                    navigate(INCIDENTS, {
                                    state: {
                                        location: {
                                        id: personFoundFull.siteId,
                                        type: 0,
                                        comboName: personFoundFull.site,
                                        },
                                        navigation: -2,
                                    },
                                    })
                                }
                                color="warning"
                                buttonText="Reportar Incidencia"
                                />
                            </Grid>
                            <br /> */}
                            {personFound.isAbsent === null ?
                                <Grid xs={9} sm={6} md={6} lg={12} item display="flex" justifyContent="center" alignItems="center" marginTop="20px">
                                    <ButtonWithIcon
                                        buttonText="Registrar ingreso"
                                        color="access"
                                        onClick={() => {setTitleAlert("¿Desea confirmar el registro de asistencias?"); setAlertOpen(true)}}
                                    />
                                </Grid>
                                :
                                <Typography variant='h3'>Ya se ha registrado su asistencia o falta previamente</Typography>
                            }
                            {/* // {personFound.isAbsent !== null && 
                            //     <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
                            //         <ButtonWithIcon
                            //         buttonText={"Anular " + (personFound.isAbsent === 0 ? 'asistencia' : 'falta')}
                            //         color="access"
                            //         onClick={() => {handleCancel(stud.scheduleId, stud.personId)}}
                            //         />
                            //     </Box>
                            // } */}
                        </Grid>
                    ) : (
                    <>
                        <Grid container className="button-back">
                            <ButtonWithIcon
                            buttonText="Continuar"
                            color="access"
                            onClick={() =>
                                navigate(ATTENDANCE_STUDENT_SCAN, {
                                state: {
                                    activityDescription: state.activityDescription,
                                },
                                })
                            }
                            />
                        </Grid>
                        </>
                    )}
                        </>
                    :
                    <></>
                    } 
                </>      
                :
                <>
                {/* {showResults && isCalled === false && isPersonFound === false &&
                <Grid container xs={12} justifyContent='center' alignItems='center'>
                    <Typography my={2} variant='h3'>No se encontró a la persona</Typography>
                    <Grid container className='button-down'>
                        <ButtonWithIcon
                        buttonText='Volver'
                        color='access'
                        onClick={handleReturn}
                        cardButton/>
                    </Grid>
                </Grid>
                
                } */}
                </>
            }
            {!isLoading && (
                totalResults > 0 ? 
                <Grid xs={12} container>
                    <Typography mx='15px' mt='15px' variant='h4'>Resultados({totalResults}):</Typography>
                </Grid>
                :
                <Grid xs={12} pb='40px' container>
                    <Typography mx='15px' mt='15px' variant='h4'>{value === 0 ? 'No hay personas sin registrar asistencia o falta' : (value === 1 ? 'No hay asistentes registrados' : (value === 2 ? 'No hay faltantes registrados': 'No hay personas registradas para la actividad'))}</Typography>
                </Grid>
                )
            }
            
            <Grid container justifyContent='center' xs={12} px='15px'>
            {!isLoading ? 
                (totalResults > 0) ?
                students.map((stud, index) => {
                    return(
                        <Grid container xs={12} key={stud.scheduleId} my='5px' className='grid-student'>
                            <Grid item xs={11} >
                                <Grid container onClick={() => {
                                    navigate(ATTENDANCE_STUDENT_INFO, {
                                        state: {
                                        documentNumber: stud.documentNumber,
                                        sessionId: sessionId,
                                        origin: 1
                                        },
                                    }
                                    )}} xs={12}>
                                    <Typography
                                        className='text-doi'
                                    >
                                        DOI: {stud.documentNumber !== null && stud.documentNumber.trim() !== '' ? stud.documentNumber : 'No tiene'}
                                    </Typography>
                                </Grid>
                                <Grid container xs={12}>
                                    <Typography
                                        className='text-name'
                                    >
                                        {stud.fullName}
                                    </Typography>
                                </Grid>
                                <Grid container xs={12}>
                                    <Typography
                                        className='text-code'
                                    >
                                        Cod: {stud.pucpCode !== null && stud.pucpCode.trim() !== '' ? stud.pucpCode : 'No tiene'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <img src={arrowIcon} onClick={() => {
                                    navigate(ATTENDANCE_STUDENT_INFO, {
                                        state: {
                                        documentNumber: stud.documentNumber,
                                        sessionId: sessionId,
                                        origin: 1
                                        },
                                    }
                                    )}}/>
                            </Grid>
                            {/* {value === 0 && (
                                <Grid className='button-student'>
                                    <Button variant="outlined" className='button-assistance' fullWidth onClick={() => handleAttendance(stud.scheduleId, stud.personId)}>
                                        Asistencia
                                    </Button>
                                    <Button variant="outlined" className='button-fault' fullWidth onClick={() => handleAbsence(stud.scheduleId, stud.personId)}>
                                        Falta
                                    </Button>
                                </Grid> 
                            )}
                            {value === 1 && (
                                <>
                                    <Grid item xs={3.5} className='button-student'>
                                        <Typography
                                            className='text-student text-attendance'
                                        >
                                            {stud.registerType === 0 ? 'Cod. QR' : (stud.registerType === 1 ? 'Lista Asistencia' : 'Doc. Identidad')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} className='button-student-flex-end'>
                                        <Typography
                                            className={'text-student ' + (stud.isAbsent === 0 ? 'text-attendance' : 'text-fault')}
                                        >
                                            {stud.isAbsent === 0 ? 'Asistió' : 'Faltó'}
                                        </Typography>
                                        <Button variant="outlined" className='button-cancel' fullWidth onClick={() => handleCancel(stud.scheduleId, stud.personId)}>
                                            Anular
                                        </Button>
                                    </Grid>
                                </>
                            )} */}
                        </Grid>
                    )})
                :
                <img src={NoPersonsRegistered} alt='No se encontraron personas'/>
            :
                <></>
            }
            </Grid>
            </div>
            {/* {!isPersonFound && 
            <Grid container xs={12} justifyContent='center' className='pagination-div'>
                <Pagination
                    page={page}
                    onChange={handlePagination}
                    color='primary'
                    count={count}
                    size='small'
                    variant="outlined"
                    shape="rounded"
                />
            </Grid> 
            } */}
            {!isLoading && moreData && 
            <Grid container xs={12} px='15px' pb='35px'>
                <ButtonWithIcon variant='outlined' buttonText='Ver más' fullWidth rightIcon
                    onClick={() => handleClickMoreItems()}
                />
            </Grid>
            }
        </div>
    );

}

export default AttendanceReport;
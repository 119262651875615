import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const askForAttendance = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    firstResult: variables.firstResult,
    maxResults: variables.maxResults,
    user: variables.user,
    from: variables.startDate,  
    to: variables.endDate, //optional
    person: variables.idPerson, //optional
    site: variables.idPlace, //optional
    building: variables.idDivision, //optional
  }

  return axios.post(
    API_URL.url + "attendance/query"
  ,
    obj
  );
};

export const askForUnscheduledAttendance = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    firstResult: variables.firstResult,
    maxResults: variables.maxResults,
    user: variables.user,
    from: variables.startDate,  
    to: variables.endDate, //optional
    person: variables.idPerson, //optional
    site: variables.idPlace, //optional
    building: variables.idDivision, //optional
  }

  return axios.post(
    API_URL.url + "attendance/unscheduledQuery"
  ,
    obj
  );
};

export const registerAssistance = async (variables) => {
  if(variables.type === 1){ // Add location permission
    let obj = {
      keyCode: API_URL.keyCode,
      user: variables.user,
      onlyBuilding: variables.onlyBuilding,
      sessionId: variables.sessionId,//optional
      siteId: variables.siteId,
      buildingId: variables.buildingId,
      ip: "cadena", //optional
      district: "cadena", //optional
      province: "cadena", //optional
      region: "cadena", //optional
      type: variables.type,
    }
    
    var promise1 = new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(function(pos){
        const lat = pos.coords.latitude
        const lon = pos.coords.longitude
        resolve({lat,lon});
      }
      ,
      () => {
        console.log('Error inesperado en geolocalización');
        resolve({undefined, undefined})}
      , {timeout:2000})}); 

    let data = null;
    try{
      data = await promise1;
    } catch {
      console.log("Geolocalización desactivada")
    }

    if(data){
      obj.latitude = data.lat ? data.lat.toString() : null;
      obj.longitude = data.lon ? data.lon.toString() : null;
    }

    return axios.post(
      API_URL.url + "attendance/register",
      obj,
    );
    
  } else {
    const obj = {
      keyCode: API_URL.keyCode,
      user: variables.user,
      onlyBuilding: variables.onlyBuilding,
      sessionId: variables.sessionId,//optional
      siteId: variables.siteId,
      buildingId: variables.buildingId,
      ip: "", //optional
      latitude: "", //optional
      longitude: "", //optional
      district: "", //optional
      province: "", //optional
      region: "", //optional
      type: variables.type,
    }
  
    return axios.post(
      API_URL.url + "attendance/register",
      obj,
    );
  }
};

export const registerStudentAttendance = async (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    user: variables.user,
    studentId: variables.studentId,
    activityDescription: variables.activityDescription,
    sessionId: variables.sessionId,
    type: variables.type,
    registerType: variables.registerType
  }

  var promise1 = new Promise(function(resolve, reject) {
    navigator.geolocation.getCurrentPosition(function(pos){
      const lat = pos.coords.latitude
      const lon = pos.coords.longitude
      resolve({lat,lon});
    }
    ,
    () => {
      console.log('Error inesperado en geolocalización');
      resolve({undefined, undefined})}
    , {timeout:2000})}); 

  let data = null;
  try{
    data = await promise1;
  } catch {
    console.log("Geolocalización desactivada")
  }

  if(data){
    obj.latitude = data.lat ? data.lat.toString() : null;
    obj.longitude = data.lon ? data.lon.toString() : null;
  }

  return axios.post(
    API_URL.url + "attendance/teacher/register",
    obj,
  );
};

export const exportAttendancesToExcel = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    user: variables.user,
    from: variables.startDate,  
    //to: "1/1/3000", //optional
    //person: variables.idPerson, //optional
    //location: variables.idPlace, //optional
    //site: variables.idDivision, //optional
    //sector: variables.idSector, //optional
  }

  return axios.post( API_URL.url + "attendance/export", obj, { responseType: 'blob' } );
};

export const getLastDayCheckIn = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    username: variables.user,
    personId: variables.personId
  }
  return axios.post(
    API_URL.url + "attendance/lastDayCheckIn",
    obj,
  );
};

export const getActivityAttendance = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    firstResult: variables.firstResult,
    maxResults: variables.maxResults,
    user: variables.user,
    sessionId: variables.sessionId,
    type: variables.type !== undefined && variables.type !== null ? variables.type : 2,
    nameDocumentNumber: variables.nameDocumentNumber
  }
  return axios.post(
    API_URL.url + "attendance/activity/query",
    obj,
  )
}

export const getActivityAttendanceStats = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    user: variables.user,
    sessionId: variables.sessionId,
  }
  return axios.post(
    API_URL.url + "attendance/activity/stats",
    obj,
  )
}

export const getActivityAttendancePerson = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    sessionId: variables.sessionId,
    value: variables.value
  }
  return axios.post(
    API_URL.url + "attendance/activity/find",
    obj,
  )
}
import ActivityIcon from "../assets/icons/actividadesOB.svg";
import AttendanceIcon from "../assets/icons/asistenciaOB.svg";
import QRIcon from "../assets/icons/qrOB.svg";
import CapacityIcon from "../assets/icons/aforoOB.svg";
import IncidentIcon from "../assets/icons/incidenciasOB.svg";

export const appOptions = [
    {
      icon: (
        <img src={ActivityIcon} keyHelp="Actividades" className="imageOnBoarding"/>
      ),
      name: "Revisar tus actividades",
      text: "Visualiza las actividades que tienes programadas y registra tu ingreso de manera fácil.",
    },
    {
      icon: (
        <img src={AttendanceIcon} keyHelp="Asistencia" className="imageOnBoarding"/>
      ),
      name: "Visualizar tu asistencia",
      text: "Consulta el historial de los ambientes en los que registraste tu asistencia.",
    },
    {
      icon: <img src={QRIcon} keyHelp="Escanear QR" className="imageOnBoarding"/>,
      name: "Registrar tu ingreso por QR",
      text: "Registra el ingreso a los ambientes del campus PUCP escaneando el código QR.",
    },
    {
      icon: <img src={CapacityIcon} keyHelp="Aforo" className="imageOnBoarding"/>,
      name: "Revisar los aforos",
      text: "Revisa en tiempo real los aforos de los ambientes del campus.",
    },
    {
      icon: (
        <img src={IncidentIcon} keyHelp="Incidencia" className="imageOnBoarding"/>
      ),
      name: "Reportar alguna incidencia",
      text: "¿Identificaste algún incidente relacionado al retorno seguro al campus? Repórtalo.",
  
    },
];
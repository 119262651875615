import * as React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ProfileDefaultImage from "../../assets/images/profile-default.png";
import ScheduledActivityIcon from "../../assets/icons/ScheduledActivity.svg";
import NoScheduledActivityIcon from "../../assets/icons/NoScheduledActivity.svg";
import AnotherScheduledActivityIcon from "../../assets/icons/AnotherScheduledActivity.svg";
import * as ROUTES from "../../routes/routes";
import ButtonWithIcon from "../../components/Button/ButtonWithIcon";
import AlertOptions from "../../components/Alert/AlertOptions";
import ActivityCard from "../../components/Card/Activity/ActivityCard.js";
import LocationDetailsCard from "../../components/Card/LocationDetails/LocationDetailsCard";
import "./AttendanceStudentInfo.css";
import { getStudentActivity } from "../../services/activities/ActivitiesServices";
import { getActivityAttendancePerson, registerStudentAttendance } from "../../services/attendance/AttendanceServices";
import { formatNameCapitals } from "../../constants/commonFunctions";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleButton from "../../components/Button/CircleButton";
import GroupCircleButton from "../../components/Button/GroupCircleButton";
import ArrowBack from '../../assets/icons/backArrowIcon.svg';
import ErrorIcon from '../../assets/icons/error.svg';
import FastCheckIcon from '../../assets/icons/fact_check.svg';

const AttendanceStudentInfo = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [person, setPerson] = React.useState({});
  const [message, setMessage] = React.useState('');
  const [messageColor, setMessageColor] = React.useState('');
  const [activityStatusText, setActivityStatusText] = React.useState("");
  const [activityStatusColor, setActivityStatusColor] = React.useState("");
  const [iconButton, setIconButton] = React.useState("");
  const [type, setType] = React.useState('');
  const [isDone, setIsDone] = React.useState(0);
  const userInf = useSelector((state) => state.userReducer.user);

  const navigate = useNavigate();

  React.useEffect(() => {

    if (!(state && state.documentNumber && state.sessionId && state.origin)) {
      navigate("/");
      return;
    }

    setIsLoading(true);
    getStudentActivity({ documentNumber: state.documentNumber })
      .then((response) => {
        //setPerson(response.data);
        const auxPerson = response.data;
        getActivityAttendancePerson({sessionId: state.sessionId, value: auxPerson.documentNumber})
        .then((res) => {
          auxPerson.isAbsent = res.data.isAbsent;
          auxPerson.lastCheck =  res.data.lastCheck
          // if(res.data.isAbsent !== null) {
          //   setIsDone(1);
          // }
          setPerson(auxPerson);
          getActivityStatus(auxPerson);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
          return;
        });
      })
      .catch((error) => {
        console.log(error);
        navigate("/");
        return;
      });
  }, []);

  const getActivityStatus = (auxPerson) => {
    if(auxPerson.isAbsent === null) {
      if(state.origin === 1) {
        setMessage('Participante sin asignar asistencia o falta');
        setMessageColor('info');
      }
      else if(state.origin === 2) {
        if(auxPerson.scheduleId === state.scheduleId) {
          setMessage('Tiene una actividad programada en este ambiente');
          setMessageColor('success');
        }
        else if(auxPerson.scheduleId !== null) {
          setMessage('Tiene una actividad programada en otro ambiente');
          setMessageColor('warning');
        }
        else {
          setMessage('No tiene actividades programadas en este lugar');
          setMessageColor('info');
        }
      }
    }
    else {
      if(state.origin === 1) {
        if(auxPerson.isAbsent === 0) {
          setMessage('El participante ya fue registrado como asistente - ' + auxPerson.lastCheck);
          setMessageColor('error');
        }
        else {
          setMessage('El participante ya fue registrado como falto - ' + auxPerson.lastCheck);
          setMessageColor('error');
        }
      }
      else if(state.origin === 2) {
        if(auxPerson.isAbsent === 0) {
          setMessage('El participante ya fue registrado como asistente - ' + auxPerson.lastCheck);
          setMessageColor('error');
        }
        else {
          setMessage('El participante ya fue registrado como falto - ' + auxPerson.lastCheck);
          setMessageColor('error');
        }
      }
    }
    
    
    
    
    
    // if (state.activityDescription === auxPerson.fullDescription) {
    //   setIconButton(ScheduledActivityIcon);
    //   setActivityStatusText("Tiene una actividad programada en este ambiente.");
    //   setActivityStatusColor("has-activity");
    // } else if (auxPerson.fullDescription !== null) {
    //   setIconButton(AnotherScheduledActivityIcon);
    //   setActivityStatusText("Tiene una actividad programada en otro ambiente.");
    //   setActivityStatusColor("other-activity");
    // } else {
    //   setIconButton(NoScheduledActivityIcon);
    //   setActivityStatusText(
    //     "No tiene una actividad programada en este ambiente ni a esta hora."
    //   );
    //   setActivityStatusColor("no-activity");
    // }
  };

  const handleRegisterAttendance = (type) => {
    const obj = {
      user: userInf.email,
      sessionId: state.sessionId,
      scheduleId: person.scheduleId,
      studentId: person.personId,
      type: type,
      registerType: 2
    };

    registerStudentAttendance(obj)
      .then((response) => {
        // navigate(ROUTES.ATTENDANCE_STUDENT_SCAN, {
        //   state: {
        //     sessionId: state.sessionId,
        //     personId: state.personId,
        //     message: 'Se ha registrado la asistencia exitosamente.',
        //     alertType: 'success'
        //   },
        // });
        navigate(-1);
        return;

        // if(state.origin === 1) {
        //   if(type === 0) {
        //     setMessage('Registrada asistencia manualmente');
        //     setMessageColor('success');
        //   }
        //   else {
        //     setMessage('Participante registrado como faltante');
        //     setMessageColor('error');
        //   }
        // }
      })
      .catch((error) => {
        console.log(error);
        // if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
        //   navigate(ROUTES.ATTENDANCE_STUDENT_SCAN, {
        //     state: {
        //       activityDescription: state.activityDescription,
        //       message: error.response.data.message,
        //       alertType: 'warning'
        //     },
        //   });
        // }
      })
      .finally(() => setAlertOpen(false));
  };

  return (
    <>
      {isLoading === false && (
        <Grid container xs={12}>
        <Grid container xs={12} px='15px'>
          <Grid container xs={12} my='15px' className='grid-student'>
            <Grid xs={12} item>
              <Typography
                className='text-doi'
              >
                  DOI: {person.documentNumber !== null && person.documentNumber.trim() !== '' ? person.documentNumber : 'No tiene'}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography
                  className='text-name'
              >
                  {person.fullName}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography
                  className='text-code'
              >
                  Cod: {person.pucpCode !== null && person.pucpCode.trim() !== '' ? person.pucpCode : 'No tiene'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} mx='15px' px='10px' alignItems='center' className={"box-message " + (messageColor === 'info' ? 'box-message-info' : (messageColor === 'error' ? 'box-message-error': 'box-message-success')) }>
          <Typography component="h5" color={messageColor === 'info' ? 'rgba(0, 55, 119, 1)' : (messageColor === 'error' ? 'rgba(150, 33, 33, 1)': 'rgba(33, 89, 36, 1)')} variant="h5" className="box-text">
            {messageColor === 'info' ? <InfoIcon className="message-icon"/> : (messageColor === 'error' ? <CheckCircleIcon className="message-icon"/>: <CheckCircleIcon className="message-icon"/>)} {message}
          </Typography>
        </Grid>

        {person.sessionId !== null && 
          <Grid container justifyContent='center' xs={12} px='15px' my='10px'>
            <LocationDetailsCard
              activityLabel={person.activityDescription}
              typeActivity={person.activityType}
              siteName={formatNameCapitals(person.site ? formatNameCapitals(person.site) : '')}
              shift={person.shift}
            />
          </Grid>
        }
      
        <Grid container>
          <div className={`activity-status ${activityStatusColor}`}>
            <img className="icon-status" src={iconButton} />
            <Typography component="h5" variant="h5">
              {activityStatusText}
            </Typography>
          </div>
        </Grid>
          
        {/* {activityStatusColor === "has-activity" ? (
          <Grid container align='center' className="group-buttons">
            <Box display="flex" justifyContent="center" alignItems="center">
              <ButtonWithIcon
                onClick={() =>
                  navigate(ROUTES.INCIDENTS, {
                    state: {
                      location: {
                        id: person.siteId,
                        type: 0,
                        comboName: person.site,
                      },
                      navigation: -2,
                    },
                  })
                }
                color="warning"
                buttonText="Reportar Incidencia"
              />
            </Box>
            <br />
            <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
              <ButtonWithIcon
                buttonText="Asistencia"
                color="access"
                onClick={() => setAlertOpen(true)}
              />
            </Box>
          </Grid>
          ) : (
            <>
              <Grid container className="button-back">
                <ButtonWithIcon
                  buttonText="Continuar"
                  color="access"
                  onClick={() =>
                    navigate(ROUTES.ATTENDANCE_STUDENT_SCAN, {
                      state: {
                        activityDescription: state.activityDescription,
                      },
                    })
                  }
                />
              </Grid>
            </>
          )} */}

      <Grid container xs={12} justifyContent='center'>
        {isDone === 1 ?
          <CircleButton
            buttonText={'Regresar'}
            iconButton={ArrowBack}
            onClick={() => {navigate(-1)}}
            hx={"h3"}
          />
        :
          (person.isAbsent === null ?
            <GroupCircleButton numButt={3}
              buttonTexts={['Regresar', 'Registrar Falta', 'Registrar Asistencia']}
              iconButtons={[ArrowBack, ErrorIcon, FastCheckIcon]}
              onClicks={[() => navigate(-1),() => {setType(1); setAlertMessage('¿Desea registrar la falta?'); setAlertOpen(true)}, () => {setType(0); setAlertMessage('¿Desea registrar la asistencia?'); setAlertOpen(true)}]}
            />
          :
            <>
              <CircleButton
                buttonText={'Regresar'}
                iconButton={ArrowBack}
                onClick={() => {navigate(-1)}}
                hx={"h3"}
              />
              &nbsp;&nbsp;
              <CircleButton
                buttonText={'Anular ' + (person.isAbsent === 0 ? 'ingreso' : 'falta')}
                iconButton={ErrorIcon}
                onClick={() => {setType(2); setAlertMessage('¿Desea anular ' + (person.isAbsent === 0 ? 'el ingreso ?' : 'la falta ?')); setAlertOpen(true)}}
                hx={"h3"}
              />
            </>
          )
        }
      </Grid>
      <AlertOptions
        title={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        onConfirm={() => {handleRegisterAttendance(type)}}
      />
    </Grid>
      )}
    </>
  );
};

export default AttendanceStudentInfo;

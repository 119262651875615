import * as React from 'react';
import { 
    Container,
    Grid,
    Tabs,
    Tab,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    ButtonGroup,
    Button
} from '@mui/material';
import AssistanceCard from '../../components/Card/Assistance/AssistanceCard';
import LinkWithIcon from '../../components/Link/LinkWithIcon';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import labImage from '../../assets/images/laboratorio.png';
import learnImage from '../../assets/images/formacion.png';
import servicesImage from '../../assets/images/servicios.png';
import { useSelector } from 'react-redux';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon.js';
import { askForAttendance, askForUnscheduledAttendance, exportAttendancesToExcel } from '../../services/attendance/AttendanceServices';
import BasicAlerts from '../../components/Alert/Alert';
import seeMoreIcon from '../../assets/icons/see-more.svg';
import './AttendanceResults.css';


const AttendanceResults  = ({route}) => {
    const [totalResults, setTotalResults] = React.useState(3);
    const [totalScheduledResults, setTotalScheduledResults] = React.useState('-');
    const [totalUnscheduledResults, setTotalUnscheduledResults] = React.useState('-');
    const [results, setResults] = React.useState([]);
    const userInf = useSelector(state => state.userReducer.user);    
    const [firstResult, setFirstResult] = React.useState(1);
    const [lastResult, setLastResult] = React.useState(3);
    const [moreInf, setMoreInf] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);

    const { state } = useLocation();

    const handleChange = (event, newValue) => {
        if(newValue === null){
            return;
        }
        setFirstResult(1);
        setLastResult(3);
        goToResults(newValue);
    };

    const goToResults = (currentValue) => {
        setIsLoading(true);
        const variables = {
            firstResult: state.variables.firstResult,
            maxResults: state.variables.maxResults,
            user: state.variables.user,
            startDate: state.variables.startDate,  
            endDate: state.variables.endDate, 
        }
        if(currentValue === 0){
            askForAttendance(variables)
                .then(function (response) {
                    if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                        setResults(response.data.list);
                        setTotalResults(response.data.total);
                        setLastResult(3);
                        const activeButton = response.data.total > 3;
                        setMoreInf(activeButton);
                        setIsLoading(false);
                        setTotalScheduledResults(response.data.total);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setAlertType("error");
                    setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                    setActiveMessage(true);
                });
        } else {
            askForUnscheduledAttendance(state.variables)
            .then(function (responseNoSch) {
                if(responseNoSch.data.resultCode === 0 || responseNoSch.data.resultCode === 1003) {
                    setResults(responseNoSch.data.list);
                    setTotalResults(responseNoSch.data.total);
                    setLastResult(3);
                    const activeButtonNS = responseNoSch.data.total > 3;
                    setMoreInf(activeButtonNS);
                    setIsLoading(false);
                    setTotalUnscheduledResults(responseNoSch.data.total);
                }
            })
            .catch(function (error) {
                console.log(error);
                setAlertType("error");
                setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                setActiveMessage(true);
            }); 
        }
        setValue(currentValue);
    }

    React.useEffect(()=>{
        
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage]);

    React.useEffect(() => {
        const variables = {
            firstResult: 1,
            maxResults: 100000000,
            user: userInf.email,
            startDate: state.variables.startDate,  
            endDate: state.variables.endDate, 
        }

        askForAttendance(variables) 
        .then(function (response) {
            setTotalScheduledResults(response.data.total);
        })
        .catch(function (error) {
            console.log(error);
            setAlertType("error");
            setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
            setActiveMessage(true);
        });

        askForUnscheduledAttendance(variables) 
        .then(function (response) {
            setTotalUnscheduledResults(response.data.total);
        })
        .catch(function (error) {
            console.log(error);
            setAlertType("error");
            setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
            setActiveMessage(true);
        });

        goToResults(0);
    }, []);

    const getAttendanceResults = (newLastResult) => {
        
        const variables = {
          firstResult: firstResult,
          maxResults: newLastResult,
          user: userInf.email,
          startDate: state.variables.startDate,  
          endDate: state.variables.endDate, 
        }
        if(value===0){
            askForAttendance(variables)
                .then(function (response) {
                    if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                        setResults(response.data.list)
                        const activeButton = response.data.total > newLastResult;
                        setMoreInf(activeButton);
                        setTotalScheduledResults(response.data.total);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setAlertType("error");
                    setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                    setActiveMessage(true);
                });
        } else {
            askForUnscheduledAttendance(variables)
            .then(function (response) {
                if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                    setResults(response.data.list)
                    const activeButton = response.data.total > newLastResult;
                    setMoreInf(activeButton);
                    setTotalUnscheduledResults(response.data.total);
                }
            })
            .catch(function (error) {
                console.log(error);
                setAlertType("error");
                setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                setActiveMessage(true);
            });
        }
        
    }

    const selectImage = (activityType) => {
        let imageCard;
        switch(activityType){
            case 'Investigación':
                imageCard = labImage;
                break;
            case 'Servicios':
                imageCard = learnImage;
                break; 
            case 'Formación':
                imageCard = servicesImage;
                break;
            default:
                imageCard = labImage;
                break;                           
        }
        return imageCard;
    }

    const moreResults = () =>{
        const newLastResult=lastResult+3;
        setLastResult(newLastResult);
        getAttendanceResults(newLastResult);
        
    }   

    

    return(
        <>  
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }
            <Container 
                className="containerCardsView"
            > 
                <div align='center'>
                    <Grid container direction="column" className='data-container'> 

                        <ToggleButtonGroup
                            className='toggle-group'
                            orientation="horizontal"
                            value={value}
                            onChange={handleChange}
                            size="small"
                            exclusive
                            color='primary'
                        >
                            <ToggleButton className={value===0 ? "toggle-option barcode-optionOn" : "toggle-option barcode-optionOff"} value={0} aria-label="list">
                                Programados ({totalScheduledResults})
                            </ToggleButton>
                            <ToggleButton className={value===1 ? "toggle-option barcode-optionOn" : "toggle-option barcode-optionOff"} value={1} aria-label="list">
                                No Programados ({totalUnscheduledResults})
                            </ToggleButton>
                            
                        </ToggleButtonGroup>
                        
                        {isLoading === false && (
                        <>
                            {userInf && userInf.role && userInf.role.roleId !== 3 ? (
                            <Grid item xs={4}  md={6}>
                                <LinkWithIcon callFunction={
                                    () => {
                                        const variables = {
                                            user: userInf.email,
                                            startDate: state.variables.startDate,
                                        }
                                        exportAttendancesToExcel(variables).then( (response) =>{
                                            var data = new Blob([response.data], {type: 'application/x-www-form-urlencoded; charset=UTF-8'});
                                            var xlsxUrl = window.URL.createObjectURL(data);
                                            const tempLink = document.createElement('a');
                                            tempLink.href = xlsxUrl;
                                            tempLink.setAttribute('download', 'Asistencias.xlsx');
                                            tempLink.click();
                                        }).catch((error) => {
                                            console.log(error);
                                            setAlertType("error");
                                            setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                                            setActiveMessage(true);
                                        })
                                    }
                                    }
                                    linkText="Exportar" 
                                    icon={<FileDownloadIcon
                                        className="iconStyle"/>}
                                />
                            </Grid>  ):
                            <></>
                            } 
                        
                        {results && results.map((result, index)=>{
                            let imageCard = selectImage(result.tipoActividad);
                            return(
                                <div className='cardContentAttendance'>
                                    <AssistanceCard
                                        key={index}
                                        image={imageCard}
                                        title={result.activityDescription ? result.activityDescription : result.site !== null ? (result.siPucpLabel !== null ? result.siPucpLabel : result.site) : (result.shortName !== null ? result.shortName : result.building)}
                                        place={value===0 ? (result.siPucpLabel ? result.siPucpLabel : result.site) : null}
                                        type={result.activityType ? result.activityType : null}
                                        checkIn={result.attendanceList ? result.attendanceList[0].date : ''}
                                        checkOut={result.attendanceList ? result.attendanceList[1].date : ''}
                                    />
                                </div>
                            )
                        })}
                        {value===0 && results && results.length===0 &&
                            <Typography 
                                variant="h4" 
                                component="h4" 
                                color="primary"
                                align='justify'
                                style={{marginTop: "20px"}}
                                gutterBottom 
                            >
                                No se ha registrado su asistencia en sus actividades programadas en los días buscados.
                            </Typography> 
                        }
                        {value===1 && results && results.length===0 &&
                            <Typography 
                                variant="h4" 
                                component="h4" 
                                color="primary"
                                align='justify'
                                style={{marginTop: "20px"}}
                                gutterBottom 
                            >
                                No se ha registrado su asistencia en ambientes del campus.
                            </Typography> 
                        }
                        {value === 0 && results && results.length > 0 && moreInf && 
                            <Box className="containerButtonIcon">
                                <ButtonWithIcon
                                    buttonText="Ver más" 
                                    iconButton={seeMoreIcon} 
                                    onClick={() => moreResults()}
                                    rightIcon
                                />
                            </Box>}
                        {value === 1 && results && results.length > 0 && moreInf && 
                            <Box className="containerButtonIcon">
                                <ButtonWithIcon
                                    buttonText="Ver más" 
                                    iconButton={seeMoreIcon} 
                                    onClick={() => moreResults()}
                                    rightIcon
                                />
                            </Box>}
                        </>
                        )}
                    </Grid>
                </div>
                </Container>  
            </>
    )

};

export default AttendanceResults;
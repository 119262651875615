import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import './AssistanceCard.css';
import {MARINE_BLUE, ORANGE, RED} from '../../../constants/colors';
import noImage from '../../../assets/images/no-image-127.png';
import { formatNameCapitals } from '../../../constants/commonFunctions';

const AssistanceCard=(props)=> {
    const {image, title, place, type, checkIn, checkOut} = props;
    const titleRef = React.useRef('');
    const placeRef = React.useRef('');
    const [hoverTitleRef, setHoverTitleRef] = React.useState(false);
    const [hoverPlaceRef, setHoverPlaceRef] = React.useState(false);

    const compareSizeTitle = () => {
        const compareTitle =
        titleRef.current.scrollWidth >
        titleRef.current.clientWidth;
        setHoverTitleRef(compareTitle);
    };
    const compareSizePlace = () => {
        const comparePlace =
        placeRef.current.scrollWidth >
        placeRef.current.clientWidth;
        setHoverPlaceRef(comparePlace);
    };
  
    // compare once and add resize listener on "componentDidMount"
      React.useEffect(() => {
        compareSizeTitle();
        compareSizePlace();
        window.addEventListener('resizeTitle', compareSizeTitle);
        window.addEventListener('resizePlace', compareSizePlace);
      }, []);
  
      // remove resize listener again on "componentWillUnmount"
      React.useEffect(
        () => () => {
          window.removeEventListener('resizeTitle', compareSizeTitle);
          window.removeEventListener('resizePlace', compareSizePlace);
        },
        []
      );

    return (
        <Card className="assistanceCardPI">
            <CardMedia
                className="assistanceCardMediaPI"
                component="img"
                image={noImage}
                alt="activity"
            />
            <CardContent className='assistanceCardContentPI'>
                <Grid item className='text-title-container max-width-full'>
                    <Typography 
                        ref={titleRef}
                        align='left'
                        component="h5"                     
                        variant="h5"
                        color="primary"
                        className="text-overflow-ass"
                        style={{paddingBottom: "5px"}}
                    >
                        {type && type.toUpperCase() === "EVENTO" ? formatNameCapitals(title.split(' - ')[1]) : formatNameCapitals(title)}
                    </Typography>
                    {hoverTitleRef? 
                    (
                        <span className="tooltiptext-ass">{type && type.toUpperCase() === "EVENTO" ? formatNameCapitals(title.split(' - ')[1]) : formatNameCapitals(title)}</span>
                    )
                    :
                    (
                        <></>
                    )}
                </Grid>
                <Grid container direction="row" className='text-container'>
                    <Typography                         
                        ref={placeRef}
                        align='left'
                        variant="h6" 
                        color={MARINE_BLUE}
                        component="h6"
                        className="text-overflow-ass"
                        style={{paddingBottom: "5px"}}
                    >
                        {formatNameCapitals(place)}
                    </Typography>
                    {hoverPlaceRef? 
                    (
                        <span className="tooltiptext-ass">{formatNameCapitals(place)}</span>
                    )
                    :
                    (
                        <></>
                    )}
                </Grid>
                <Typography 
                    align='left'
                    variant="h6" 
                    color={RED} 
                    component="h6"
                    style={{paddingBottom: "5px"}}
                >
                    {formatNameCapitals(type)}
                </Typography>
                <div className="assistanceCardColumnPI">
                    <Typography 
                        variant="h6" 
                        color={ORANGE}
                        component="h6"
                        style={{paddingBottom: "5px"}}
                    >
                        Ingreso: {checkIn ? checkIn : "No figura"}
                    </Typography>
                </div>
                <div className="assistanceCardColumnPI">
                    <Typography 
                        variant="h6" 
                        color={ORANGE}
                        component="h6"
                    >
                        Salida:&nbsp;&nbsp; {checkOut ? checkOut : "No figura"}
                    </Typography>
                </div>                    
            </CardContent>    
        </Card>
    );
}
export default AssistanceCard;
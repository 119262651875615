import { Container, Grid, Typography,Divider,Box, Button } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDateSpanish, formatJustTimeNoSeconds, formatNameCapitals, formatSeconds, secondsOfTime } from '../../constants/commonFunctions';
import * as ROUTES from "../../routes/routes";
import { getActivityAttendanceStats, getLastDayCheckIn, registerAssistance } from '../../services/attendance/AttendanceServices';
import accessIcon from '../../assets/icons/check-in.svg';
import exitIcon from '../../assets/icons/check-out.svg';
import InfoIcon from '@mui/icons-material/Info';
import qrIcon from '../../assets/icons/qrbig.svg';
import footIcon from '../../assets/icons/footprint.svg';
import updateIcon from '../../assets/icons/update.svg';
import ArrowBackIcon from '../../assets/icons/back-arrow.svg';
import NoScheduledActivityIcon from "../../assets/icons/NoScheduledActivity.svg";
import AnotherScheduledActivityIcon from "../../assets/icons/AnotherScheduledActivity.svg";
import registerAttendanceIcon from '../../assets/icons/registerAttendance.svg';
import seeAtendanceIcon from '../../assets/icons/seeAtendance.svg';
import reportIncidentIcon from '../../assets/icons/reportIncident.svg';
import registerCheckOutIcon from '../../assets/icons/registerCheckOut.svg';
import announcementsAndNewsIcon from '../../assets/icons/announcementsAndNews.svg';
import CircleButton from '../../components/Button/CircleButton'
import ButtonWithIcon from '../../components/Button/ButtonWithIcon';
import AlertOptions from '../../components/Alert/AlertOptions';
import BasicAlerts from '../../components/Alert/Alert';
import { getActivities, getStudentActivity } from '../../services/activities/ActivitiesServices';
import { getLocationInfoByQrKey } from '../../services/commons/commonsServices';
import { PRIMARY, RED } from '../../constants/colors';
import { getActivityAttendance } from '../../services/attendance/AttendanceServices';
import './LocationDetails.css';
import { LocalizationProvider } from '@mui/lab';
import checkIcon from '../../assets/icons/check.svg';
import LocationDetailsCard from '../../components/Card/LocationDetails/LocationDetailsCard';
import { getActivitiesBySiteAndPerson } from '../../services/activities/ActivitiesServices';
import GroupCircleButton from '../../components/Button/GroupCircleButton';


const LocationDetails  = (props) => {

    let { key } = useParams();
    let { state } = useLocation();
    const { checkIn, setCheckIn } = props;
    const navigate = useNavigate();
    const [isSite, setIsSite] = useState(true);
    const [isScheduled, setIsScheduled] = useState(false);
    const [siteDate, setSiteDate] = useState(null);
    const [buildingDate, setBuildingDate] = useState(null);
    const [siteName, setSiteName] = useState('');
    const [siPucpLabel, setSiPucpLabel] = useState(null);
    const [shortName, setShortName] = useState(null);
    const [levelName, setLevelName] = useState('');
    const [buildingName, setBuildingName] = useState('');
    const [lastCheckIn, setLastCheckIn] = useState(new Date());
    const [canRegister, setCanRegister] = useState(false);
    const [isTimerVisible, setIsTimerVisible] = useState(false);
    const [registerAction, setRegisterAction] = useState('');
    const [registerColor, setRegisterColor] = useState('access');
    const [now, setNow] = useState(new Date());
    const userInf = useSelector(state => state.userReducer.user); 
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false); 
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [siteId, setSiteId] = React.useState(null);
    const [buildingId, setBuildingId] = React.useState(null);
    const [sessionId, setSessionId] = React.useState(null);
    const [activityId, setActivityId] = React.useState(null)
    const [activityLabel, setActivityLabel] = React.useState(null);
    const [activityType, setActivityType] = React.useState('');
    const [registerClickTime, setRegisterClickTime] = React.useState(new Date());
    const [stateForNoScheduled,setStateForNoScheduled] = React.useState(null);
    const [alertOpenUnscheduled, setAlertOpenUnscheduled] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isTeacher, setIsTeacher] = React.useState(null);
    const [totalStudentsPending, setTotalStudentsPending] = React.useState(0);
    const [totalStudentsRegistered, setTotalStudentsRegistered] = React.useState(0);
    const [totalStudentsSchedule, setTotalStudentsSchedule] = React.useState(0);
    const [shift, setShift] = React.useState('');
    const [fullDescription, setFullDescription] = React.useState(null);
    const [role, setRole] = React.useState(null);
    const [unscheduledType, setUnscheduledType] = React.useState(null);
    const [otherActivityObj, setOtherActivityObj] = React.useState(null);

    const updateContent = (newCheckIn) => {
        if(newCheckIn.siteId !== null) {
            setIsSite(true);
            setSiteId(newCheckIn.siteId);
            setBuildingId(newCheckIn.buildingId);
            setSiteName(newCheckIn.siteName);
            setSiPucpLabel(newCheckIn.siPucpLabel);
            setShortName(newCheckIn.shortName);
            setLevelName(newCheckIn.levelName);
            setBuildingName(newCheckIn.buildingName);
            setBuildingDate(newCheckIn.buildingDate);
        }
        // Or a building
        else if(newCheckIn.buildingId !== null) {
            setIsSite(false);
            setBuildingId(newCheckIn.buildingId);
            setBuildingName(newCheckIn.buildingName);
            setBuildingDate(newCheckIn.buildingDate);
            setShortName(newCheckIn.shortName);
        }

        if(newCheckIn.role !== null) {
            if(newCheckIn.role > 0){
                const variables = {
                    user : userInf.email,
                    sessionId : newCheckIn.sessionId
                }
                //console.log(variables);
                getActivityAttendanceStats(variables)
                .then(function (response) {
                    //console.log(response);
                    setIsTeacher(true);
                    setTotalStudentsPending(response.data.totalPending);
                    setTotalStudentsRegistered(response.data.totalAttend);
                    setTotalStudentsSchedule(response.data.totalRegistered + response.data.totalPending);
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                setIsTeacher(false);
            }
        } 

        setIsTimerVisible(true);

        // Building Date or Site Date ?
        setLastCheckIn(new Date(newCheckIn.siteDate !== null ? newCheckIn.siteDate : newCheckIn.buildingDate));

        // If checkIn is related to a scheduled activity
        if(newCheckIn.sessionId && newCheckIn.sessionId !== null) {
            setSessionId(newCheckIn.sessionId);
            setIsScheduled(true);
        }
        else {
            setIsScheduled(false);
        }

        // Move logic
        setRegisterAction('Registrar salida');
        setRegisterColor('exit');

        // Init timer
        const interval = setInterval( () => setNow(new Date()), 1000 );

        setIsLoading(false);
    }

    const handleCheckInError = () => {
        getLastDayCheckIn({user: userInf.email, personId: userInf.role.personId})
        .then((response) => {
            setCheckIn(response.data);
            navigate(ROUTES.ACTIVITIES,
                { state: {alertType: 'warning', alertMessage: 'Tiene un ingreso registrado previamente'} });

        })
        .catch((error) => console.log(error))
    }

    const updateAttendanceCounters = () => {
        const variables = {
            user : userInf.email,
            sessionId: sessionId
        }
        getActivityAttendanceStats(variables)
        .then(function (response) {
            setTotalStudentsPending(response.data.totalPending);
            setTotalStudentsRegistered(response.data.totalAttend);
            setTotalStudentsSchedule(response.data.totalRegistered + response.data.totalPending);
            
        })
        .catch(function (error) {
            console.log(error.response.data);
        });
    }

    const redirectAfterCheckOut = (type, message, checkInToSet) => {

        let date = new Date()
                    
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hour = date.getHours();
        let minute = date.getMinutes();
        let seconds = date.getSeconds();
                    
        let stringDate = `${day < 10 ? '0'+day : day}/${month < 10 ? '0'+month : month}/${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${seconds < 10 ? '0' + seconds : seconds}`
                        
        const variables = {
            firstResult: 1,
            maxResults: 1000,
            user: userInf.email,
            personId: userInf.role.personId,
            date: stringDate
        };

        getActivities(variables).then(function (response) {
            // Set the new Check In
            setCheckIn(checkInToSet);

            if(response.data.resultCode === 1003) {
                navigate(ROUTES.QR_PRE,
                    { state: {alertType: type, alertMessage: message} });
            }
            else {
                navigate(ROUTES.ACTIVITIES,
                    { state: {alertType: type, alertMessage: message} });
            }
        })
        .catch(function (error) {
            console.log(error);
            setCheckIn(checkInToSet);
            navigate(ROUTES.QR_PRE);
        });
    }

    const handleCheckOutError = () => {
        getLastDayCheckIn({user: userInf.email, personId: userInf.role.personId})
        .then((response) => {
            if(response.data.resultCode === 1003) {
                // No check in
                redirectAfterCheckOut('warning', 'Ya se registró su salida previamente', null);
            }
            else {
                // New check in
                setCheckIn(response.data);
                navigate(ROUTES.ACTIVITIES,
                    { state: {alertType: 'warning', alertMessage: 'Ya se registró su salida y un nuevo ingreso previamente'} });
            }

        })
        .catch((error) => console.log(error))
    }

    const confirmRegisterCheckOut = () => {
        setRegisterClickTime(new Date());

        // RELEASE 2 ONLY. REMOVE FOR RELEASE 3
        // If its a site, register the site check-out first and then the building check-out
        if(siteId && siteId !== null) {
            const variables = {
                user: userInf.email,
                sessionId: sessionId,
                siteId: siteId,
                type: 2,
            };

            registerAssistance(variables)
            .then(function (response) {
                // Site check-out registered, now register building
                const buildingVariables = {
                    user: userInf.email,
                    sessionId: sessionId,
                    buildingId: buildingId,
                    onlyBuilding: 0,
                    type: 2
                }
                registerAssistance(buildingVariables)
                    .then(function (response) {
                        let message = "Su salida ha sido registrada";
                        setAlertType("success");
                        setAlertMessage(message);
                        setActiveMessage(true);
                        setAlertOpen(false);
                        
                        redirectAfterCheckOut('success', message, null);
                    })
                    .catch(function (error) {
                        if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                            handleCheckOutError();
                        }
                        else{
                            setAlertType("error");
                            setAlertMessage("Su salida no ha sido registrada. Inténtelo de nuevo");
                            setActiveMessage(true);
                        }
                    });
            })
            .catch(function (error) {
                if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                    handleCheckOutError();
                }
                else{
                    setAlertType("error");
                    setAlertMessage("Su salida no ha sido registrada. Inténtelo de nuevo");
                    setActiveMessage(true);
                }
            });
        }
        else if(buildingId && buildingId !== null) {

            const buildingVariables = {
                user: userInf.email,
                sessionId: sessionId,
                buildingId: buildingId,
                type: 2,
                onlyBuilding: 1
            }

            registerAssistance(buildingVariables)
            .then(function (response) {
                let message = "Su salida ha sido registrada";
                setAlertType("success");
                setAlertMessage(message);
                setActiveMessage(true);
                setAlertOpen(false);
                
                redirectAfterCheckOut('success', message, null);
            })
            .catch(function (error) {
                if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                    handleCheckOutError();
                }
                else{
                    setAlertType("error");
                    setAlertMessage("Su salida no ha sido registrada. Inténtelo de nuevo");
                    setActiveMessage(true);
                }
            });
        }
    }


    const confirmRegisterCheckIn = ()=>{
        const variables = {
            user: userInf.email,
            siteId: stateForNoScheduled.site ? stateForNoScheduled.site.siteId : null,
            buildingId: stateForNoScheduled.building ? stateForNoScheduled.building.buildingId : null,
            sessionId: sessionId,
            type: 1,
        };

        registerAssistance(variables)
        .then( (response)=> {
            let message = "Su ingreso ha sido registrado";
            setAlertType("success");
            setAlertMessage(message);
            setActiveMessage(true);
            setAlertOpen(false);

            const newCheckIn = {
                activityDescription: fullDescription,
                activityId: activityId,
                sessionId: sessionId,
                activityType: activityType,
                siteId: stateForNoScheduled.site? stateForNoScheduled.site.siteId: null,
                buildingId: stateForNoScheduled.site?stateForNoScheduled.site.buildingId: stateForNoScheduled.building.buildingId,
                siteDate: siteId !== null ? new Date() : null,
                buildingDate: new Date(),
                siteName: stateForNoScheduled.site ? stateForNoScheduled.site.siteName : null,
                shortName : stateForNoScheduled.site? stateForNoScheduled.site.shortName: stateForNoScheduled.building.shortName,
                siPucpLabel : stateForNoScheduled.site?.siPucpLabel,
                levelName: stateForNoScheduled.site?.levelName,
                buildingName: stateForNoScheduled.site? stateForNoScheduled.site.buildingName: stateForNoScheduled.building.buildingName,
                role: role,
                fullDescription: fullDescription,
                shift: shift
            };
            setAlertOpenUnscheduled(false);
            setLastCheckIn(new Date());
            setNow(new Date());
            setCheckIn(newCheckIn);
            setSiteDate(new Date());
            if(siteDate === null) {
                setBuildingDate(new Date());
            }
            setIsTimerVisible(true);
            setCanRegister(false);
        })
        .catch(function (error) {
            if(error && error.response && error.response.data && error.response.data.resultCode === 1004) {
                handleCheckInError();
            }
            else {
                setAlertType("error");
                setAlertMessage("Su asistencia no ha sido registrada. Inténtelo de nuevo");
                setActiveMessage(true);
                setCanRegister(true);
            }                
        });
    }

    const registerCheckOut = () => {        
        setAlertOpen(true);
    }
    
    const registerCheckIn = () => {        
        setAlertOpenUnscheduled(true);
    } 

    const showInfo = () => {

    }

    const successCallback = (position) => {
        //console.log(position);
    };
      
    const errorCallback = (error) => {
        console.log(error);
    };

    React.useEffect(() => {
        if (checkIn === null && (key === undefined || key === null)){
            navigate(ROUTES.QR_PRE);
        }
        else if (checkIn !== null){
            // User comes from an active check-in
            // Is it a site ?
            if(checkIn.activityDescription !== null) setActivityLabel(checkIn.activityDescription)
            if(checkIn.activityType !== null) setActivityType(checkIn.activityType)
            if(checkIn.shift !== null) setShift(checkIn.shift)
            if(checkIn.siteId !== null) {
                setIsSite(true);
                setSiteId(checkIn.siteId);
                setBuildingId(checkIn.buildingId);
                setSiteName(checkIn.siteName);
                setSiPucpLabel(checkIn.siPucpLabel);
                setShortName(checkIn.shortName);
                setLevelName(checkIn.levelName);
                setBuildingName(checkIn.buildingName);
                setBuildingDate(checkIn.buildingDate);
            }
            // Or a building
            else if(checkIn.buildingId !== null) {
                setIsSite(false);
                setBuildingId(checkIn.buildingId);
                setBuildingName(checkIn.buildingName);
                setBuildingDate(checkIn.buildingDate);
                setShortName(checkIn.shortName);
            }

            if(checkIn.role !== null) {
                if(checkIn.role > 0){
                    const variables = {
                        user : userInf.email,
                        sessionId: checkIn.sessionId
                    }
                    getActivityAttendanceStats(variables)
                    .then(function (response) {
                        setIsTeacher(true);
                        setTotalStudentsPending(response.data.totalPending);
                        setTotalStudentsRegistered(response.data.totalAttend);
                        setTotalStudentsSchedule(response.data.totalRegistered + response.data.totalPending);
                        
                    })
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
                } else {
                    setIsTeacher(false);
                }
                
            } 

            setIsTimerVisible(true);

            // Building Date or Site Date ?
            setLastCheckIn(new Date(checkIn.siteDate !== null ? checkIn.siteDate : checkIn.buildingDate));

            // If checkIn is related to a scheduled activity
            if(checkIn.sessionId && checkIn.sessionId !== null) {
                setSessionId(checkIn.sessionId);
                setIsScheduled(true);
            }
            else {
                setIsScheduled(false);
            }

            // Move logic
            setRegisterAction('Registrar salida');
            setRegisterColor('exit');

            // Init timer
            const interval = setInterval( () => setNow(new Date()), 1000 );

            setIsLoading(false);
                
            return () => {
                clearInterval(interval);
            };
        }
        else if(key && key !== null) {
            // checkIn can be null because of rendering times
            getLastDayCheckIn({user: userInf.email, personId: userInf.role.personId})
            .then((response) => {
                if(response.data.resultCode === 0) {
                    setCheckIn(response.data);
                    updateContent(response.data);
                    setAlertMessage('Ya tiene un ingreso registrado');
                    setAlertType('warning');
                    setActiveMessage(true);
                    return;
                }
                else {
                    getLocationInfoByQrKey({ value: key}).then( (response) => {
                        if(response.data.resultCode === 1003) {
                            console.log('Attempted to access a non existing location');
                            navigate(ROUTES.QR_PRE);
                        }
                        else {
                            // Is it a site ?
                            if(response.data.siteId !== null) {
                                let obj = (state && state.sessionId !== null && state.sessionId !== undefined ? 
                                    {
                                        sessionId: state.sessionId,
                                        site: {
                                            "siteId": response.data.siteId,
                                            "buildingId": response.data.buildingId,
                                            "siteName": response.data.siteName,
                                            "levelName": response.data.levelName,
                                            "buildingName": response.data.buildingName,
                                            "shortName" : response.data.shortName,
                                            "siPucpLabel" : response.data.siPucpLabel
                                        }
                                    }
                                    :
                                    {
                                        site: {
                                            "siteId": response.data.siteId,
                                            "buildingId": response.data.buildingId,
                                            "siteName": response.data.siteName,
                                            "levelName": response.data.levelName,
                                            "buildingName": response.data.buildingName,
                                            "shortName" : response.data.shortName,
                                            "siPucpLabel" : response.data.siPucpLabel
                                        }
                                    }
                                    )
                                state = obj;
                            }
                            // Or a building
                            else if(response.data.buildingId !== null) {
                               
                                state= {
                                    building: {
                                        "buildingId": response.data.buildingId,
                                        "buildingName": response.data.buildingName,
                                        "shortName" : response.data.shortName
                                    }
                                }
                            }
                            setStateForNoScheduled(state);
                        }
                    }
                    ).then((nevermind)=>{
                        // Check if page is loaded after a QR scan and if a site or building is selected.
                        // Is the location a site or a building
                        let siteIdAux = null;
                        if(state && state !== null){
                            if(state.site) {
                                siteIdAux = state.site.siteId;
                                setIsSite(true);
                                setSiteId(state.site.siteId);
                                setBuildingId(state.site.buildingId);
                                setSiteName(state.site.siteName);
                                setLevelName(state.site.levelName);
                                setBuildingName(state.site.buildingName);
                                setSiPucpLabel(state.site.siPucpLabel);
                                setShortName(state.site.shortName);
                            }
                            else {
                                setIsSite(false);
                                setBuildingId(state.building.buildingId);
                                setBuildingName(state.building.buildingName);
                                setShortName(state.building.shortName);
                            }
                        }
                            
                        setCanRegister(false);
        
                        let date = new Date()
                    
                        let day = date.getDate()
                        let month = date.getMonth() + 1
                        let year = date.getFullYear()
                        let hour = 0;
                        let minute = 0;
                        let seconds = 0;
                                    
                        let stringDate = `${day < 10 ? '0'+day : day}/${month < 10 ? '0'+month : month}/${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${seconds < 10 ? '0' + seconds : seconds}`
                                        
                        const variables = {
                            firstResult: 1,
                            maxResults: 1000,
                            user: userInf.email,
                            siteId: siteIdAux,
                            date: stringDate
                        };
        
                        getActivitiesBySiteAndPerson(variables).then(function (response) {
                            const toFind = state && state.sessionId ? response.data.list.filter(act => act.sessionId === state.sessionId) : response.data.list;
                            const activity = toFind.length > 0 ? toFind[0] : undefined;
                            
                            //const activity = response.data.list.find((activity) => state.site ? activity.siteId === state.site.siteId : activity.buildingId === state.building.buildingId);
                                                      
                            if(activity === undefined){
                                // Not scheduled with no activities on site
                                setIsScheduled(false);
                                setCanRegister(true);
                                setIsTimerVisible(false);
                                setIsTeacher(false);
        
                                // Enable all non scheduled components
                                setIsLoading(false);
                            }
                            else {
                                //All of the following is assuming that activities are just in SITES(no Buildings...).
                                // There is an existing activity, fill activity data.

                                // If the user has a role, then its scheduled for them.
                                setActivityId(activity.activityId);
                                setSessionId(activity.sessionId);
                                setRole(activity.role);
                                setShift(activity.shift);
                                setFullDescription(activity.activityDescription);
                                setActivityLabel(activity.activityDescription);
                                setActivityType(activity.activityType);

                                if(activity.role !== null) {
                                    // Participant
                                    setIsScheduled(true);

                                    const variables = {
                                        user: userInf.email,
                                        siteId: state.site ? state.site.siteId : null,
                                        buildingId: state.building ? state.building.buildingId : null,
                                        sessionId: activity.sessionId,
                                        type: 1,
                                    };
                                            
                                    registerAssistance(variables)
                                        .then(function (response) {
                                            let message = "Su ingreso ha sido registrado";
                                            setAlertType("success");
                                            setAlertMessage(message);
                                            setActiveMessage(true);
                                            setAlertOpen(false);
            
                                            // Change newCheckIn according to data and site or building presence
                                            const newCheckIn = {
                                                siteId: state.site.siteId,
                                                buildingId: state.site.buildingId,
                                                sessionId: activity.sessionId,
                                                activityId: activity.activityId,
                                                siteDate: new Date(),
                                                buildingDate: new Date(),
                                                siteName: state.site.siteName,
                                                shortName : state.site.shortName,
                                                siPucpLabel : state.site.siPucpLabel,
                                                levelName: state.site.buildingName,
                                                buildingName: state.site.buildingName,
                                                activityDescription: activity.activityDescription,
                                                activityType: activity.activityType,
                                                shift: activity.shift,
                                                role: activity.role,
                                                fullDescription: activity.fullDescription
                                            };
                                            setCheckIn(newCheckIn);
                                            setActivityLabel(activity.activityDescription);
                                            setActivityType(activity.activityType);
                                            setSiteDate(new Date());
                                            setBuildingDate(new Date());
                                            setIsTimerVisible(true);
                                            if(activity.role > 0) {
                                                const variables = {
                                                    user : userInf.email,
                                                    sessionId : activity.sessionId
                                                }
                                                getActivityAttendanceStats(variables)
                                                .then(function (response) {
                                                    setIsTeacher(true);
                                                    setTotalStudentsPending(response.data.totalPending);
                                                    setTotalStudentsRegistered(response.data.totalAttend);
                                                    setTotalStudentsSchedule(response.data.totalRegistered + response.data.totalPending);
                                                    
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                            } else {
                                                setIsTeacher(false);
                                            }
                                            setIsLoading(false);
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            setAlertType("error");
                                            setAlertMessage("Su asistencia no ha sido registrada. Inténtelo de nuevo");
                                            setActiveMessage(true);
                                        });
                                }
                                else {
                                    // Unscheduled and visitor
                                    getStudentActivity({documentNumber: userInf.email}).
                                    then((response) => {

                                        if(response.data.sessionId) {
                                            setOtherActivityObj(response.data);
                                        }
                                        
                                        setIsScheduled(false);
                                        setIsTeacher(false);
                                        setIsTimerVisible(false);
                                        setCanRegister(true);

                                        

                                        // Enable all non scheduled components
                                        setIsLoading(false);
                                    }).
                                    catch((error) => console.log(error.response.data));
                                }
                            }
                        });
                             
                        // Init timer
                        const interval = setInterval( () => setNow(new Date()), 1000 );
                        
                        return () => {
                            clearInterval(interval);
                        };
         
                    }).catch(function (error) {
                        console.log(error);
                        setAlertType("error");
                        setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                        setActiveMessage(true);
                    });       
                }
            })
            .catch((error) => navigate('/'));

        }

    }, []);

    const handleGoToAttendance = () => {
        navigate(ROUTES.ATTENDANCE_REPORT, {
            state: {
                sessionId : checkIn.sessionId
            }
        })
    }

    React.useEffect(() => {
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage])

    const registerIncident = () => {
        navigate(ROUTES.INCIDENTS, {
            state: {
                location: {
                    id: siteId !== null ? siteId : buildingId,
                    type: siteId !== null ? 0 : 1,
                    comboName: siteId !== null ? (siPucpLabel === null ? siteName : siPucpLabel) + ' - ' + (shortName === null ? buildingName : shortName) : shortName === null ? buildingName : shortName
                }
            }
    })}

    const registerAttendance = () => {
        navigate(ROUTES.ATTENDANCE_STUDENT_SCAN, {
            state: {
                sessionId: sessionId
            }
        })
    }

    const returnActivities = () => {
        navigate(ROUTES.ACTIVITIES);
    }

    return(
        <>  
            {isLoading === false ?
            <Container className="containerCardsView">
                <div className="siteDetails" align='center'>
                    {activeMessage && <BasicAlerts message={alertMessage} type={alertType} />}
                    <LocationDetailsCard    // Complete card
                        activityLabel={activityLabel}
                        typeActivity={activityType}
                        siteName={formatNameCapitals(siteName ? (siPucpLabel === null ? siteName : siPucpLabel) : '')}
                        buildingName={formatNameCapitals(shortName === null ? buildingName : shortName)}
                        span={buildingName + (siteName ? ' - ' + siteName : '')}
                        shift={shift}
                        role={isTeacher}
                        arrive={lastCheckIn}
                        lastCheckIn={lastCheckIn}
                        checkIn={checkIn}
                    />

                    {checkIn === null && isScheduled === false &&
                        <Grid container className='unscheduled-container'>
                            <Grid item xs={12} className={otherActivityObj === null ? 'status-no-activity' : 'status-other-activity'}>
                                <InfoIcon/>
                                <Typography component="h5" variant="h5" ml='6px'>
                                    {otherActivityObj === null ? 'No tienes actividades programadas en este lugar' : 'Tienes una actividad programada en otro lugar'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                            {otherActivityObj !== null && ( // Actividad programada o no
                                <LocationDetailsCard
                                    checkIn={checkIn}
                                    activityLabel={otherActivityObj.activityDescription}
                                    typeActivity={otherActivityObj.activityType}
                                    siteName={formatNameCapitals(otherActivityObj.site)}
                                    shift={otherActivityObj.shift}
                                    isTeacher={null}
                                    arrive={null}
                                    lastCheckIn={null}
                                    noImage
                                />
                            )}
                            </Grid>
                            <Typography component="h5" variant="h5" marginTop="10px">
                                ¿Qué deseas hacer?
                            </Typography>
                            
                      </Grid>
                    }

                    
                    {isScheduled === true && isTeacher === true ?
                    <>
                    <Grid container xs={12} className='attendance-info' justifyContent='center' my="15px">
                        <Grid alignSelf='center' display='flex' flexDirection='column' container xs={3.6}>
                            <Typography
                                align='center'
                                color='rgba(0, 78, 168, 1)'
                                variant="h3"
                            >
                                {totalStudentsSchedule}
                            </Typography>
                            <Typography
                                align='center'
                                color='primary'
                                variant="h5"
                            >
                                Participantes
                            </Typography>
                        </Grid>
                        <Grid py='8px' justifyContent='center' display='flex' item xs={0.5}>
                            <Divider orientation="vertical"/>
                        </Grid>
                        <Grid alignItems='center' alignSelf='center' item xs={3.6}>
                            <Typography
                                align='center'
                                color='rgba(46, 125, 50, 1)'
                                variant="h3"
                            >
                                {totalStudentsRegistered}
                            </Typography>
                            <Typography
                                align='center'
                                color='primary'
                                variant="h5"
                            >
                                Asistentes
                            </Typography>
                        </Grid>
                        <Grid py='8px' item justifyContent='center' display='flex' xs={0.5}>
                            <Divider orientation="vertical"/>
                        </Grid>
                        <Grid alignItems='center' alignSelf='center' item xs={3.6}>
                            <Typography
                                align='center'
                                color='rgba(211, 47, 47, 1)'
                                variant="h3"
                            >
                                {totalStudentsPending}
                            </Typography>
                            <Typography
                                align='center'
                                color='primary'
                                variant="h5"
                            >
                                Sin registrar
                            </Typography>
                        </Grid>
                        <Grid alignItems='center' alignSelf='center' item xs={12}>
                            <Typography
                                align='center'
                                color='primary'
                                variant="h5"
                            >
                                Faltantes: {totalStudentsSchedule - totalStudentsPending - totalStudentsRegistered}
                            </Typography>
                        </Grid>
                    </Grid>
                    <GroupCircleButton
                        numButt={5}
                        buttonTexts={['Reportar Incidencia', 'Actualizar Asistencia', 'Tomar asistencia', 'Registrar por QR', 'Registrar Salida']}
                        iconButtons={[reportIncidentIcon, updateIcon, seeAtendanceIcon, registerAttendanceIcon, footIcon]}
                        onClicks={[registerIncident, updateAttendanceCounters, handleGoToAttendance, registerAttendance, registerCheckOut]}
                    />
                    </> : 
                    <Grid marginTop="10px">
                        <GroupCircleButton
                            numButt={checkIn !== null ? 2 : 3}
                            buttonTexts={[checkIn !== null ? 'Registrar Incidencia' : 'Volver al menú', checkIn !== null ? 'Registrar Salida' : 'Reportar incidencia', checkIn !== null ? null : 'Registrar Ingreso']}
                            iconButtons={[checkIn !== null ? reportIncidentIcon : ArrowBackIcon, checkIn !== null ? footIcon : reportIncidentIcon, checkIn !== null ? null : qrIcon]}
                            onClicks={[checkIn !== null ? registerIncident : returnActivities, checkIn !== null ? registerCheckOut : registerIncident, checkIn !== null ? null : registerCheckIn]}
                        />
                    </Grid>
                    }
                    {/* <Grid container spacing={0.5}>                        
                        {isTeacher === true ?
                        <>
                            <Grid container xs={12} justifyContent='center' marginBottom="10px">
                                <img src={checkIcon}></img>
                                <Typography
                                    align='left'
                                    color='primary'
                                    variant="h5"
                                    className='label-weight-location'
                                    marginLeft="5px">
                                    {"Asistencia: " + totalStudentsRegistered + " de " + totalStudentsSchedule}
                                </Typography>   
                            </Grid>
                            <Grid item xs={4}>
                                <CircleButton
                                    buttonText="Actualizar Asistencia"
                                    iconButton={checkIcon}
                                    onClick={() => console.log('A')}
                                />
                                <CircleButton
                                    buttonText="Registrar Asistencia"
                                    iconButton={registerAttendanceIcon}
                                    onClick={() => navigate(ROUTES.ATTENDANCE_STUDENT_SCAN, {
                                        state: {
                                            sessionId: sessionId
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CircleButton
                                    buttonText="Ver Asistencia"
                                    iconButton={seeAtendanceIcon}
                                    onClick={handleGoToAttendance}
                            />
                            </Grid>
                        </> 
                        : 
                        <Grid item xs={4}>
                            <CircleButton
                                buttonText="Anuncios y Noticias"
                                iconButton={announcementsAndNewsIcon}
                                onClick={() => {}}
                            />
                        </Grid>}
                        <Grid item xs={4}>
                            <CircleButton
                                buttonText="Reportar Incidencia"
                                iconButton={reportIncidentIcon}
                                onClick={() => navigate(ROUTES.INCIDENTS, {
                                    state: {
                                        location: {
                                            id: siteId !== null ? siteId : buildingId,
                                            type: siteId !== null ? 0 : 1,
                                            comboName: siteId !== null ? (siPucpLabel === null ? siteName : siPucpLabel) + ' - ' + (shortName === null ? buildingName : shortName) : shortName === null ? buildingName : shortName
                                        }
                                    }
                                })}
                            />
                        </Grid>
                        {isTeacher === true ? <Grid item xs={4}></Grid> : <></>}
                        <Grid item xs={4}>
                            <CircleButton
                                buttonText="Registrar Salida"
                                iconButton={registerCheckOutIcon}
                                onClick={registerCheckOut}
                            />
                        </Grid>
                    </Grid> */}

                    
                    
                    {/* {canRegister === true ?
                        <>
                            <Container>
                                <Typography  variant='h5' component='h5' color='primary'>
                                <Box sx={{fontStyle:'italic'}}>No tienes actividades programadas<br/>en este lugar.</Box>
                                </Typography>
                                <Typography variant='h5' component='h5' color='primary'>
                                    <br/>¿Qué deseas hacer?<br/><br/><br/>
                                </Typography>
                            </Container>
                            <Container>
                            <ButtonWithIcon
                                iconButton={canRegister ? exitIcon : accessIcon}
                                color='access' buttonText={!canRegister ? 'Registrar Salida' : 'Registrar Ingreso'}
                                rightIcon={!canRegister}
                                leftIcon={canRegister}
                                onClick={registerCheckIn}
                            />
                            <> 
                            <Divider className="personalizedDivider" variant="middle">o</Divider>
                            <ButtonWithIcon onClick={() => navigate(ROUTES.INCIDENTS, {
                                state: {
                                    location: {
                                        id: siteId !== null ? siteId : buildingId,
                                        type: siteId !== null ? 0 : 1,
                                        comboName: siteId !== null ? (siPucpLabel === null ? siteName : siPucpLabel) + ' - ' + (shortName === null ? buildingName : shortName) : shortName === null ? buildingName : shortName
                                    }
                                }
                            })} color="warning" buttonText='Registrar Incidencia'/>
                            </>
                            </Container>
                        </>
                    :
                        <></>           
                    } */}
                    <AlertOptions 
                        title={registerAction === 'Registrar ingreso' ? "Registro de ingreso" : "¿Deseas confirmar tu salida?"}
                        message={registerAction === 'Registrar ingreso' ? 
                            "¿Desea confirmar su ingreso siendo las " + formatDateSpanish(registerClickTime) + " al " + siteName + "?"
                            :"¿Desea confirmar su salida siendo las " + formatDateSpanish(registerClickTime) + " al " + siteName + "?"}
                        alertOpen={alertOpen}
                        setAlertOpen={setAlertOpen}
                        onConfirm={confirmRegisterCheckOut}
                    />
                    <AlertOptions 
                        title='Registro de ingreso'
                        message={"¿Desea confirmar su ingreso siendo las " + formatDateSpanish(registerClickTime) + " al " + siteName + "?"}
                        alertOpen={alertOpenUnscheduled}
                        setAlertOpen={setAlertOpenUnscheduled}
                        onConfirm={confirmRegisterCheckIn}
                    />
                </div> 
            </Container> 
            : 
            <></>
            }
        </>
    );
};

export default LocationDetails;
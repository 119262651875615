import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const getInfoUser = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      user: variables.user,
      doiType: variables.doiType,
      doiNumber: variables.doiNumber,
      password: variables.password
  }
  return axios.post(
    API_URL.url + "security/userInfo",
    obj,
  );
};

export const encryptedUserInfo = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      user: variables.user,
  }
  return axios.post(
    API_URL.url + "security/encryptedUserInfo",
    obj,
  );
};
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { INSTRUCTIONS } from '../../constants/instructions';
import * as ROUTES from "../../routes/routes";
import imageLayout from '../../assets/images/qr_scan.png';
import './QRScan.css';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon';
import BasicAlerts from '../../components/Alert/Alert';


const QRScanPre  = () => {

    const { state } = useLocation();
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        
        if(state && state.alertType && state.alertMessage) {
            setAlertType(state.alertType);
            setAlertMessage(state.alertMessage);
            setActiveMessage(true);
        }

    }, []);

    React.useEffect(()=>{
        
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage]);

    return(
        <>
        <div className='waitingRoom'>
            <Grid container direction="row">
                <Grid item xs={0} sm={3} md={4} lg={4}/>
                <Grid item xs={12} sm={8} md={6} lg={4} >
                    <img className='qrScan' src = {imageLayout}/>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={1} sm={3} md={4} lg={4}/>
                <Grid item xs={10} sm={8} md={6} lg={4} >
                    <div className='instructionsDiv'>
                        {INSTRUCTIONS.map((text) => (<Typography className='instruction' color='primary'>{text}</Typography>))}
                    </div>
                </Grid>
            </Grid>

            <ButtonWithIcon noCaps onClick={ () => navigate(ROUTES.QR_SCAN, { state: 1 }) } buttonText = 'Escanear Código QR'/>
        </div>
        {activeMessage &&
            <BasicAlerts
                message={alertMessage}
                type={alertType}
            />
        }
        </>
    )

};

export default QRScanPre;
import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const getIncidentMotives = () => {
  const obj = {
      keyCode: API_URL.keyCode,
  }

  return axios.post(
    API_URL.url + "common/reasonsIncident",
    obj,
  );
};

export const getSectors = () => {
  const obj = {
    keyCode: API_URL.keyCode,
  }

  return axios.post(
    API_URL.url + "common/sectors",
    obj,
  );
};

export const getDivisons = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    id: variables.id
  }

  return axios.post(
    API_URL.url + "common/sites",
    obj,
  );
};

export const getPlaces = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    id: variables.id
  }

  return axios.post(
    API_URL.url + "common/locations",
    obj,
  );
};

export const getSectorsAutocomplete = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      value: variables.value,
  }

  return axios.post(
    obj,
    API_URL.url + "common/autocompleteSectors",
  );
};
export const getPersonsAutocomplete = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      value: variables.value,
  }

  return axios.post(
    API_URL.url + "common/autocompletePersons",
    obj,
  );
};

export const getSitesAutocomplete = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      value: variables.value,
  }

  return axios.post(
    API_URL.url + "common/autocompleteSites",
    obj,
  );
};

export const getLocationsAutocomplete = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      value: variables.value,
  }

  return axios.post(
    API_URL.url + "common/autocompleteLocations",
    obj,
  );
};

export const getDivisonsAutocomplete = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      value: variables.value,
  }
  return axios.post(

    API_URL.url + "common/autocompleteSites",
    obj,
  );
};


export const getLocationInfoByQrKey = (variables) => {
  const obj = {
      keyCode: API_URL.keyCode,
      value: variables.value,
  }
  
  return axios.post(
    API_URL.url + "common/locationInfoByQr",
    obj,
  );
};
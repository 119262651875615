import React from 'react';
import { 
    Container,
    Button,
    Grid,
    Typography
} from '@mui/material';
import $ from 'jquery';
import Slider from 'react-slick';
import TopBar from "../../components/TopBar/TopBar";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes.js";
import './OnBoarding.css';
import {appOptions} from '../../constants/onBoardingText.js';
import { getLastDayCheckIn } from '../../services/attendance/AttendanceServices.js';
import { getActivities } from '../../services/activities/ActivitiesServices.js';

const OnBoarding = (props) => {
    const {title}=props;
    let navigate = useNavigate();
    const [disablePrev, setDisablePrev] = React.useState(true);
    const [disableNext, setDisableNext] = React.useState(false);

    const closeOnBoarding = (e) => {
      let value = e;
      switch (value) {
        case "Actividades":
          navigate(ROUTES.ACTIVITIES);
          break;
        case "Asistencia":
          navigate(ROUTES.ATTENDANCE);
          break;
        case "Escanear QR":
          navigate(ROUTES.QR_PRE);
          break;
        case "Aforo":
          navigate(ROUTES.CAPACITY);
          break;
        case "Incidencias":
          navigate(ROUTES.INCIDENTS);
          break;
        default:
          break;
      }
    };

    const skipOnBoarding = () => {
      const user = JSON.parse(localStorage.getItem("userPUCPIN"));
      getLastDayCheckIn({user: user.email, personId: user.role.personId})
        .then((response) => {
          if(response.data.resultCode === 0) {
            // There is an active check-in
            navigate(ROUTES.QR_PRE);
          }
          else {
            let date = new Date()
                    
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let hour = date.getHours();
            let minute = date.getMinutes();
            let seconds = date.getSeconds();
                        
            let stringDate = `${day < 10 ? '0'+day : day}/${month < 10 ? '0'+month : month}/${year} ${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${seconds < 10 ? '0' + seconds : seconds}`
                            
            const variables = {
                firstResult: 1,
                maxResults: 1000,
                user: user.email,
                personId: user.role.personId,
                date: stringDate
            };
                  
            getActivities(variables)
              .then(function (response) {
                if(response.data.resultCode === 1003) {
                  // No activities
                  navigate(ROUTES.QR_PRE);
                }
                else {
                  // User has activities
                  navigate(ROUTES.ACTIVITIES);
                }
              })
              .catch(function (error) {
                console.log(error);
                navigate(ROUTES.QR_PRE);
              });
          }
        })
        .catch((error) => {
          console.log("Login error: ", error);
          navigate(ROUTES.QR_PRE);
        });
    }

    const settings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 500,
      focusOnSelect: false,
      customPaging: function(slider, i) {
        return (
          <a><svg width="100%" height="100%" viewBox="0 0 16 16"><circle cx="8" cy="8" r="6.215"></circle></svg><span></span></a>
        );
      },
      beforeChange: (event, slick, currentSlide, nextSlide) => {        
        $('.item').removeClass('from-right from-left ready');
        var c = (currentSlide < nextSlide) ? 'from-right' : 'from-left';
        $('.item').eq(nextSlide).addClass(c);
      },
      afterChange: (event, slick, currentSlide, nextSlide) => {
        if(event === 0){
          setDisablePrev(true);
        } else if(event === 4){
          setDisableNext(true);
        } else {
          setDisablePrev(false);
          setDisableNext(false);
        }
        $('.item').eq(currentSlide).addClass('ready');
      }
    };

    const customeSlider = React.useRef();

    const gotoNext = () => {
      customeSlider.current.slickNext()
    }
  
    const gotoPrev = () => {
      customeSlider.current.slickPrev()
    }

    return (
      <>
        {/*<TopBar title={title}/>*/}
        <Container className="onBoardingLayout">          
          <div className="onboard">  
            <Typography variant="button" color="action.main" align="right" paragraph onClick={skipOnBoarding}>
              Omitir 
            </Typography>              
            <Typography variant="h2" color="primary">
              En PUCP-IN podrás: 
            </Typography>  
            <Slider {...settings} ref={customeSlider}>            
              {appOptions.map((option, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="ico">
                      {option.icon}
                    </div>
                    <Typography variant="h2" color="warning.main">
                      {option.name}
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="primary">
                      {option.text}                      
                    </Typography>
                    <br/>
                    <br/>                    
                  </div>
                );
              })}
            </Slider>
            <Grid container>
              <Grid item container xs={6} justifyContent="flex-start"> 
                {disablePrev? 
                  <></>:            
                  <Button onClick={()=>gotoPrev()}><Typography variant="h2" color="action.main">Anterior</Typography></Button>}
              </Grid>
              <Grid item container xs={6} justifyContent="flex-end">    
                <Button onClick={disableNext?()=>skipOnBoarding():()=>gotoNext()}><Typography variant="h2">{disableNext? "Comenzar":"Siguiente"}</Typography></Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </>
    );
    
}

export default OnBoarding;
export const PRIMARY = "#042354";
export const SLATE_GRAY = "#667892";
export const LIGHT_GRAY = "#A5B0C1";
export const OFF_WHITE = "#F7F7FC";
export const WHITE = "#FFFFFF";
export const RED = "#E23008";
export const ORANGE = "#FF9929";
export const LIME_GREEN = "#ADD754";
export const MARINE_BLUE = "#004EA8";
export const SKY_BLUE = "#41B9E4";
export const BLUE_GREY = "#667892";
export const LIGHT_BLUE_GREY = "#667892";

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import './AlertOptionsRow.css';


export default function AlertOptionsRow(props) {
  const {title, message, alertOpen, setAlertOpen, onConfirm} = props;

  return (
    <div>
      <Dialog
        open={alertOpen}
        onClose={ () => setAlertOpen(false) }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{sx: {width: "90%"}, className:"dialogRounded alert-dialog"}}
      >
        <DialogTitle id="alert-dialog-title" className='alertRowTitlePI' color={"primary"}>
          {title}
        </DialogTitle>
        <DialogActions className='dialogRowActionsStyle'>          
          <Button variant="outlined" className='alertRowOpButtonPI' fullWidth onClick={()=>setAlertOpen(false)}>
            Cancelar
          </Button>
          <Button variant="contained" className='alertRowOpButtonPI' fullWidth onClick={()=>onConfirm()}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import { createTheme } from "@mui/material/styles";
import { MARINE_BLUE, ORANGE, PRIMARY, RED, WHITE, LIGHT_GRAY, BLUE_GREY,LIGHT_BLUE_GREY } from "../../constants/colors";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: PRIMARY,
      contrastText: WHITE,
    },
    danger: {
      main: RED,
      contrastText: WHITE,
    },
    warning: {
      main: ORANGE,
      contrastText: WHITE,
    },
    login: {
      main: WHITE,
    },
    access: {
      main: MARINE_BLUE,
      contrastText: WHITE,
    },
    antiAccess:{
      main: WHITE,
      contrastText: MARINE_BLUE,
    },
    exit: {
      main: RED,
      contrastText: WHITE,
    },
    action: {
      main: BLUE_GREY,      
      secondary: LIGHT_BLUE_GREY,
      contrastText: WHITE,      
    }
  }
});

theme.typography.h1 = {
  fontSize: "32",
  fontFamily: "Roboto",
  fontWeight: "bold"
};

theme.typography.h2 = {
  fontSize: "24",
  fontFamily: "Roboto",
  fontWeight: "bold"
};

theme.typography.h3 = {
  fontSize: "18",
  fontFamily: "Roboto",
  fontWeight: 600
};

theme.typography.h4 = {
  fontSize: "16",
  fontFamily: "Roboto",
  fontWeight: 600
};

theme.typography.h5 = {
  fontSize: "14",
  fontFamily: "Roboto",
  fontWeight: 600
};

theme.typography.h6 = {
  fontSize: "10",
  fontFamily: "Roboto",
  fontWeight: 600
};

theme.typography.body1 = {
  fontSize: "14",
  fontFamily: "Montserrat",
  fontWeight: 600
};

theme.typography.body2 = {
  fontSize: "14",
  fontFamily: "Montserrat"
};

theme.typography.button = {
  fontSize: "16",
  fontFamily: "Roboto",
  fontWeight: 600
};

theme.components.MuiBottomNavigationAction = {
  styleOverrides: {
    root: {
      font: (theme) => theme.typography.h6,
      color: LIGHT_GRAY,
      minWidth:"65px"
    },
  },
};

export default theme;

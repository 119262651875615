export const LOGIN = "/login";
export const ACTIVITIES = "/activities";
export const ACTIVITY_DETAIL = "/activity/detail";
export const ATTENDANCE = "/attendance";
export const ATTENDANCE_RESULTS = "/attendance-results";
export const ATTENDANCE_REPORT = "/attendance-report";
export const ATTENDANCE_STUDENT_SCAN = "/attendance-student-scan";
export const ATTENDANCE_STUDENT_INFO = "/attendance-student-info"
export const CAPACITY = "/capacity";
export const CAPACITY_RESULTS = "/capacity-results";
export const INCIDENTS = "/incidents";
export const ONBOARDING = "/onboarding";
export const QR_PRE = "/qrInstructions";
export const QR_SCAN = "/qrScan";
export const LOCATION_DETAILS = "/location";
export const PROFILE = "/perfil";
export const MAPA = "/mapa";
import * as React from 'react';
import { 
    Button, Grid,
} from '@mui/material';
import './CircleButton.css';
import { formatNameCapitals } from '../../constants/commonFunctions';
import CircleButton from './CircleButton';
import './GroupCircleButton.css';

const GroupCircleButton  = (props) => {
    const {numButt, buttonTexts, iconButtons, onClicks} = props;
    const icons = iconButtons.map(iconBts => {(<img src={iconBts}/>)});

    return(
        <>
            {numButt === 2 && 
            <>
                <div className='button-center-up'>
                    <CircleButton
                        buttonText={buttonTexts[0]}
                        iconButton={iconButtons[0]}
                        onClick={onClicks[0]}
                        hx={"h4"}
                    />
                </div>
                <div className='button-center-down'>
                    <CircleButton 
                        className={"bigCircleButton"}
                        buttonText={buttonTexts[1]}
                        iconButton={iconButtons[1]}
                        onClick={onClicks[1]}
                        hx={"h2"}
                    />
                </div>
            </>
            }
            {numButt === 3 &&
            <>
                <div className='button-left-up'>
                    <CircleButton
                        buttonText={buttonTexts[0]}
                        iconButton={iconButtons[0]}
                        onClick={onClicks[0]}
                        hx={"h4"}
                    />
                </div>
                <div className='button-right-up'>
                    <CircleButton 
                        buttonText={buttonTexts[1]}
                        iconButton={iconButtons[1]}
                        onClick={onClicks[1]}
                        hx={"h4"}
                    />
                </div>
                <div className='button-center'>
                    <CircleButton 
                        className={"bigCircleButton"}
                        buttonText={buttonTexts[2]}
                        iconButton={iconButtons[2]}
                        onClick={onClicks[2]}
                        hx={"h2"}
                    />
                </div>
            </>
            }
            {numButt === 5 && 
            <>
                <div className='button-left-up'>
                    <CircleButton
                        buttonText={buttonTexts[0]}
                        iconButton={iconButtons[0]}
                        onClick={onClicks[0]}
                        hx={"h4"}
                    />
                </div>
                <div className='button-right-up'>
                    <CircleButton 
                        buttonText={buttonTexts[1]}
                        iconButton={iconButtons[1]}
                        onClick={onClicks[1]}
                        hx={"h4"}
                    />
                </div>
                <div className='button-center'>
                    <CircleButton 
                        className={"bigCircleButton"}
                        buttonText={buttonTexts[2]}
                        iconButton={iconButtons[2]}
                        onClick={onClicks[2]}
                        hx={"h2"}
                    />
                </div>
                <div className='button-left-down'>
                    <CircleButton 
                        buttonText={buttonTexts[3]}
                        iconButton={iconButtons[3]}
                        onClick={onClicks[3]}
                        hx={"h4"}
                    />
                </div>
                <div className='button-right-down'>
                    <CircleButton 
                        buttonText={buttonTexts[4]}
                        iconButton={iconButtons[4]}
                        onClick={onClicks[4]}
                        hx={"h4"}
                    />
                </div>
            </>
            }
        </>
    )

};

export default GroupCircleButton;
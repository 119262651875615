import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const getCapacity = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    firstResult: variables.firstResult,
    maxResults: variables.maxResults,
    user: variables.user,
    personId: variables.personId,
    from: variables.startDate,
    to: variables.endDate, //optional
    siteId: variables.siteId, //optional
    buildingId: variables.buildingId //optional
  }

  return axios.post(
    API_URL.url + "capacity/query",
    obj,
  );
};
import * as React from 'react';
import { 
    Container,
    Grid,
    Typography
} from '@mui/material';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import {formatDateTime, formatDateTimeSecondsBackEnd} from '../../constants/commonFunctions';
import { getCapacity } from '../../services/capacity/CapacityServices';
import { getLocationsAutocomplete, getSitesAutocomplete } from '../../services/commons/commonsServices';
import BasicAlerts from '../../components/Alert/Alert';
import ComboboxStyled from '../../components/Combobox/Combobox';
import { useLocation } from "react-router-dom";
import CapacityCard from '../../components/Card/Capacity/CapacityCard';
import Box from '@mui/material/Box';
import seeMoreIcon from '../../assets/icons/see-more.svg';
import searchIcon from '../../assets/icons/search.svg'
import './Capacity.css';


const Capacity  = () => {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [placeString, setPlaceString] = React.useState('');    
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [places, setPlaces] = React.useState([]);  
    const [placesObjs, setPlacesObjs] = React.useState([]);
    const [placeObj, setPlaceObj] = React.useState({label: '', id: undefined});    
    const [advancedSetttings, setAdvancedSetttings] = React.useState(false);
    const userInf = useSelector(state => state.userReducer.user);    
    const [role, setRole] = React.useState(userInf && userInf.role? userInf.role.idRol:-1);
    const [cantCall, setCantCall] = React.useState(true);
    const [totalResults, setTotalResults] = React.useState(0);
    const [results, setResults] = React.useState([]);    
    const [firstResult, setFirstResult] = React.useState(1);
    const [lastResult, setLastResult] = React.useState(2);
    const [moreInf, setMoreInf] = React.useState(false);    
    const [disabledCapacity, setDisabledCapacity] = React.useState(false);
    const { state } = useLocation();

    let navigate = useNavigate();

    const goToResults = (newLastResult) => {
        const startDateQuery = new Date();
        setStartDate(startDateQuery);

        const variables = {
            firstResult: firstResult,
            maxResults: newLastResult ? newLastResult : 2,
            user: userInf.email,
            personId: userInf.role.personId,
            startDate: formatDateTimeSecondsBackEnd(startDateQuery),  
            endDate: formatDateTimeSecondsBackEnd(endDate), //optional
            siteId: placeObj.type === 0 ? placeObj.id : null,
            buildingId: placeObj.type === 1 ? placeObj.id : null
        }
        getCapacity(variables)
            .then(function (response) {
                if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                    setResults(response.data.list)
                    setTotalResults(response.data.total)
                    setLastResult(2);
                    const activeButton = response.data.total > 2;
                    setMoreInf(activeButton);
                }
            })
            .catch(function (error) {
                console.log(error);
                setAlertType("error");
                setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                setActiveMessage(true);
            });
    }

    React.useEffect(() => {
        goToResults();
    }, []);

    React.useEffect(()=>{
        
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage])

    React.useEffect(() => {
        if(search !== undefined && search.length > 2) {

            let variables = {
                value: search
            }
            getLocationsAutocomplete(variables)
                .then((response) => {
                    let plcs = []
                    let plcsObjs = []
                    for (const plcObj of response.data.list) {
                        plcsObjs.push(plcObj)
                        plcs.push(plcObj.value)
                    }
                    setPlaces(plcs)
                    setPlacesObjs(plcsObjs)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [search])

    React.useEffect(() => {
        if(placeString === null || placeString.length === 0) {
            setPlaceObj({label: '', id: undefined, type: -1});
            setPlaces([]);
            setPlacesObjs([]);
            setDisabledCapacity(true);
        }
        else {
            for (const plcObj of placesObjs) {
                if(plcObj.value === placeString){
                    setPlaceObj({ label: plcObj.value, id: plcObj.key, type: plcObj.type });
                } 
            }
            setDisabledCapacity(false);
        }
    }, [placeString]);

    /*React.useEffect(() => {
        if (state.capacities) {
            setResults(state.capacities.list);
            setTotalResults(state.capacities.total);            
            const activeButton = state.capacities.total > lastResult;
            setMoreInf(activeButton);
        }
    }, [state]);*/

    const getCapacityResults = (newLastResult) => {
        const variables = {
            firstResult: firstResult,
            maxResults: newLastResult,
            user: userInf.email,
            personId: userInf.role.personId,
            startDate: formatDateTimeSecondsBackEnd(startDate),  
            endDate: formatDateTimeSecondsBackEnd(endDate),
        };
        
        getCapacity(variables)
            .then(function (response) {
                if(response.data.resultCode === 0 || response.data.resultCode === 1003) {
                    setResults(response.data.list);                               
                    const activeButton = response.data.total>newLastResult;
                    setMoreInf(activeButton);
                }
            })
            .catch(function (error) {
                console.log(error);                 
                setAlertType("error");
                setAlertMessage("Se produjo un error en la consulta. Inténtelo de nuevo");
                setActiveMessage(true);
            });
    }

    const moreResults = () =>{
        const newLastResult=lastResult+2;
        setLastResult(newLastResult);
        getCapacityResults(newLastResult);
    }

    /*const activeAdvancedSettings = () =>{
        let oldValueAdvancedSettings = advancedSetttings;
        setAdvancedSetttings(!advancedSetttings);
    }*/

    return(
        <Grid container xs={12} justifyContent='center'>
        <Grid container xs={12} sm={6}>   
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }
            <Container 
                style={{marginTop: "20px", marginBottom: "20px"}}
            > 
                <Grid container spacing={2} direction="column">
                    <Typography 
                        component="h3"                     
                        variant="h3"
                        color="primary"
                        style={{marginTop: "20px", marginLeft: "20px"}}
                    >
                        Lugar:
                    </Typography>
                    <ComboboxStyled
                        comboboxOptions={places} 
                        value={placeObj}
                        setValue={setPlaceString}
                        setSearch={setSearch}
                        active={true}
                    />  
                    {role !== 1 /*&& !advancedSetttings &&(
                    <LinkWithIcon
                        linkText="Configuración Avanzada"
                        icon={<TuneIcon 
                                color="primary"
                                className="iconStyle"/>}
                        callFunction={activeAdvancedSettings}
                    />
                    )*/}
                    {/*advancedSetttings && <LinkWithIcon
                        linkText="Configuración Simple"
                        icon={<TuneIcon 
                                color="primary"
                                className="iconStyle"/>}
                        callFunction={activeAdvancedSettings}
                    />*/}
                    <Grid item>
                        {/*<ButtonWithIcon disabled={cantCall} buttonText="Consultar" goToResults={()=>goToResults()} iconButton={<SearchIcon/>}/>*/}
                        <div align='center'>
                            <ButtonWithIcon 
                                buttonText="Consultar"
                                iconButton={searchIcon}
                                onClick={()=>goToResults()}
                                leftIcon
                                fullWidth
                                disabled={disabledCapacity}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>  

            {totalResults !== 0 && <Container 
                className="containerCardsView"
            >     
            <Grid container spacing={2} direction="column">
                <Typography 
                    component="h3"                     
                    variant="h3"
                    color="primary"
                    style={{marginTop: "20px", marginLeft: "20px"}}
                >
                    Total de resultados: {totalResults}
                </Typography> 
                <Typography 
                    component="body1"                     
                    variant="body1"
                    color="access.main"
                    style={{marginTop: "10px", marginLeft: "20px"}}
                >
                    Al {formatDateTime(startDate)}
                </Typography> 
            </Grid>
            <div align='center'>
                {results.map((result, index)=>{
                    return(
                        <CapacityCard 
                            key={index}
                            place={result.site !== null ?
                                (!result.siPucpLabel? result.site : result.siPucpLabel) :
                                (!result.shortName? result.building : result.shortName) }
                            amount={result.capacity} 
                            maxAmount={result.maximum}
                            time={result.hourDate}
                            labelAmount={'Actual'}
                        />
                    )
                })}
                {results && results.length===0 &&
                    <Typography 
                        className="titlesViews" 
                        variant="subtitle1" 
                        component="div" 
                        gutterBottom 
                        align='center'
                    >
                        No se cuenta con información para esta consulta
                    </Typography> 
                }
                {results && results.length>0 && moreInf && 
                <Box className="containerButtonIcon">
                    <ButtonWithIcon
                        buttonText="Ver más" 
                        iconButton={seeMoreIcon}
                        onClick={()=>moreResults()}
                        rightIcon
                    />
                </Box>}
            </div>          
            </Container> 
            }
        </Grid>
        </Grid>
    )


};

export default Capacity;
import * as React from 'react';
import { 
    Typography,
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ButtonWithIcon from '../../components/Button/ButtonWithIcon';
import ComboboxStyled from '../../components/Combobox/Combobox';
import DescriptionField from '../../components/DescriptionField/DescriptionField';
import { useSelector } from 'react-redux';
import { registerIncident } from '../../services/incidents/IncidentsServices';
import { getIncidentMotives, getLocationsAutocomplete } from '../../services/commons/commonsServices';
import { getSitesAutocomplete } from '../../services/commons/commonsServices';
import AlertOptions from '../../components/Alert/AlertOptions';
import BasicAlerts from '../../components/Alert/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { QR_PRE, QR_SCAN } from '../../routes/routes';
import { formatTime, formatDate,formatDateHyphen } from '../../constants/commonFunctions';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import QrIcon from '../../assets/icons/qr.svg'
import './Incidents.css'


const Incidents  = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const [reasons, setReasons] = React.useState([{
        label: "Seleccionar un motivo",
        id: -1
    }])
    const [reasonSelected, setReasonSelected] = React.useState({
        label: "Seleccionar un motivo",
        id: -1
    });
    const [siteSelectedObj, setSiteSelectedObj] = React.useState({
        label: "Seleccionar un lugar",
        id: -1,
        type: -1
    });
    const [siteObjs, setSiteObjs] = React.useState([]);
    const [siteStrings, setSiteStrings] = React.useState([]);
    const [siteString, setSiteString] = React.useState('');
    const [waiting, setWaiting] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const [activeMessage, setActiveMessage] = React.useState(false);
    const [alertActive, setAlertActive] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [insideCampus, setInsideCampus] = React.useState(1);
    const [dateValue, setDateValue] = React.useState(null);
    const [dateString, setDateString] = React.useState('');
    const [timeString, setTimeString] = React.useState('');
    const [clickedPlace, setClickedPlace] = React.useState(false);
    const userInf = useSelector(state => state.userReducer.user);

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    React.useEffect(() => {
        // Always get the saved incident content
        var saved = localStorage.getItem('incident');
        if(saved && saved !== null) {
            localStorage.removeItem('incident');
            saved = JSON.parse(saved);
        }
        // If user comes from site details
        if(state !== null && state.location) {
            setSiteStrings([state.location.comboName]);
            setSiteSelectedObj({ label: state.location.comboName, id: state.location.id, type: state.location.type });            
        }
        else if (saved && saved !== null){
            // Restore incident if it was saved
            setReasonSelected(saved.reasonSelected);
            setInsideCampus(saved.insideCampus);
            setSiteSelectedObj(saved.siteSelectedObj);
            setSiteStrings([saved.siteSelectedObj.label]);
            setDateValue(saved.dateValue);
            setDateString(formatDateHyphen(new Date(saved.dateValue)));
            setTimeString(saved.timeString);
            setDescription(saved.description);
        }

        if(state !== null && state.locationScanned){
            setSiteStrings([state.locationScanned.comboName]);
            setSiteSelectedObj({label: state.locationScanned.comboName, id: state.locationScanned.id, type: state.locationScanned.type});            
        }

        getIncidentMotives()
            .then(function (response) {

                var array = [];
                for (const element of response.data.list) {
                    array.push({
                        label: element.description,
                        id: element.incidentReasonId
                    })
                };
                setReasons(array);
            })
            .catch(function (error) {
                console.log(error);
                setAlertType("error");
                setAlertMessage("Los motivos no se han cargado");
                setActiveMessage(true);
            });
    },[]);

    React.useEffect(() => {
        if(search !== undefined && search.length > 2) {

            let variables = {
                value: search
            }
            getLocationsAutocomplete(variables)
                .then((response) => {
                    let sts = []
                    let stsObjs = []
                    for (const stObj of response.data.list) {
                        stsObjs.push(stObj);
                        sts.push(stObj.value);
                    }
                    setSiteStrings(sts);
                    setSiteObjs(stsObjs);
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [search])

    React.useEffect(() => {
        if(siteString === null) {
            setSiteSelectedObj({
                label: "",
                id: -1,
                type: -1
            });
            setSiteStrings(["Seleccionar un lugar"]);
        }
        else {
            for (const stObj of siteObjs) 
                if(stObj.value === siteString)
                    setSiteSelectedObj({ label: stObj.value, id: stObj.key, type: stObj.type }); 
        }

    }, [siteString]);

    React.useEffect(() => {
        if(reasonSelected === null) {
            setReasonSelected({
                label: "",
                id: -1
            });
        }
    }, [reasonSelected]);
    
    React.useEffect(()=>{
        if (activeMessage){
            setTimeout(() => {
                setActiveMessage(false);
            }, 5000);
        }

    }, [activeMessage])

    React.useEffect(()=>{
        if (clickedPlace){
            setSiteSelectedObj({
                label: " ",
                id: -1
            })
        }
    }, [])

    const registerAnIncident = () => {
        const variables = {
            user: userInf.email,
            personId: userInf.role.personId,
            reasonIncidentId: reasonSelected.id,
            description: description,
            incidentDate: state === null ? new Date(dateString + " " + timeString) : null,
            insideCampus: insideCampus
        }

        if(insideCampus === 1) {
            if(siteSelectedObj.type === 0) {
                variables.siteId = siteSelectedObj.id;
                variables.buildingId = null;
            }
            else {
                variables.buildingId = siteSelectedObj.id;
                variables.siteId = null;
            }
        }
        else {
            variables.siteId = null;
            variables.buildingId = null;
        }
        
        registerIncident(variables)
            .then(function (response) {
                setAlertActive(false);
                if(response.data.resultCode === 0 || response.data.resultCode === 1003) {  
                    setAlertType("success");
                    setAlertMessage("La incidencia ha sido registrada");
                    setActiveMessage(true); 
                    
                    if(state && state.location){
                        setWaiting(true);
                        timeout(2000).then(() => navigate(state.navigation?state.navigation : -1));
                    }
                    else{
                        setReasonSelected({
                            label: "Seleccionar un motivo",
                            id: -1,
                            type: -1
                        });
                        setSiteSelectedObj({
                            label: "Seleccionar un lugar",
                            id: -1,
                            type: -1
                        });
                        setSiteStrings(["Seleccionar un lugar"]);
                        setTimeString('');
                        setDateValue(null);
                        setDateString('');
                        setInsideCampus(1);
                        setDescription('');
                    }     
                    
                }
            })
            .catch(function (error) {
                setAlertActive(false);
                console.log(error);
                setAlertType("error");
                setAlertMessage("No se ha podido registrar la incidencia. Inténtelo de nuevo");
                setActiveMessage(true);
            });
    }

    const confirmIncident = () => {
        
        if ( reasonSelected.id < 0){
            setAlertActive(false);
            setAlertType("error");
            setAlertMessage("Se debe seleccionar un motivo para registrar la incidencia");
            setActiveMessage(true);
            return;
        }

        if( insideCampus === 1 && siteSelectedObj.id < 0 ) {
            setAlertActive(false);
            setAlertType("error");
            setAlertMessage("Se debe seleccionar un ambiente para registrar la incidencia.");
            setActiveMessage(true);
            return;
        }
        if(!(state && state.location) && (dateValue === null || timeString === '')) {
            setAlertActive(false);
            setAlertType("error");
            setAlertMessage("Debe detallar la fecha y hora para registrar la incidencia.");
            setActiveMessage(true);
            return;
        }

        setAlertActive(true);
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
      }));

      const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#106ba3',
        },
      });

    function BpRadio(props) {
        return (
          <Radio
            sx={{
              '&:hover': {
                bgcolor: 'transparent',
              },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
          />
        );
      }

    function changeTime(timeValue, dateValue = null){
        let date = dateValue !== null ? dateValue : dateString;
        
        let time = timeValue

        let now = new Date();

        let maxTime = formatTime(now);

        if(date === formatDateHyphen(now)){
            time = parseInt(time[0]+time[1]) > now.getHours() ? maxTime : parseInt(time[3]+time[4]) > now.getMinutes() ? maxTime : time;
        }

        setTimeString(time)
    }

    const handleChangeReasonSelected = (event) => {
        setReasonSelected(event.target.value);
    };

    function onClickComboboxSite () {
        // Check if there is data to save
        if(!(reasonSelected.id === -1 && siteSelectedObj.id === -1 && description === '' &&
            dateValue === null && timeString === '')) {
            localStorage.setItem("incident", JSON.stringify({
                reasonSelected : reasonSelected,
                insideCampus : insideCampus,
                siteSelectedObj : siteSelectedObj,
                dateValue : dateValue,
                timeString : timeString,
                description : description
            }));
        }
        navigate(QR_SCAN, {
            state: {
                "state": 5
            }
        });
    }

    return(
        <>  
            {activeMessage &&
                <BasicAlerts
                    message={alertMessage}
                    type={alertType}
                />
            }
            <Container 
                className="containerView"
            > 
                <Grid container spacing={2} direction="column">
                {state && state.location && (
                <>
                    <Typography 
                        component="h3"                     
                        variant="h3"
                        color="primary"
                        style={{marginTop: "20px", marginLeft: "20px"}}
                    >
                        Lugar:
                    </Typography>
                    <ComboboxStyled
                        comboboxOptions={siteStrings} 
                        value={siteSelectedObj}
                        setValue={setSiteString}
                        setSearch={setSearch}
                        active={false}
                    />
                </>
                )}
                    <Typography 
                        component="h3"                     
                        variant="h3"
                        color="primary"
                        style={{marginTop: "20px", marginLeft: "20px"}}
                    >
                        ¿Qué sucedió?
                    </Typography>
                    <Grid item xs={12} md={8}>
                        <Select
                            value={reasonSelected}
                            renderValue={(selected) => {
                                if (selected.id === -1) {
                                    return <>Seleccione un motivo</>;
                                }
                                return <Typography className='text-select'>{selected.label}</Typography>
                            }}
                            onChange={event => handleChangeReasonSelected(event)}
                            className='select'
                            displayEmpty
                            disabled={waiting}
                        >
                        {reasons.map((reason) => (
                            <MenuItem 
                                key={reason.label}
                                value={reason}
                                className="menuitem"
                            >
                                <Typography className='text-menuitem'>{reason.label}</Typography>
                            </MenuItem>
                        ))}
                        </Select>
                    </Grid>
                    {/*
                    <ComboboxStyled
                        comboboxOptions={reasons} 
                        value={reasonSelected}
                        setValue={setReasonSelected}
                        setSearch={(foo) => {}}
                        active={!waiting}
                    />  */}
                    {state && state.location ?
                        <></>
                    :
                        <>
                            <Typography 
                                component="h3"                     
                                variant="h3"
                                color="primary"
                                style={{marginTop: "20px", marginLeft: "20px", marginBottom: "15px"}}
                            >
                                ¿Ocurrió dentro del campus?
                            </Typography>
                            <RadioGroup 
                                row
                                    defaultValue={'yes'}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="row-radio-buttons-group"
                                    value={insideCampus === 0 ? 'no' : 'yes'}
                                    onChange={ (e) => {
                                        setInsideCampus(e.target.value === 'yes' ? 1 : 0)
                                        if(e.target.value === 'no'){
                                            setSiteSelectedObj({
                                                label: "Seleccionar un lugar",
                                                id: -1
                                            });
                                            setSiteStrings(["Seleccionar un lugar"]);
                                        }
                                    }}
                                >
                                    <FormControlLabel value={'yes'} control={<BpRadio />} label="Sí" style={{marginLeft: "12px"}}/>
                                    <FormControlLabel value={'no'} control={<BpRadio />} label="No" style={{marginLeft: "6px"}}/>
                            </RadioGroup>
                            {insideCampus === 1  && (
                                <>
                                    <Typography 
                                        component="h3"                     
                                        variant="h3"
                                        color="primary"
                                        style={{marginTop: "20px", marginLeft: "20px"}}
                                    >
                                        ¿Dónde sucedió?
                                    </Typography>
                                    <ComboboxStyled
                                        comboboxOptions={siteStrings} 
                                        value={siteSelectedObj}
                                        setValue={setSiteString}
                                        setSearch={setSearch}
                                        active={true}
                                        icon={QrIcon}
                                        onClickAct={onClickComboboxSite}
                                    />  
                                </>
                            )}
                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={6} md={6}>
                                    <Typography 
                                            component="h3"                     
                                            variant="h3"
                                            color="primary"
                                            gutterBottom
                                        >
                                        ¿Cuándo fue?
                                    </Typography>
                                    <DesktopDatePicker
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        disableFuture                    
                                        value={dateValue}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(value) => {
                                            let dateTemp = value;
                                            if(dateTemp !== null){
                                                setDateString(formatDateHyphen(dateTemp));
                                                setDateValue(dateTemp);
                                                changeTime(timeString, formatDateHyphen(dateTemp));
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Typography 
                                        component="h3"                     
                                        variant="h3"
                                        color="primary"
                                        gutterBottom
                                    >
                                        ¿A qué hora?
                                    </Typography>
                                    <TextField
                                        id="time"
                                        type="time"
                                        onChange={(e) => {
                                            changeTime(e.target.value)
                                        }}
                                        value={timeString}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </>
                }
                    <DescriptionField
                        disabled={waiting}
                        label="Detalle:" 
                        information={description}
                        setInformation={setDescription}
                        placeholder='Detalla lo sucedido.'
                    /> 
                    <Grid item>
                        <ButtonWithIcon disabled={waiting} onClick={confirmIncident} fullWidth color='warning' buttonText="Registrar Incidencia" />
                    </Grid>
                    <AlertOptions
                        title="¿Desea confirmar el registro de su incidencia?"
                        message="¿Desea confirmar el registro de su incidencia?"
                        alertOpen={alertActive}
                        setAlertOpen={setAlertActive}
                        onConfirm={registerAnIncident}
                    />

                </Grid>
            </Container>  
        </>
    )

};

export default Incidents;
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import * as React from 'react';
import './CustomDatePicker.css'

const CustomDatePicker  = (props) => {

    const { title, setValue } = props;
    // Enero: 1, Febrero: 2, ...

    const [monthObj, setMonthObj] = React.useState([]);
    const days = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const [date, setDate] = React.useState(new Date());
    const [currentYear, setCurrentYear] = React.useState(date.getFullYear());
    const [currentMonth, setCurrentMonth] = React.useState(date.getMonth() + 1);
    const [selectedDay, setSelectedDay] = React.useState(date.getDate());
    const [selectedWeek, setSelectedWeek] = React.useState(Math.floor((selectedDay - 1 + new Date(currentYear, currentMonth - 1, 1).getDay()) / 7));

    const fillMonth = (month) => {
        var rows = [];
        var monthAux = [];
        const offset = new Date(currentYear, month - 1, 1).getDay();
        const monthDays = new Date(currentYear, month, 0).getDate();
        const weekCount = Math.ceil((offset + monthDays) / 7);

        var count = 1;
        for(var i = 0; i < 7 * weekCount; i++) {
            if(i < offset || count > monthDays) {
                rows.push(null);
            }
            else {
                rows.push(count);
                count++;
            }
        }

        for(var i = 0; i < weekCount; i++) {
            monthAux.push(rows.slice(i*7, (i+1) * 7));
        }

        setMonthObj(monthAux);        
    }

    React.useEffect(() => {
        fillMonth(currentMonth);
    }
    , []);

    return (
        <Grid container xs={12} className='container-date-picker' style={{display: 'flex', direction: 'column'}}>
            <Grid mt='10px' item xs={8}>
                <Typography variant='h5' color='#000000'>{title}</Typography>
            </Grid>
            <Grid mt='10px' item xs={4} px='10px'>
                <Select fullWidth onChange={(event) => {
                    setCurrentMonth(event.target.value);
                    setSelectedDay(1);
                    setSelectedWeek(0);
                    fillMonth(event.target.value)
                    setValue(new Date(currentYear, event.target.value - 1, 1));
                }} size='small' value={currentMonth}>
                    {months.map((month, index) => <MenuItem value={index+1}>{month}</MenuItem>)}
                </Select>
            </Grid>

            <Grid container padding='10px'>
                {monthObj.length > 0 && monthObj[selectedWeek].map((day, index) => 
                    <Grid onClick={() => { if(day !== null) {setSelectedDay(day); setValue(new Date(currentYear, currentMonth - 1, day))} }} container display='flex' direction='column' justifyContent='center' alignItems='center' style={day === selectedDay ? {backgroundColor: '#004EA8', borderRadius: '40px', height: '80px'} : {height: '80px'}} xs={1.7}>
                        <Typography variant='h5' color={selectedDay === day ? '#F8F8F8' : '#4F4F4F'} mx='2px'>{days[index]}</Typography>
                        <Typography variant='h5' color={selectedDay === day ? '#F8F8F8' : '#4F4F4F'} mx='2px'> {day !== null ? day : '-'}</Typography>
                    </Grid>)}
            </Grid>

            <Grid mb='10px' justifyContent='center' alignItems='center' container xs={12} display='flex' direction='row'>
                {monthObj.length > 0 && monthObj.map((week, index) =>
                    <div
                        onClick={() => setSelectedWeek(index)}
                        className={index === selectedWeek ? 'selected-button' : 'not-selected-button'}
                    />)}
            </Grid>


        </Grid>
    )



}

export default CustomDatePicker;

import axios from "axios";

const API_URL = window.globalConfig || { url: process.env.REACT_APP_WEB_SERVICES_URL, keyCode: process.env.REACT_APP_KEYCODE} ;

export const getActivities = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    firstResult: variables.firstResult,
    maxResults: variables.maxResults,
    user: variables.user,
    personId: variables.personId,
    date: variables.date,
  }
  return axios.post(
    API_URL.url + "activity/list",
    obj,
  );
};


export const getStudentActivity = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    value: variables.documentNumber,
  }
  return axios.post(
    API_URL.url + "activity/student/query",
    obj,
  );
};

export const getActivitiesBySiteAndPerson = (variables) => {
  const obj = {
    keyCode: API_URL.keyCode,
    user: variables.user,
    siteId: variables.siteId,
    date: variables.date,
  }
  return axios.post(
    API_URL.url + "activity/listActivitiesSite",
    obj,
  );
}
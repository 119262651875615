import * as React from 'react';
import { 
    Grid, 
    Typography,
    TextField
} from '@mui/material';

const DescriptionField  = (props) => {
    const {label, information, setInformation, placeholder, disabled} = props;
    return(
        <Grid container item direction="row"> 
            <Grid item xs={12} md={4} lg={2}>
                <Typography 
                    variant="h3" 
                    component="h3" 
                    color="primary"
                    gutterBottom 
                >
                    {label}
                </Typography> 
            </Grid> 
            <Grid item xs={12} md={8} lg={10}>
                <TextField
                    disabled={disabled}
                    id="outlined-basic" 
                    variant="outlined"
                    value={information} 
                    onChange={(e)=>setInformation(e.target.value)}
                    placeholder={placeholder}
                    multiline
                    rows={5}
                    fullWidth
                />
            </Grid>  
        </Grid>
    )

};

export default DescriptionField;